import { Component } from '@angular/core';
import { SurveyQuestionComponent } from '../survey-question.component';
import { FormGroup } from '@angular/forms';
import { Model } from '@app-ngrx-domains';
import { sortBy } from 'lodash';

@Component({
  selector: 'survey-radio-question',
  templateUrl: './radio-question.component.html'
})
export class RadioQuestionComponent extends SurveyQuestionComponent {

  public options: Array<Model.SelectOption> = [];
  private choicesLength: number;

  buildForm() {
    this.options = sortBy(this.question.response_options, ['sort_order']);
    this.choicesLength = this.question.response_options.length;
    const controls = {
      [this.question.id]: [this.getValueForKey(this.question.id)]
    };

    this.form = this._fb.group(controls, { validator: this.isRequired ? this.radioValidator(String(this.question.id)): undefined });
  }

  clearRadioSelection() {
    this.getControl(this.question.id).setValue('');
  }

  radioValidator(formControlKey: string) {
    return (group: FormGroup): { [error: string]: string }  => {
      const value = group.get(formControlKey).value;
      if (!value) {
        return { validationError: 'A selection is required' };
      }
    }
  }

  get displayAsColumns() {
    return this.choicesLength > this.maxSingleColumnLength;
  }

  get column1Choices() {
    if (this.displayAsColumns) {
      return this.options.slice(0, Math.ceil(this.choicesLength / 2));
    } else {
      return this.options;
    }
  }

  get column2Choices() {
    if (this.displayAsColumns) {
      return this.options.slice(Math.ceil(this.choicesLength / 2) + 1);
    } else {
      return [];
    }
  }
}
