import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { ProgramService } from '@app-services';
import { ProgramSettings } from '@app-models';
import { CHAR_LIMITS, WORKFLOW_STEP_FIELDS, WORKFLOW_STEPS } from '@app-consts';

@Component({
  selector: 'po-guidance-text',  templateUrl: './guidance-text.component.html',
})
export class GuidanceTextComponent implements OnInit {
  @Input()
  set workflowFilter(value) {
    this._workflowFilter = value;
    this.updateDescription();
  }
  get workflowFilter() {
    return this._workflowFilter;
  }
  _workflowFilter: Model.GuidanceWorkflowFilter;
  @Input() fieldName: string = WORKFLOW_STEP_FIELDS.GUIDANCE;

  descriptionLength = CHAR_LIMITS.EXTRA_LONG;
  descriptionForm: FormGroup;

  private programSettings:  Model.EAProgramSettings;

  constructor(
    private programService: ProgramService,
    private store: Store<State>,
    private _fb: FormBuilder
  ) {
  }

  ngOnInit() {
    if (this.workflowFilter.canEdit) {
      // listen for fund updates.
      this.store.select(Queries.Fund.get)
        .pipe(
          filter(fund => !!(fund && fund.id)),
          take(1)
        )
        .subscribe((fund: Model.Fund) => {
          this.programSettings = fund.program_settings;
        });
    }
  }

  updateDescription() {
    if (!this.workflowFilter) {
      return;
    }

    this.description = this.programService.getWorkflowStepFieldGuidanceText(this.workflowFilter, this.fieldName);
    this.descriptionForm = this._fb.group({
      description: [this.description]
    });
  }

  /**
   * Help/field description getter & setter
   */
  private _description: string;
  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  /**
   * Returns true if the text is guidance text.
   */
  get isGuidance(): boolean {
    return !!this.workflowFilter && this.fieldName === WORKFLOW_STEPS.GUIDANCE;
  }

  persistDescription() {
    const guidanceEA = ProgramSettings.findGuidance(this.programSettings.guidances, this.workflowFilter, this.fieldName);
    const value = this.descriptionForm.get('description').value;

    if (!!guidanceEA) {
      this.store.dispatch(Actions.Fund.upsertAttribute({
        key: 'description',
        value,
        ea: guidanceEA,
        parentEffortAreas: [this.programSettings]
      }));
    } else {
      this.store.dispatch(Actions.Fund.createEffortArea({
        ea: {
          effort_area_type: 'guidances',
          parent_effort_area_id: this.programSettings.id,
          proposal_type: this.workflowFilter.proposalType,
          workflow_name: this.workflowFilter.workflowName,
          workflow_step: this.workflowFilter.stepName,
          title: this.workflowFilter.stepTitle,
          field_name: this.fieldName,
          description: value,
        },
        parentEffortAreas: [this.programSettings],
        hasAttributes: false
      }))
    }
  }
}
