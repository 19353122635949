import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SurveyService } from '@app/shared.surveys/components';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { filter, map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { uniq } from 'lodash';
import { collapsibleCardAnimation } from '@app/shared.generic/animations';
import { QUESTION_TYPES, SURVEY_UTILS } from '@app/shared.surveys/consts';

@Component({
  selector: 'survey-question-group',
  templateUrl: './survey-question-group.component.html',
  animations: [collapsibleCardAnimation]
})
export class SurveyQuestionGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() groupQuestion: Model.EASurveyQuestion;
  @Input() questions: Array<Model.EASurveyQuestion> = [];

  questionTypes = QUESTION_TYPES;
  responses: Array<Model.EASurveyResponse> = [];
  responseGroups: Array<number> = [];
  responseGroupIndex: number = 0;
  groupToDelete: number;
  showRequiredResponseError: boolean;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    public surveyService: SurveyService,
    private store: Store<State>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.surveyService.isPreview) {
      this.setupAsPreview();
      return;
    }

    const observable = (this.surveyService.useCurrentProposalStore
      ? this.store.select(Queries.CurrentProposal.get)
      : this.store.select(Queries.Proposals.getAll).pipe(map(proposals => {
        return proposals.find(p => p.id === this.surveyService.proposalId) || null;
      }))).pipe(filter(p => p && !!p.id));

    combineLatest([
      observable,
      this.store.select(Queries.Files.getItems)
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([proposal, files]) => {
      const allResponses: Array<Model.EASurveyResponse> = proposal // proposalId can be null when showing the survey questions as a template
        ? proposal.survey_responses ? [].concat(proposal.survey_responses) : [] // [].concat() ensures `allResponses` will always be an array
        : [];

      this.responses = allResponses.filter(response => {
        return ((this.surveyService.institutionId ? this.surveyService.institutionId === response.institution_id : true)
        && (this.surveyService.durationId ? this.surveyService.durationId === response.duration_id : true)
        && (this.surveyService.effortAreaId ? this.surveyService.effortAreaId === response.parent_effort_area_id : true)
        && (this.surveyService.fundId ? this.surveyService.fundId === response.fund_id : true)
        && (this.surveyService.userId ? this.surveyService.userId === response.user_id : true)
        && (this.groupQuestion.id === response.group_id)
        && !!response.response_group)
      });

      this.responseGroups = uniq(this.responses.map(response => response.response_group));

      if (!this.responseGroupIndex) {
        // Initialize the group index to the highest response group number
        this.responseGroupIndex = this.responseGroups.length ? Math.max(...this.responseGroups) : 0;
      }

      if (!this.responseGroups.length) {
        this.addResponseGroup();
      }

      if (!this.groupQuestion.is_optional) {
        const validator = this.surveyService.createValidator(this.surveyQuestions, this.responses, files);
        this.showRequiredResponseError = !validator.hasResponse(this.groupQuestion) && this.groupQuestion.multi_response;
      }
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  setupAsPreview() {
    this.responseGroups = [1];
  }

  addResponseGroup() {
    this.responseGroups.push(++this.responseGroupIndex);
  }

  get surveyQuestions() {
    return this.questions.filter(question => !question.follow_up_to)
      .sort((a, b) => a.question_number - b.question_number);
  }

  followUpQuestions(question: Model.EASurveyQuestion, responseGroup: number) {
    return this.questions
      .filter(q => {
        if (q.follow_up_to === question.id) {
          // check for conditional display
          const questions = this.surveyService.questions.value;
          if (this.surveyService.isPreview || !q.if_answer_is || !questions) {
            return true;
          } else {
            const questionRef = questions.find(qRef => qRef.id === question.id && qRef.responseGroup === responseGroup);
            if (questionRef) {
              if (SURVEY_UTILS.isMultiSelectQuestion(question)) {
                const answerOption = question.response_options.find(choice => choice.title === q.if_answer_is);
                if (answerOption) {
                  return questionRef.getResponse(answerOption.id);
                }
              } else {
                const value = questionRef.getResponse();
                return q.if_answer_is === String(value);
              }
            }
          }
        }
      })
      .sort(function(a, b) {
        return a.question_number - b.question_number;
    });
  }

  promptDeleteResponses(groupNumber?: number) {
    this.groupToDelete = groupNumber;
  }

  deleteResponses() {
    const responsesToDelete = this.responses.filter(response => response.response_group === this.groupToDelete);
    if (this.surveyService.useCurrentProposalStore) {
      this.store.dispatch(Actions.CurrentProposal.deleteMultiEffortAreas({ eas: responsesToDelete }));
    } else {
      this.store.dispatch(Actions.Proposals.deleteMultiEffortAreas({ eas: responsesToDelete, proposal_id: this.surveyService.proposalId }));
    }
    this.groupToDelete = undefined;
  }

  trackByValue(index: number, value: any) {
    return value;
  }

  trackById(index: number, question: Model.EASurveyQuestion) {
    return question.id;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
