// modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// shared modules
import { GenericModule } from '@app/shared.generic/generic.module';

// components
import { SurveyComponents } from './components';

// services
import { SurveyService } from './components/survey-template/survey.service';

@NgModule({
  declarations: [
    ...SurveyComponents
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // shared modules
    GenericModule
  ],
  providers: [
    SurveyService
  ],
  exports: [
    ...SurveyComponents
  ],
})
export class SurveysModule { }
