<div class="card">
  <h4>{{groupQuestion.question_number}}. {{groupQuestion?.title}}<span class="error-asterisk" *ngIf="!groupQuestion.is_optional"> *</span></h4>

  <p *ngIf="showRequiredResponseError" [attr.data-invalid]="true" class="error-text">
    At least one valid response is required for this question group.
  </p>
  <p *ngIf="!responseGroups?.length" class="empty-state">No responses for this question have been entered.</p>

  <div *ngFor="let group of responseGroups; let last = last; trackBy: trackByValue;">
    <div *ngFor="let question of surveyQuestions; trackBy: trackById" id="survey_question_{{question.id}}">
      <ng-container [ngTemplateOutlet]="questionTemplate" [ngTemplateOutletContext]="{ question: question, responseGroup: group }"></ng-container>

      <div *ngIf="!!followUpQuestions(question, group)?.length" class="list-filter__container" [@collapsible]>
        <div *ngFor="let followUp of followUpQuestions(question, group); trackBy: trackById">
          <ng-container [ngTemplateOutlet]="questionTemplate" [ngTemplateOutletContext]="{ question: followUp, responseGroup: group, parentQuestionNumber: question.question_number }"></ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="surveyService.canEdit && groupQuestion.multi_response" class="align-right">
      <button id="questionGroup{{groupQuestion.id}}_removeResponse{{group}}_button" type="button"
        class="action-button action-button--secondary action-button--small icon--left"
        (click)="promptDeleteResponses(group)">
        <icon iconId="delete"></icon>
        Remove Responses
      </button>
    </div>
    <hr *ngIf="!last">
  </div>

  <div *ngIf="surveyService.canEdit && groupQuestion.multi_response" class="btn-section btn-section--center">
    <button id="questionGroup{{groupQuestion.id}}_add_response_button" type="button" class="action-button action-button--primary action-button--small icon--left"
      (click)="addResponseGroup();">
      <icon iconId="plus"></icon>
      Add{{ responseGroups.length ? ' Additional' : '' }} Response
    </button>
  </div>
</div>

<ng-template #questionTemplate let-question="question" let-responseGroup="responseGroup" let-parentQuestionNumber="parentQuestionNumber">
  <ng-container [ngSwitch]="question.question_type">
    <survey-table-question *ngSwitchCase="questionTypes.TABLE"
      [question]="question"
      [parentQuestionNumber]="parentQuestionNumber"
      [groupNumber]="groupQuestion.question_number"
      [groupId]="groupQuestion.id"
      [responseGroupNumber]="responseGroup">
    </survey-table-question>

    <survey-radio-question *ngSwitchCase="questionTypes.RADIO"
      [question]="question"
      [parentQuestionNumber]="parentQuestionNumber"
      [groupNumber]="groupQuestion.question_number"
      [groupId]="groupQuestion.id"
      [responseGroupNumber]="responseGroup">
    </survey-radio-question>

    <survey-checkbox-question *ngSwitchCase="questionTypes.CHECKBOX"
      [question]="question"
      [parentQuestionNumber]="parentQuestionNumber"
      [groupNumber]="groupQuestion.question_number"
      [groupId]="groupQuestion.id"
      [responseGroupNumber]="responseGroup">
    </survey-checkbox-question>

    <survey-file-question *ngSwitchCase="questionTypes.FILE"
      [question]="question"
      [parentQuestionNumber]="parentQuestionNumber"
      [groupNumber]="groupQuestion.question_number"
      [groupId]="groupQuestion.id"
      [responseGroupNumber]="responseGroup">
    </survey-file-question>

    <survey-dropdown-question *ngSwitchCase="questionTypes.DROPDOWN"
      [question]="question"
      [parentQuestionNumber]="parentQuestionNumber"
      [groupNumber]="groupQuestion.question_number"
      [groupId]="groupQuestion.id"
      [responseGroupNumber]="responseGroup">
    </survey-dropdown-question>

    <survey-question *ngSwitchDefault
      [question]="question"
      [parentQuestionNumber]="parentQuestionNumber"
      [groupNumber]="groupQuestion.question_number"
      [groupId]="groupQuestion.id"
      [responseGroupNumber]="responseGroup">
    </survey-question>
  </ng-container>
</ng-template>

<alert *ngIf="groupToDelete"
  button-action="Delete"
  button-dismiss="Cancel"
  level="warning"
  (action)="deleteResponses()"
  (dismiss)="promptDeleteResponses()">
  <p>
    Are you sure you want to delete these responses?
  </p>
</alert>
