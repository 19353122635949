import { Component } from '@angular/core';
import { SurveyQuestionComponent } from '../survey-question.component';
import { Model, Actions, Queries } from '@app-ngrx-domains';
import { ValidatorsEx } from '@app/core/utilities';
import { filter, map, skipWhile, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { SURVEY_FILE_TYPE } from '@app/shared.surveys/consts';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'survey-file-question',
  templateUrl: './file-question.component.html'
})
export class FileQuestionComponent extends SurveyQuestionComponent {

  documentTypeId = SURVEY_FILE_TYPE;
  showUploadModal: boolean;

  fileList = [];
  currentUserId;

  surveyResponseEA: Model.EASurveyResponse;
  hasResponseEA: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ngOnInit() {
    const proposal$ = (this.surveyService.useCurrentProposalStore
      ? this.store.select(Queries.CurrentProposal.get)
      : this.store.select(Queries.Proposals.getAll).pipe(map(proposals => { return proposals.find(p => p.id === this.surveyService.proposalId) || null })))
        .pipe(filter(p => p && p.id));
    combineLatest([
      proposal$,
      this.store.select(Queries.Files.getItems)
    ]).pipe(
      withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId)),
      takeUntil(this.destroy$)
    ).subscribe(([[proposal, files], userId]) => {

      const surveyResponses = proposal && proposal.survey_responses ? [].concat(proposal.survey_responses) : [];
      this.surveyResponseEA = surveyResponses.find(response => {
        return ((this.surveyService.institutionId ? this.surveyService.institutionId === response.institution_id : true)
        && (this.surveyService.durationId ? this.surveyService.durationId === response.duration_id : true)
        && (this.surveyService.effortAreaId ? this.surveyService.effortAreaId === response.parent_effort_area_id : true)
        && (this.surveyService.fundId ? this.surveyService.fundId === response.fund_id : true)
        && (this.surveyService.userId ? this.surveyService.userId === response.user_id : true)
        && response.question_id === this.question.id
        && response.group_id === (this.groupId || null)
        && response.response_group === (this.responseGroupNumber || null));
      });

      this.hasResponseEA.next(!!this.surveyResponseEA);

      this.fileList = this.surveyResponseEA ? files.filter(file => file.effort_area_id === this.surveyResponseEA.id) : [];

      this.currentUserId = userId;

      if (this.form) {
        this.form.get('fileIds').setValue(this.fileList);
      }
    });

    super.ngOnInit();
  }

  setupAsPreview() {
    super.setupAsPreview();
  }

  // Creates the "survey_responses" EA to associate the file to
  createResponse = async (fileFields) => {
    if (!this.surveyResponseEA) {
      const payload = {
        ea: { ...this.baseResponseFields },
        hasAttributes: false
      };

      if (this.surveyService.useCurrentProposalStore) {
        this.store.dispatch(Actions.CurrentProposal.createEffortArea(payload));
      } else {
        this.store.dispatch(Actions.Proposals.createEffortArea({ ...payload, proposal_id: this.surveyService.proposalId }));
      }

      await this.hasResponseEA.pipe(
        skipWhile(hasResponse => !hasResponse),
        take(1)
      ).toPromise();
    }

    fileFields.effort_area_id = this.surveyResponseEA.id;
  }

  buildForm() {
    const validators = this.isRequired ? [ValidatorsEx.requiredSelection] : undefined;
    this.form = this._fb.group({
      fileIds: [this.fileList, validators]
    });
  }

  formatDocTitle(file: Model.Document) {
    return file.title || file.filename;
  }

  uploadSuccess(file: Model.Document) {
    this.store.dispatch(Actions.Files.append(file));
    this.showUploadModal = false;
  }

  removeFile(fileId: number) {
    this.store.dispatch(Actions.Files.delete(fileId));
  }

  canRemoveFile = (file: Model.Document) => {
    return this.surveyService.canEdit;
  }

  get documentColumns() {
    return { title: true, type: false, year: false, user: true, time: true, actions: this.surveyService.canEdit };
  }
}
