import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ACTION_BUTTONS, AREAS, PROGRAM_KEYS, PROPOSAL_TYPES, STATES, TASK_TYPES, WORKFLOW_STEPS } from '@app-consts';
import { Fund, ProposalBase as Proposal, Task, Utilities } from '@app-models';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { PermissionsService, ProgramService } from '@app-services';
import { combineLatest, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Store } from '@ngrx/store';
import { cloneDeep, capitalize, findIndex } from 'lodash';
import { ProjectUtilities } from '@app/shared.project';
import { filter, startWith, take, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'base-project-workflow-routing',
  template: '<router-outlet></router-outlet>',
})
export class BaseProjectWorkflowRoutingComponent implements OnInit, OnDestroy {

  protected proposal: Model.ProposalItem;
  protected program: Model.Fund;
  protected parentProgram: Model.Fund;
  protected configWorkflow: Model.Workflow | { [key: string]: Model.Workflow };
  protected destroy$: Subject<boolean> = new Subject();
  protected baseLink: string;
  protected currentProposalId: number;

  private useProgramSettings: boolean;
  protected workflowSteps: Array<string> = [];
  protected visibleWhenApproved: Array<string> = [];

  private workflowType: string;
  private infoHeaderActions: Array<Model.HeaderAction> = [];
  private projectType: string; // default undefined
  private areaName: string = AREAS.PROJECT;
  private defaultStep: string = WORKFLOW_STEPS.DETAILS;
  private postSegment: string = '';

  private previousTitle = '';
  private previousStatus = '';
  private postSegmentOverride;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected store: Store<State>,
    protected logger: NGXLogger,
    protected permissionsService: PermissionsService,
    protected programService: ProgramService
  ) {
    this.store.dispatch(Actions.Layout.clearHeader());
  }

  protected setContext(config: {
    areaName: string,
    parentKey: string,
    postSegment?: string,
    projectType?: string;
    segment?: string,
    workflow?: Model.Workflow | { [key: string]: Model.Workflow },
    defaultStep?: string,
    infoHeaderActions?: Array<Model.HeaderAction>,
    useProgramSettings?: boolean, // Uses program_settings.workflow_steps to determine step visibility
    visibleWhenApproved?: Array<string>
  }) {
    this.configWorkflow = config.workflow;

    this.areaName = !!config.areaName ? config.areaName : this.areaName;
    this.postSegmentOverride =  Utilities.isNil(config.postSegment) ? undefined : config.postSegment;
    this.projectType = !!config.projectType ? config.projectType : this.projectType;
    this.defaultStep = !!config.defaultStep ? config.defaultStep : this.defaultStep;
    this.infoHeaderActions = !!config.infoHeaderActions ? config.infoHeaderActions : this.infoHeaderActions;

    this.useProgramSettings = !!config.useProgramSettings;
    this.visibleWhenApproved = [ ...(config.visibleWhenApproved || []), WORKFLOW_STEPS.PREVIEW];

    const projectSegment = config.segment ? config.segment : 'plans';
    this.baseLink = `/${config.parentKey}/${projectSegment}`;
  }

  ngOnInit() {
    this.logger.debug(`[${this.constructor.name}][${this.router.url}] initializing...`);

    this.store.select(Queries.CurrentProposal.get).pipe(
      withLatestFrom(this.store.select(Queries.Auth.getCurrentUser)),
      filter(([p, u]) => p && p.id && !!u),
      take(1)
    ).subscribe(([proposal, user]) => {

      if (!!proposal.type && (proposal.type !== this.projectType)) {
        // reroute user to dashboard
        this.router.navigate([this.baseLink]);
        return;
      }

      this.proposal = proposal;
      this.currentProposalId = proposal.id;
      const programId = proposal.fund_ids[0];
      this.program = this.programService.getProgramById(programId);
      this.parentProgram = this.programService.getParentProgramFromRoute(this.router.url);

      if (Utilities.isNil(this.postSegmentOverride)) {
        const version = Proposal.version(proposal);
        this.postSegment = !!version ? `/${version}` : '';
      } else {
        this.postSegment = this.postSegmentOverride;
      }

      this.initHeader(proposal);
      this.initWorkflow(proposal);
      this.afterWorkflowInit();

      this.manageHeaderAndWorkflow(proposal);
    });
  }

  protected afterWorkflowInit() {}

  ngOnDestroy() {
    this.logger.debug(`[${this.constructor.name}][${this.router.url}] destroying...`);

    // clear header.
    this.store.dispatch(Actions.Layout.clearHeader());

    // clear current workflow.
    this.store.dispatch(Actions.Workflow.clear());

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * This is an abstract method; the derived class must supply this method.
   */
  protected validateWorkflow() { };

  private initHeader(proposal: Model.ProposalBase) {
    // show header
    const parentKey = this.program.parent_key || this.program.key;
    const offerStarted = Proposal.offerNotDraft(proposal);
    let sub_title;
    let user;

    if ([PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_R].includes(parentKey)) {
      sub_title = Proposal.formatProposalAuthorHeader(proposal);
    } else if (proposal.type === PROPOSAL_TYPES.APPLICATION && this.program.is_small_program && this.program.program_settings.is_rfa) {
      const offerStarted = Proposal.offerNotDraft(proposal);
      const applicationSubmitted = Proposal.applicationIsSubmitted(proposal);
      const offerTask = proposal.tasks.find(task => task.task_type === TASK_TYPES.RFA_FUNDING_OFFER);
      const submittedTask = proposal.tasks.find(task => (task.task_type === TASK_TYPES.RFA_APPLICATION_SUBMIT) && task.store.message === 'Application Submitted');
      const submitTask = proposal.tasks.find(task => task.task_type === TASK_TYPES.RFA_APPLICATION_SUBMIT);

      if (offerStarted) {
        sub_title = Task.formatTaskDate(offerTask, 'Offer sent');
      } else if (applicationSubmitted) {
        sub_title = Task.formatTaskAuthor(submittedTask, 'Submitted');
        user = submittedTask.performed_by;
      } else {
        sub_title = Task.formatTaskAuthor({ performed_by: submitTask.performed_by, created_at: proposal.created_at } as Model.Task, 'Started');
        user = submitTask.performed_by;
      }
    } else {
      sub_title = undefined;
      user = undefined;
    }

    let projectType = this.projectType;
    if ([PROGRAM_KEYS.GP, PROGRAM_KEYS.IPLAN].includes(this.program.parent_key)) {
      projectType = 'Project';
    }

    this.store.dispatch(Actions.Layout.updateHeader({
      programTitle: Fund.getHeaderName(this.parentProgram),
      routeTitle: `${capitalize(projectType) || 'Plan'}s`,
      grant: this.program,
      title: Proposal.formatProposalTitleHeader(proposal),
      status: Proposal.getAreaState(proposal, this.areaName),
      sub_title,
      resource: {
        area_name: this.areaName,
        fund_id: this.program.id,
        proposal_id: proposal.id,
      },
      actions: this.infoHeaderActions,
      isProfile: false,
      user,
    }));
  }

  protected initWorkflow(proposal: Model.ProposalBase) {
    let configWorkflow: Model.Workflow;
    let additionalConfigSteps: {[step: string]: any};
    if (!!this.configWorkflow) {
      if (!!this.configWorkflow[this.program.key]) {
        configWorkflow = this.configWorkflow[this.program.key];
      } else {
        configWorkflow = this.configWorkflow as Model.Workflow;
      }
    } else {
      // read config from the route snapshot -- used for handling plan workflow type.
      const routeConfig = cloneDeep(this.route.snapshot.data.config);
      const durationId = proposal.duration_id;
      let config = routeConfig['applicationWorkflow'];
      configWorkflow = config[durationId] ? config[durationId] : config.default;
      if (routeConfig['planWorkflow']) {
        config = routeConfig['planWorkflow'];
        additionalConfigSteps = config[durationId]
          ? config[durationId]
          : config.default ? config.default : undefined;
      }

      if (config['subWorkflows']) {
        for (const subConfig of config['subWorkflows']) {
          const subConfigStep = configWorkflow.steps.find(step => step.workflow && step.workflow.name === subConfig.name);
          this.store.dispatch(Actions.CurrentWorkflow.setWorkflowSteps({
            config: subConfig,
            title: subConfig.title,
            workflowType: subConfig.name,
            workflowPath: subConfigStep.route,
            baseLink: `${this.baseLink}/${this.currentProposalId}/${this.postSegment}`,
            show: false,
            parent: {
              name: configWorkflow.name,
              title: configWorkflow.title,
              route: this.baseLink,
              backRoute: `${this.baseLink}/${this.currentProposalId}/${this.postSegment}`
            }
          }, false));
        }
      }
    }

    this.workflowType = configWorkflow.name;

    if (this.useProgramSettings) {
      ProjectUtilities.setWorkflowSteps(configWorkflow, this.program);
    }

    let workflowSteps = [...configWorkflow.steps];

    const versionNumber = Proposal.versionNumber(proposal);
    if (this.program.parent_key === PROGRAM_KEYS.SWP_K12 && versionNumber >= 5) {
      const index = findIndex(workflowSteps, { route: WORKFLOW_STEPS.PARTNERS });
      workflowSteps = [
        ...workflowSteps.slice(0, index),
        {
          route: WORKFLOW_STEPS.POSITIVE_CONSIDERATIONS,
          title: 'Positive Considerations',
          showStatus: true,
          valid: false,
        },
        ...workflowSteps.slice(index)
      ];
    }

    const planOnlySteps = [WORKFLOW_STEPS.SUCCESS];
    this.workflowSteps = workflowSteps.filter(step => !step.hide &&
      (this.areaName === AREAS.APPLICATION ? !planOnlySteps.includes(step.route) : true ))
      .map(step => step.route);

    // build workflow w visibility turned off
    this.store.dispatch(Actions.CurrentWorkflow.setWorkflowSteps({
      config: { ...configWorkflow, steps: workflowSteps },
      workflowType: configWorkflow.name,
      workflowPath: `${proposal.id}${this.postSegment}`,
      show: false,
      baseLink: this.baseLink,
      additionalConfig: additionalConfigSteps,
    }));

    // validate workflow
    this.validateWorkflow();

    // handle one time rerouting upfront before waiting on edit status check, as that delays
    // the reroute & causes part of the hidden page to appear
    const historyLink = `${this.baseLink}/${proposal.id}${this.postSegment}/${WORKFLOW_STEPS.HISTORY}`;
    const compareChangesLink = `${this.baseLink}/${proposal.id}${this.postSegment}/${WORKFLOW_STEPS.COMPARE_CHANGES}`;
    const isHistoryLink = [historyLink, compareChangesLink].includes(this.router.url);

    if (isHistoryLink) {
      return;
    }

    const visibleSteps = this.getVisibleSteps(Proposal.isAreaDraft(proposal, this.areaName));
    this.rerouteIfInvalid(visibleSteps);
  }

  protected async manageHeaderAndWorkflow(proposal: Model.ProposalBase) {
    const canEdit = await this.permissionsService.canEditProject(proposal, AREAS.PROJECT, this.route.snapshot).toPromise();
    this.logger.debug(`[${this.constructor.name}][${this.router.url}] checked permissions. canEdit=${canEdit}`);

    this.handleNextButton();

    let prevState: string;

    combineLatest([
      this.store.select(Queries.CurrentProposal.get),
      this.store.select(Queries.Workflow.getCurrentStep).pipe(startWith(''))
    ]).pipe(
      filter(([p, workflowStep]) => p && p.id && !!workflowStep),
      takeUntil(this.destroy$)
    ).subscribe(([p, step]) => {

      if (p.id !== this.currentProposalId) {
        // in transition
        return;
      }

      this.proposal = p;
      this.updateHeader(p);

      if ([WORKFLOW_STEPS.HISTORY, WORKFLOW_STEPS.COMPARE_CHANGES].includes(step)) {
        // hide the workflow always
        prevState = undefined;
        this.store.dispatch(Actions.Workflow.show(false));
        return;
      }

      const state = Proposal.getAreaState(p, this.areaName);

      if (state === prevState) {
        // already set up
        return;
      }
      prevState = state;

      let showWorkflow = false;

      if (canEdit) {
        if ([STATES.DRAFT.name, STATES.EDITED.name].includes(state)) {
          this.setVisibleSteps(true);
          showWorkflow = true;
        } else if ([STATES.APPROVED.name, STATES.CERTIFIED.name].includes(state) && this.visibleWhenApproved.length > 1) {
          this.setVisibleSteps(false);
          showWorkflow = true;
        }
      }

      if (showWorkflow) {
        this.store.dispatch(Actions.Workflow.show(true));
      } else {
        this.store.dispatch(Actions.Workflow.show(false));
        this.rerouteIfInvalid([WORKFLOW_STEPS.PREVIEW]);
      }
    });
  }

  protected handleNextButton() {
    this.store.select(Queries.Layout.getEmitState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((emitted: { name: string, id: string }) => {
        if (emitted && emitted.id === ACTION_BUTTONS.NEXT) {
          this.store.dispatch(Actions.CurrentWorkflow.gotoNext(emitted.name));
        }
      });
  }

  protected updateHeader(proposal: Model.ProposalBase) {
    const status = Proposal.getAreaState(proposal, this.areaName);
    const title = proposal.title;
    if (status !== this.previousStatus || title !== this.previousTitle) {
      this.store.dispatch(Actions.Layout.setHeaderTitles({
        title: Proposal.formatProposalTitleHeader(proposal),
        status: status,
      }));
      this.previousStatus = status;
      this.previousTitle = title;
    }
  }

  protected getVisibleSteps(isDraft: boolean) {
    return isDraft ? this.workflowSteps : this.visibleWhenApproved;
  }

  private setVisibleSteps(isDraft: boolean) {
    const visibleSteps = this.getVisibleSteps(isDraft);
    this.rerouteIfInvalid(visibleSteps);
    this.store.dispatch(Actions.Workflow.showSteps_new(visibleSteps, this.workflowType));
  }

  private rerouteIfInvalid(visibleSteps: Array<string>, rerouteTo: string = WORKFLOW_STEPS.PREVIEW) {
    const visibleRoutes = visibleSteps.map(step => `${this.baseLink}/${this.currentProposalId}${this.postSegment}/${step}`);

    if (!visibleRoutes.some(route => this.router.url.startsWith(route))) {
      const rerouteLink = `${this.baseLink}/${this.currentProposalId}/${this.postSegment}/${rerouteTo}`
      this.logger.debug(`[${this.constructor.name}][${this.router.url}] rerouting=${rerouteLink}`);
      this.router.navigate([rerouteLink], { replaceUrl: true });
    }
  }
}
