<ng-container *ngIf="responses?.length === 1" [ngSwitch]="question.question_type">
  <survey-response-field
    [labelText]="questionLabel"
    [questionType]="question.question_type"
    [canEdit]="surveyService.canEdit"
    [form]="form"
    controlName="{{question.id}}_response{{responses[0]['temp_id'] || responses[0].id}}"
    [isRequired]="isRequired"
    [uniqueTag]="uniqueTag"
    [helpText]="helpText"
    [textLimit]="question.text_limit"
    (onChange)="persistMultiResponse(responseType, responses[0].id)">
  </survey-response-field>
</ng-container>

<ng-container *ngIf="responses?.length > 1">
  <input-group [headingText]="questionLabel" [control]="form" [required]="isRequired" [errorsOnUntouched]="surveyService.canEdit && form.invalid">
    <ng-container *ngTemplateOutlet="helpTextTemplate"></ng-container>

    <div *ngFor="let response of responses; let i = index; trackBy: trackById" [ngSwitch]="question.question_type" [ngClass]="{ 'columns columns--5 no-margin': surveyService.canEdit }">
      <survey-response-field class="column-start-1 column-end-5"
        labelText="Response {{i + 1}}"
        [questionType]="question.question_type"
        [canEdit]="surveyService.canEdit"
        [form]="form"
        controlName="{{question.id}}_response{{response['temp_id'] || response.id}}"
        [isRequired]="isRequired"
        [uniqueTag]="uniqueTag"
        [textLimit]="question.text_limit"
        (onChange)="persistMultiResponse(responseType, response.id)">
      </survey-response-field>

      <button *ngIf="surveyService.canEdit" id="question{{question.id}}_delete_response_button" type="button"
        class="action-button action-button--tertiary action-button--small icon--left align-self-center"
        (click)="deleteResponse(response)">
        <icon iconId="delete"></icon>
        Remove Response
      </button>
    </div>
  </input-group>
</ng-container>

<ng-template #helpTextTemplate>
  <div *ngIf="helpText" class="help-text">
    <p [innerHtml]="helpText"></p>
  </div>
</ng-template>

<div *ngIf="surveyService.canEdit" class="btn-section btn-section--center">
  <button id="question{{question.id}}_add_response_button" type="button" class="action-button action-button--primary action-button--small icon--left"
    (click)="addResponse()">
    <icon iconId="plus"></icon>
    Add Additional Response
  </button>
</div>