import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DEFAULT_TEXT_LIMIT, QUESTION_TYPES } from '@app/shared.surveys/consts';

@Component({
  selector: 'survey-response-field',
  templateUrl: './response-field.component.html'
})
export class SurveyResponseFieldComponent {
  @Input() labelText: string;
  @Input() questionType: string;
  @Input() canEdit: boolean;
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() isRequired: boolean;
  @Input() uniqueTag: string;

  @Input() helpText?: string;
  @Input() textLimit?: number = DEFAULT_TEXT_LIMIT;

  @Output() onChange: EventEmitter<null> = new EventEmitter();

  questionTypes = QUESTION_TYPES;

  emitChange() {
    this.onChange.emit();
  }

  get useHtmlEditor() {
    return this.textLimit > DEFAULT_TEXT_LIMIT;
  }
}
