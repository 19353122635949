<form [formGroup]="form">
  <po-select id="dropdown_question_{{question.id}}_{{uniqueTag}}"
    [labelText]="questionLabel"
    [options]="options"
    [canEdit]="surveyService.canEdit"
    [selectMultiple]="isMulti"
    (optionSelected)="setDropdownValue($event, true)"
    (optionRemoved)="setDropdownValue($event, false)">
    <div *ngIf="helpText" class="help-text">
      <p [innerHtml]="helpText"></p>
    </div>
    <select inputRef [formControlName]="question.id" class="po-select__select" [attr.aria-required]="isRequired"></select>
  </po-select>
</form>
