<ng-container [ngSwitch]="questionType">
  <ng-container *ngSwitchCase="questionTypes.TEXT" [ngTemplateOutlet]="textTemplate"></ng-container>
  <ng-container *ngSwitchCase="questionTypes.NUMBER" [ngTemplateOutlet]="numberTemplate"></ng-container>
  <ng-container *ngSwitchCase="questionTypes.PERCENT" [ngTemplateOutlet]="percentTemplate"></ng-container>
  <ng-container *ngSwitchCase="questionTypes.CURRENCY" [ngTemplateOutlet]="currencyTemplate"></ng-container>
</ng-container>

<ng-template #textTemplate>
  <form [formGroup]="form">
    <ng-container [ngSwitch]="useHtmlEditor">
      <input-field *ngSwitchCase="true" [labelText]="labelText" [canEdit]="canEdit">
        <ng-container *ngTemplateOutlet="helpTextTemplate"></ng-container>
        <po-html-textarea inputRef id="response_field_{{controlName}}_{{uniqueTag}}"
          [formControlName]="controlName"
          [htmlCharLimit]="textLimit"
          (change)="emitChange()"
          [required]="isRequired" [attr.aria-required]="isRequired">
        </po-html-textarea>
      </input-field>

      <input-field *ngSwitchCase="false" [labelText]="labelText" [showCounter]="true" [canEdit]="canEdit">
        <ng-container *ngTemplateOutlet="helpTextTemplate"></ng-container>
        <input inputRef id="response_field_{{controlName}}_{{uniqueTag}}" type="text"
          [formControlName]="controlName" [maxLength]="textLimit"
          (change)="emitChange()"
          [required]="isRequired" [attr.aria-required]="isRequired"/>
      </input-field>
    </ng-container>
  </form>
</ng-template>

<ng-template #numberTemplate>
  <form [formGroup]="form">
    <input-field [labelText]="labelText" [canEdit]="canEdit">
      <ng-container *ngTemplateOutlet="helpTextTemplate"></ng-container>
      <input inputRef id="response_field_{{controlName}}_{{uniqueTag}}"
        [formControlName]="controlName" type="number"
        placeholder="Enter a number"
        (change)="emitChange()"
        [required]="isRequired" [attr.aria-required]="isRequired">
    </input-field>
  </form>
</ng-template>

<ng-template #percentTemplate>
  <form [formGroup]="form">
    <input-field [labelText]="labelText" [canEdit]="canEdit">
      <ng-container *ngTemplateOutlet="helpTextTemplate"></ng-container>
      <input inputRef id="response_field_{{controlName}}_{{uniqueTag}}"
        [formControlName]="controlName"
        type="text" transform="percent"
        placeholder="Enter a percentage"
        (change)="emitChange()"
        [required]="isRequired" [attr.aria-required]="isRequired">
    </input-field>
  </form>
</ng-template>

<ng-template #currencyTemplate>
  <form [formGroup]="form">
    <input-field [labelText]="labelText" [canEdit]="canEdit">
      <ng-container *ngTemplateOutlet="helpTextTemplate"></ng-container>
      <input inputRef id="response_field_{{controlName}}_{{uniqueTag}}"
        [formControlName]="controlName"
        integerCurrency
        type="text" placeholder="$"
        (change)="emitChange()"
        [required]="isRequired" [attr.aria-required]="isRequired">
    </input-field>
  </form>
</ng-template>

<ng-template #helpTextTemplate>
  <div *ngIf="helpText" class="help-text">
    <p [innerHtml]="helpText"></p>
  </div>
</ng-template>