export const ALL_RELEASES = [
  {
    date: 'December 12, 2024',
    version: '8.4',
    features: [
      'SEA Program: 2025-28 plan is now available in NOVA'
    ],
    bugs: []
  },
  {
    date: 'December 11, 2024',
    version: '8.3.3',
    features: [],
    bugs: [
      'System: Fixed an issue where alert pop-ups were not closing after acknowledgement'
    ]
  },
  {
    date: 'December 10, 2024',
    version: '8.3.2',
    features: [],
    bugs: [
      'SPM: Fixed an issue with percentage type custom survey questions not displaying responses correctly'
    ]
  },
  {
    date: 'November 26, 2024',
    version: '8.3.1',
    features: [],
    bugs: [
      'CAEP: Fixed a display issue with past metrics in the Three Year Plan',
      'SWP: Fixed a bug where the User Comments button was not available on the details tab of continued plans'
    ]
  },
  {
    date: 'November 14, 2024',
    version: '8.3',
    features: [
      'All Programs: User commenting feature now available for applications, plans, and reporting'
    ],
    bugs: [
      'SEA Program: Fixed a bug preventing some 2023-24 Annual Reports from loading correctly',
      'SEA Program: Fixed a bug with the Status filter on the reporting dashboard'
    ]
  },
  {
    date: 'November 12, 2024',
    version: '8.2.1',
    features: [],
    bugs: [
      'RCM: Updated validation for contributing funds to allow RCM allocation amounts without grant allocations' 
    ]
  },
  {
    date: 'October 22, 2024',
    version: '8.2.0',
    features: [
      'SWP: Added second approval step for Final reports' 
    ],
    bugs: []
  },
  {
    date: 'October 18, 2024',
    version: '8.1.0',
    features: [
      'SEA Program: Added support for reporting on SEP 2.0 Plans' 
    ],
    bugs: []
  },
  {
    date: 'October 10, 2024',
    version: '8.0.3',
    features: [],
    bugs: [
      'iPlan: Improved query response performance for Fiscal Reporting dashboard',
      'K12 SWP R7: Fixed the ADA Total displayed in the budget tab summary',
      'SWP: Added notification to Program Managers when Final Reports are submitted'
    ]
  },
  {
    date: 'October 8, 2024',
    version: '8.0.2',
    features: [
      'K12 SWP R7: Adjusted total ADA calculation to exclude collaborative partners of type LEA',
      'SPM: Fixed a bug in reporting where budgetless plans were not displaying Final Report fields correctly'
    ],
    bugs: []
  },
  {
    date: 'October 4, 2024',
    version: '8.0.1',
    features: [
      'Perkins NTCT: Updated Custom Surveys Dash to include each individual Perkins NTCT grant',
      'K12 SWP R7: Removed requirement for Collaborative Partners to upload Statement of Assurance documentation'
    ],
    bugs: []
  },
  {
    date: 'October 1, 2024',
    version: '8.0',
    features: [
      'SPM: Added support for tracking of carryover for independent funding sources',
      'SPM: Added support for transferring of carryover for independent funding sources'
    ],
    bugs: []
  },
  {
    date: 'September 25, 2024',
    version: '7.3.9',
    features: [],
    bugs: [
      'K12-SWP: Fixed total points display in redacted application review for V4 applications'
    ]
  },
  {
    date: 'September 16, 2024',
    version: '7.3.8',
    features: [],
    bugs: [
      'K12-SWP: Display institution long name on applications and plans when available',
      'SPM: Refined display of accepted funding amounts for competitive programs when multiple funding sources are used'
    ]
  },
  {
    date: 'August 30, 2024',
    version: '7.3.7',
    features: [],
    bugs: [
      'K12-TAP/PC: Fixed a bug preventing grant settings from loading the plan timeline'
    ]
  },
  {
    date: 'August 27, 2024',
    version: '7.3.6',
    features: [],
    bugs: [
      'K12-SWP: Resolved a validation issue with Historical Performance response fields'
    ]
  },
  {
    date: 'August 19, 2024',
    version: '7.3.5',
    features: [],
    bugs: [
      'K12-SWP: Updated method for fetching Participating Schools list on Applications/Plans',
      'K12-SWP: Updated K12 Lead Agency card to use institution long names to match name displayed in search results'
    ]
  },
  {
    date: 'August 12, 2024',
    version: '7.3.4',
    features: [],
    bugs: [
      'Perkins 1C: Fixed a bug where the CBO approval buttons were not shown for 2023-24 submitted applications'
    ]
  },
  {
    date: 'August 9, 2024',
    version: '7.3.3',
    features: [],
    bugs: [
      'K12-SWP: Round 7 application positive consideration question updates'
    ]
  },
  {
    date: 'August 7, 2024',
    version: '7.3.2',
    features: [],
    bugs: [
      'K12-SWP: Round 7 application and review updates'
    ]
  },
  {
    date: 'August 5, 2024',
    version: '7.3.1',
    features: [],
    bugs: [
      'SWP: Changed max spending duration for 2024-25 and beyond to 2 Years',
      'SWP: Removed 2020-21 funding year option from Create/Edit/Continue plan'
    ]
  },
  {
    date: 'July 31, 2024',
    version: '7.3',
    features: [
      'Perkins Title 1C: Application updates for 2025-26'
    ],
    bugs: [
      'SWP: Fixed a display issue stacking multiple years on the Program Settings/Guidance tab',
      'Perkins Title 1C: Hide document actions column from certified applications'
    ]
  },
  {
    date: 'July 19, 2024',
    version: '7.2',
    features: [],
    bugs: [
      'SWP: Updated 2024-25 plan templates to use Vision 2030 outcomes',
      'Perkins Title 1C: Final approval updated to Substantially Approved'
    ]
  },
  {
    date: 'June 27, 2024',
    version: '7.1.2',
    features: [],
    bugs: [
      'K12-SWP: Various minor fixes & improvements to the Round 7 application workflow',
      'SPM: Allow editing of grants that are using multiple funding sources',
      'SPM: Resolved an error when creating new reporting categories',
      'System: Resolved an issue with the current program not being highlighted in the nav after a refresh',
    ]
  },
  {
    date: 'June 14, 2024',
    version: '7.1.1',
    features: [
      'K12-SWP: Various workflow changes to support Round 7 applications'
    ],
    bugs: [
      'K12-SWP: Updated budget match requirement ratios to use the modern notation',
      'CAEP: Resolved an issue with Three Year Plan Activities'
    ]
  },
  {
    date: 'May 29, 2024',
    version: '7.1',
    features: [
    ],
    bugs: [
      'SPM: Braided funding related flexibility improvements',
      'System: Fixed a system error when cloning budget items'
    ]
  },
  {
    date: 'May 20, 2024',
    version: '7.0.2',
    features: [
    ],
    bugs: [
      'Perkins 1C: Added extra check for race condition during validation of Goals & Metrics tab',
      'SPM: Restored visibility of Q2 custom survey for ELL Healthcare fiscal reporting',
      'SPM: Improved UI for competitive application funding where aggregated tracking of funding sources is specified',
      'SPM: Restored display of funding source name in card when adding/editing a funding source',
      'SPM: Added a new fund setting to the "Application/Plan Steps" tab for budget item association with activities (when present)'
    ]
  },
  {
    date: 'May 14, 2024',
    version: '7.0.1',
    features: [
    ],
    bugs: [
      'Reporting: Fixed an issue where forecasts and project budgets were displayed as $0',
      'Reporting: Fixed an issue where legacy small program allocations were displayed as $0',
      'SPM: Display a summary of category spending on submitted reports tracking multiple funding sources independently',
      'SPM: Updated plan timeline to expand to show out of range funding sources'
    ]
  },
  {
    date: 'May 10, 2024',
    version: '7.0',
    features: [
      'SPM: Added support for fund configuration, budgeting, and reporting of multiple funding sources with aggregate or independent tracking',
      'SPM: Added support for category spend reporting by percent or dollars',
      'SPM: Added support for activity association to budget items'
    ],
    bugs: [
      'Service: Addressed a condition causing derived table updates to fail unexpectedly'
    ]
  },
  {
    date: 'May 8, 2024',
    version: '6.7.6',
    features: [
    ],
    bugs: [
      'SWP: Updated Create New and Continue Plan to permit selection of 2020-21 as funding year and to set the start year explicitly'
    ]
  },
  {
    date: 'April 25, 2024',
    version: '6.7.5',
    features: [
    ],
    bugs: [
      'Institutions: Fixed a system error when viewing an individual institution dashboard Users & Roles tab'
    ]
  },
  {
    date: 'April 19, 2024',
    version: '6.7.4',
    features: [
    ],
    bugs: [
      'CAEP: Fixed an intermittent issue displaying Governance questions on CFAD Preview',
      'CAEP: Fixed a timing issue displaying forecast values on Budget & Workplan Preview causing a system error',
      'Perkins: Updates to support Non-Traditional Careers & Training as a subprogram of Perkins'
    ]
  },
  {
    date: 'April 12, 2024',
    version: '6.7.3',
    features: [
    ],
    bugs: [
      'CAEP: Fixed Leveraged Fund totals in Active Member Agencies table',
      'K12-SWP: Fixed a bug where application contacts were not copied to funded plans',
      'K12-SWP: Updated recently funded plans with contacts from the application',
      'SPM: Added Budget Summary card and Institution totals to Application/Plan Preview tabs',
      'SPM: Fixed a bug where sent e-mail messages were not shown on the Sent tab in Dates & Messaging',
      'SPM: Added ability to view previously sent e-mail message text on the Sent tab in Dates & Messaging',
      'System: Added total rows to allocation cards system-wide'    
    ]
  },
  {
    date: 'March 28, 2024',
    version: '6.7.2',
    features: [
    ],
    bugs: [
      'SPM: Removed WIP flag for Grant Flexibility feature'
    ]
  },
  {
    date: 'March 25, 2024',
    version: '6.7.1',
    features: [
    ],
    bugs: [
      'K12-SWP: Prevented region locked error from displaying on funded plans',
      'K12-SWP: Fixed budget tab not going complete on plans when no match required',
      'SPM: Excluded start year and start quarter from being copied when duplicating grants',
      'System: Allocations and amendments now have a Clear All button with acknowledgement',
      'System: Improved SUDO to return admins to the page they were on when returning to their own account',
      'System: Fixed a race condition when clicking Fiscal Reporting button on plan previews causing page to freeze'
    ]
  },
  {
    date: 'March 19, 2024',
    version: '6.7',
    features: [
      'Perkins-1C: Application updates for 2024-25'
    ],
    bugs: [
      'System: Updated alert text when decertifying plans',
      'SPM/SWP: Updated logic and alert to show specific dependencies when removing a collaborative partner from a plan'
    ]
  },
  {
    date: 'March 6, 2024',
    version: '6.6.1',
    features: [
    ],
    bugs: [
      'CAEP: Fixed a bug on the CFAD Details tab displaying incorrect checkbox state',
      'Perkins-1C: Updated WEDD Memo button on Headcount Certification page to point to WEDD Memos page at cccco.edu',
      'SPM: Fixed a bug on the Open Reviews modal preventing the action from being completed'
    ]
  },
  {
    date: 'February 29, 2024',
    version: '6.6',
    features: [
      'All Programs: Allocations and amendments now support paste from clipboard and uploading via templates',
      'SPM: Small program funds support flexible editing with version history tracking after being certified and added',
      'SPM: Allocations may be copied between funds within a small program',
      'SPM: Fund duplication permitted within a program and between programs'
    ],
    bugs: [
      'Reporting: Send Reminder button hidden for submitted reports',
      'SPM: Fixed a bug in the allocations/amendments tables to limit institutions of type other',
      'System: Formatting of drop-down lists eliminates overwrite of down arrow',
      'System: Fund names displayed in drop-down lists now include the fund ID for easier identification',
      'System: "Uncertify" replaced with "Decertify" system-wide'
    ]
  },
  {
    date: 'February 27, 2024',
    version: '6.5.25',
    features: [
    ],
    bugs: [
      'Perkins-1C: Fixed a bug causing two back buttons to display when editing a grant'
    ]
  },
  {
    date: 'February 9, 2024',
    version: '6.5.24',
    features: [
    ],
    bugs: [
      'System: Fixed a bug where fiscal reporting printable view did not include follow up survey questions'
    ]
  },
  {
    date: 'February 2, 2024',
    version: '6.5.23',
    features: [
    ],
    bugs: [
      'EWD: Fixed a bug preventing allocation amendments from completing properly',
      'SPM: Updated custom form validator to better handle checkbox clicks when follow-on questions are specified',
      'System: Updated date picker to respect local timezones for min/max date ranges'
    ]
  },
  {
    date: 'January 25, 2024',
    version: '6.5.22',
    features: [
    ],
    bugs: [
      'CAEP: Added helper text to the Member Carryover Compliance Status table within the member fiscal reporting card',
      'SPM: Fixed a bug where in-kind match budget amounts were being overwritten in the In-Kind Match Report table'
    ]
  },
  {
    date: 'January 24, 2024',
    version: '6.5.21',
    features: [
    ],
    bugs: [
      'CAEP: Improved accurace of expenditure amount display when total exceeded allocation amount for a given year range to better mimick earmarking',
      'K12-SWP: Updates to the Application Review dashboard to support second round application reviews',
      'RCM: Fixed a bug on the allocations page that displayed duplicate outside funding sources',
      'RCM: Outside funding sources required to have a unique name'
    ]
  },
  {
    date: 'January 3, 2024',
    version: '6.5.20',
    features: [
    ],
    bugs: [
      'SPM: Fixed a bug preventing guidance card from being displayed on the Supporting Documents tab of plans and applications'
    ]
  },
  {
    date: 'December 21, 2023',
    version: '6.5.19',
    features: [
      'K12-SWP: Added the ability for regions to reopen for applications, independently of other regions'
    ],
    bugs: [
      'CAEP: Filter consortium allocations down to the CAEP fund',
      'K12-SWP: Allow batched funding offers to be sent before all regions have locked their application reviews',
      'Funding: Resolved an issue with displaying the correct "Accepted Amount" of an application on the application funding page',
      'System: Resolved an issue causing duplicated fund statuses to be created'
    ]
  },
  {
    date: 'December 5, 2023',
    version: '6.5.17',
    features: [],
    bugs: [
      'SPM: Added "Adult Education Consortia" as selectable institution type'
    ]
  },
  {
    date: 'November 8, 2023',
    version: '6.5.16',
    features: [],
    bugs: [
      'SEAP: Increased Success Story Outcomes text limit',
      'System: Resolved system errors when duplicate fiscal report creations were attempted'
    ]
  },
  {
    date: 'November 3, 2023',
    version: '6.5.15',
    features: [],
    bugs: [
      'System: Resolved an issue with missing file uploads after a plan is cloned',
      'System: Removed the possibility of duplicate fiscal report records being created',
      'SPM: Increased the maximum plan duration to 6 years'
    ]
  },
  {
    date: 'October 20, 2023',
    version: '6.5.14',
    features: [],
    bugs: [
      'Small Programs: Added "Charter Schools" as a Lead Institution type option'
    ]
  },
  {
    date: 'October 18, 2023',
    version: '6.5.13',
    features: [],
    bugs: [
      'Perkins-1C: Resolved an issue when loading institution settings for the CTE-7 workflow step',
      'Small Programs: Properly update question numbers when removing questions within a custom workflow step\'s question group',
      'CAEP: Filter out leveraged funds that do not have hours of instruction in the Program Area Report summary'
    ]
  },
  {
    date: 'October 4, 2023',
    version: '6.5.12',
    features: [],
    bugs: [
      'K12-SWP: Round 6 Applications and Plans supporting documents requirements updated'
    ]
  },
  {
    date: 'October 2, 2023',
    version: '6.5.11',
    features: [],
    bugs: [
      'K12-SWP: Round 6 Applications and Plans supporting documents requirements updated'
    ]
  },
  {
    date: 'September 25, 2023',
    version: '6.5.10',
    features: [],
    bugs: [
      'K12-SWP: Round 6 Applications and Plans now require Letter of Commitment'
    ]
  },
  {
    date: 'September 20, 2023',
    version: '6.5.9',
    features: [],
    bugs: [
      'CAEP: Fixed a permission check issue preventing approval of fiscal reports',
      'K12-SWP: Round 6 Applications and Plans updated with new selection lists for Sectors and Pathways',
      'Reporting: Fixed group and follow-up questions not being copied over to new survey versions'
    ]
  },
{
    date: 'September 11, 2023',
    version: '6.5.8',
    features: [],
    bugs: [
      'CAI: Removed Cumulative Total row from RSI Reporting tables',
      'User: Fixed a bug where small program plans/applications were not displaying a hyperlink in user profiles',
      'System: Fiscal reporting is now always visible, but can only be edited while plans are certified'
    ]
  },
  {
    date: 'September 6, 2023',
    version: '6.5.7',
    features: [],
    bugs: [
      'SWP-K12: Updated questions & helper-text for Round 6',
      'SWP-K12: Resolved an issue when attempting to remove a partner agency.',
      'System: General bug fixes and improvements'
    ]
  },
  {
    date: 'August 30, 2023',
    version: '6.5.6',
    features: [],
    bugs: [
      'SWP-K12: ADA and Unemployment Data Updated',
      'System: Various performance improvements'
    ]
  },
  {
    date: 'August 23, 2023',
    version: '6.5.5',
    features: [],
    bugs: [
      'CAI-RSI: Updated list of Apprenticeship Programs for RSI Reporting',
      'CAEP: Carryover Compliance dashboard filter now defaults to the current program year',
      'CAEP: Removed variable corrective action trigger settings for years using carryover threshold settings',
      'SWP-K12: Updated helper-text on Problem Statement workflow step'
    ]
  },
  {
    date: 'August 17, 2023',
    version: '6.5.4',
    features: [
      'CAEP: Implemented Carryover Compliance analytics dashboard'
    ],
    bugs: [
      'System: Improved behavior of pop-up UI components',
      'System: Removed "Match Funds Agreement" checkbox from funded applications where match isn\'t required.',
      'SPM: Removed "Student Success Stories" workplan step from applications',
      'SPM: Resolved an issue with cloned budget items not having the correct year set',
      'SPM: Apply budget match requirements to Non-Educational institution types',
      'SWP-K12: Increased character limit on various application input fields'
    ]
  },
  {
    date: 'August 1, 2023',
    version: '6.5.3',
    features: [],
    bugs: [
      'System: Improved dynamic sizing of the dashboard calendar',
      'System: Improved Review Dashboard UI & filter capabilities',
      'System: Resolved an issue occuring when starting the application review process'
    ]
  },
  {
    date: 'July 25, 2023',
    version: '6.5.2',
    features: [],
    bugs: [
      'CAEP: Text updates and additions for CC EC 84914'
    ]
  },
  {
    date: 'July 21, 2023',
    version: '6.5.1',
    features: [],
    bugs: [
      'CAEP: Text updates and additions for CC EC 84914',
      'CAEP: Allocation amendments now require reason and explanation narrative per CC EC84914',
      'RCM: Fixed a bug with Print View filtering so results now match the Fiscal Reporting page'
    ]
  },
  {
    date: 'July 14, 2023',
    version: '6.5',
    features: [
      'CAEP: NOVA now includes support for Carryover Compliance EC 84914'
    ],
    bugs: [
      'CAI-RSI: Renamed Final reporting period to ReCal',
      'CAI-RSI: Updated validation logic to require RSI Reporting or FTES Reporting, not both',
      'CAI-RSI: Fixed a broken anchor link in the RSI Report Status table'
    ]
  },
  {
    date: 'July 11, 2023',
    version: '6.4.1',
    features: [],
    bugs: [
      'System: Resolved error when rejecting fiscal reports',
      'System: Updated permission requirements for viewing institution details'
    ]
  },
  {
    date: 'July 10, 2023',
    version: '6.4.0',
    features: [
      'CAI RSI: Updated RSI settings and reporting',
      'SPM: Added ability to create public, non-educational grants',
      'SPM: Added configurable guidance text for fiscal reporting',
      'Perkins-1C: Allow editing of expenditure values on the Final Report'
    ],
    bugs: [
      'System: Updated view permission requirements for all users',
      'System: Resolved issue generating PDF/HTML snapshots of Final Reports',
      'K12 TAP/PC: Modified funding & reporting years for existing grants'
    ]
  },
  {
    date: 'June 15, 2023',
    version: '6.3.4',
    features: [],
    bugs: [
      'System: Resolved an issue with duplicate action items',
      'System: Resolved an issue with deleting roles with expiration dates'
    ]
  },
  {
    date: 'June 7, 2023',
    version: '6.3.3',
    features: [],
    bugs: [
      'Added redirect endpoint for embedding Looker Dashboards.'
    ]
  },
  {
    date: 'June 1, 2023',
    version: '6.3.2',
    features: [],
    bugs: [
      'CAEP: Added ability to view historical Annual Plan previews',
      'System: Updated custom survey validation logic around group questions',
    ]
  },
  { 
    date: 'May 19, 2023',
    version: '6.3.1',
    features: [],
    bugs: [
      'SPM: Fixed a bug preventing file uploads in custom steps'
    ]
  },  
  { 
    date: 'May 17, 2023',
    version: '6.3',
    features: [
      `In the latest version release of NOVA, we have introduced a User Dashboard to display your 
      action items alongside an event calendar. This allows you to clearly view and navigate to current, upcoming, 
      and past due actionable tasks. Items will automatically be marked as Completed once you've completed the task.`
    ],
    bugs: [
      'Plans: Mark contacts as deleted when a plan is deleted',
      'System: Refactored data storage strategy for file uploads'
    ]
  },  
  { 
    date: 'May 16, 2023',
    version: '6.2.15',
    features: [],
    bugs: [
      'System: Fixed an error when fetching the creator_id of the user when allocating to plans',
      'RC Management: Fixed another bug where the Send Funds to Plans button was not functioning as expected'
    ]
  },
  { 
    date: 'May 15, 2023',
    version: '6.2.14',
    features: [],
    bugs: [
      'Perkins 1C: Fixed a bug and updated validation for adding activities on the Program Info subapplication tab',
      'RC Management: Fixed a bug where the Send Funds to Plans button was not functioning as expected'
    ]
  },
  { 
    date: 'May 12, 2023',
    version: '6.2.13',
    features: [],
    bugs: [
      'Perkins 1C: Updated text and hyperlinks for Permissive uses of funds'
    ]
  },
  { 
    date: 'May 11, 2023',
    version: '6.2.12',
    features: [],
    bugs: [
      'RCM: Added an ID filter to the dashboard and fixed missing links to plans'
    ]
  },
  { 
    date: 'May 5, 2023',
    version: '6.2.11',
    features: [],
    bugs: [
      'System: Fixed a bug preventing K12 Application Funding page from loading correctly'
    ]
  },
  { 
    date: 'May 4, 2023',
    version: '6.2.9',
    features: [],
    bugs: [
      'Perkins 1C: Removed question 18 from 2024 application; Updated missing FAUPL values on CTE-7 tab',
      'SPM: Updated validation within optional question groups to require input only after the first required field is entered',
      'System: Display name of user responsible for allocations and amendments where available'
    ]
  },
  { 
    date: 'April 26, 2023',
    version: '6.2.8',
    features: [],
    bugs: [
      'GP: Fixed a bug preventing V1 projects from opening correctly',
      'Reporting: Improved filter operation on reporting dashboards to better integrate funding year and reporting period selections',
      'Reporting: Added "Awaiting Submittal" status and Fund filters to dashboard',
      'User Profile: Fixed a bug preventing phone number from being deleted and saved'
    ]
  },
  { 
    date: 'April 17, 2023',
    version: '6.2.7',
    features: [],
    bugs: [
      'SPM: Fixed a bug preventing competitive fund reviews from being locked when number of reviewers was fewer than 3',
      'SPM: Fund transfer notification settings added to fund setup workflow'
    ]
  },
  { 
    date: 'April 12, 2023',
    version: '6.2.6',
    features: [],
    bugs: [
      'CAEP: Removed 3YP year from Regional Planning Overview helper text',
      'CAI: Added Grant ID to Fiscal Reporting header',
      'Perkins 1C: Added TOP Code hyperlinks and anchors to application preview for easier navigation on large applications',
      'Plans: Fixed HTML elements showing up in user comments in Project Timeline',
      'Plans: Added Plan ID column and filter to all Plan dashboards',
      'RCM: Fixed missing budget items and forecast errors for applications',
      'RCM: Fixed print modal to show the correct Allocation Year options',
      'RCM: Set "Printable View" button to always appear when not in Print mode and fixed Share button to show Share Document modal',
      'SPM: Set "Enable Encumbered Funds" setting to be editable after grant has already been approved',
    ]
  },
  { 
    date: 'March 29, 2023',
    version: '6.2.5',
    features: [],
    bugs: [
      'CAEP: CFAD updated to collect member carryover threshold percentage'
    ]
  },
  {
    date: 'March 23, 2023',
    version: '6.2.4',
    features: [],
    bugs: [
      'NEP: Updated storage methodology for activities to facilitate better adhoc reporting'
    ]
  },
  {
    date: 'March 16, 2023',
    version: '6.2.3',
    features: [],
    bugs: [
      'SPM: Updated fund preview to display encumbered funds reporting setting as disabled by default'
    ]
  },
  {
    date: 'March 13, 2023',
    version: '6.2.2',
    features: [],
    bugs: [
      'CAEP: Corrected a validation issue on the CFAD Governance tab that was preventing submission',
      'CAEP: Addressed an issue where the year of the current Annual Plan was incorrectly displayed on the consortium dashboard',
      'RCM: Filtered out excess allocations displayed on the allocations page for outside funding sources',
      'SPM: Prevent invalid combinations of feature selections and simplified logic for creating reporting tasks'
    ]
  },
  {
    date: 'March 8, 2023',
    version: '6.2.1',
    features: [],
    bugs: [
      'CAEP: Fixed a bug that was excluding expenditures of removed member institutions in the FIFO report',
      'CAEP: Fixed a bug that was hiding past fiscal reports of removed member institutions',
      'CAEP: Added admin quick access menu to Program Area Reporting preview for users with administrator access',
      'Perkins 1C: Added logic to the availability of the Lock Headcount Certifications button to honor the funding year filter selection',
      'RCM: Refined plan budget tab validation',
      'SPM: Hid Encumbered Funds setting if Expense Reporting is not included',
      'SPM: Hid Fiscal Reporting button on the Plan preview for Small Programs that do not require reporting',
      'SPM: Fixed Guidance templates throwing a system error',
      'SPM: Updated competitive fund review configurator to allow a minimum of 1 reviewer per application',
      'SPM: Updated allocations screen to display institutions in alpha order in all browsers',
      'SPM: Updated reporting approvals so Fund Monitor can no longer approve on-behalf-of other approvers',
      'SWP: Added a District filter to SWP L and R Plans dashboards',
      'System: Added a direct link to program Plans dashboard from the Applications dashboard when no competitive grants are open for applications',
      'System: Refined display of loading spinner when publishing custom surveys'
    ]
  },
  {
    date: 'March 1, 2023',
    version: '6.2',
    features: [
      'SPM: Fund transfers within a program now available to program leads',
      'SPM: Program leads may define funds that start in a different fiscal year than the funding source',
      'SPM: Program leads can create funds with variable duration by quarter instead of fiscal year',
      'SPM: Program leads can create funds that start or stop on quarter boundaries',
      'SPM: Added support for configuring and reporting of encumbered funds'
    ],
    bugs: [
      'System: Updated filename encoding algorithm to handle special characters better',
      'Dashboard: Improved layout to show expanded bookmarks above the fold'
    ]
  },
  {
    date: 'February 28, 2023',
    version: '6.1.3',
    features: [],
    bugs: [
      'K12-SWP: Enabled budget modification feature for certified plans',
      'SWP: Enabled budget modification feature for local and regional certified plans',
      'SPM: Fixed a bug in the contacts table to hide project associated roles that should not be shown'
    ]
  },
  {
    date: 'February 15, 2023',
    version: '6.1.1',
    features: [],
    bugs: [
      'Perkins 1C: Provided link to WEDD headcount memo'
    ]
  },
  {
    date: 'February 10, 2023',
    version: '6.1',
    features: [],
    bugs: [
      'Button styles modernized throughout the application',
      'User dashboard usability and feature improvements'
    ]
  },
  {
    date: 'January 31, 2023',
    version: '6.0.7',
    features: [],
    bugs: [
      'Service compatibility updates'
    ]
  },
  {
    date: 'January 26, 2023',
    version: '6.0.7',
    features: [],
    bugs: [
      'Perkins: Fixed issue where reporting periods were not showing up on custom survey create screen'
    ]
  },
  {
    date: 'January 11, 2023',
    version: '6.0.6',
    features: [
      'Dates & Messaging: Improved recipient management for scheduled messages'
    ],
    bugs: []
  },
  {
    date: 'December 16, 2022',
    version: '6.0.5',
    features: [],
    bugs: [
      'Dates & Messaging: Added set width for Event Names column for consistent vertical appearance',
      'Hardened funding validation for competitive grants to cover more scenarios to prevent overfunding',
      'Nursing: CCCCO Project Monitors can no longer approve on-behalf-of other approvers',
      'Updated new header to always show the subheader for consistent action button availability',
      'Updated service to set recipient-filter on RCM allocation notification'
    ]
  },
  {
    date: 'December 10, 2022',
    version: '6.0.4',
    features: [],
    bugs: [
      'CAEP: Fixed "Agree & Submit" button style for fiscal report submit acknowledgement',
      'SPM: Updated "Comprehensive Score" total points to pull from the grant configuration settings'
    ]
  },
  {
    date: 'December 7, 2022',
    version: '6.0.3',
    features: [],
    bugs: [
      'Restored fiscal reporting tasks for Small Program funds',
      'Recovered the [Add Institution] button on the Institutions Dashboard',
      'Fixed a header formatting issue with the [Create Application] button for CAINI program',
      'Resolved a silent system error generated by the Share feature'
    ]
  },
  {
    date: 'December 5, 2022',
    version: '6.0',
    features: [
      'In the latest major version release of NOVA, we have introduced a User Dashboard to clearly display your action items alongside an event calendar. This allows you to clearly see and navigate to current, upcoming, and past due actionable tasks. Items will automatically be marked as Completed when you have completed the task.',
      'A more robust Dates & Messaging is also part of this release. The updates support program level calendar events and are what drive the progress and success of your programs.',
      'We have also redesigned the NOVA page headers in an effort to display more relevant information in a concise way. You will now notice this is where your name and user profile appears.',
      'And, you will notice you can collapse the main menu for more working space on narrower screens'
    ],
    bugs: []
  },
  {
    date: 'November 29, 2022',
    version: '5.0.16',
    features: ['Added Privacy Policy'],
    bugs: []
  },
  {
    date: 'November 17, 2022',
    version: '5.0.15',
    features: [],
    bugs: [
      'SPM: Corrected route for fiscal reporting bookmarks'
    ]
  },
  {
    date: 'November 2, 2022',
    version: '5.0.14',
    features: [],
    bugs: [
      'Admin: Fixed a bug preventing role transfers',
      'CAEP: Fixed a display issue with objectives and strategies in the Budget & Workplan Consortium Roll-up',
      'SWP: Implemented additional fiscal reporting check for Close Plan eligibility'

    ]
  },
  {
    date: 'October 20, 2022',
    version: '5.0.13',
    features: [],
    bugs: [
      'K12: Fixed a bug preventing reviews opening for Round 5'
    ]
  },
  {
    date: 'October 14, 2022',
    version: '5.0.12',
    features: [],
    bugs: [
      'SPM: Fixed a validation bug in the review workflow'
    ]
  },
  {
    date: 'October 10, 2022',
    version: '5.0.11',
    features: [],
    bugs: [
      'SWP: Updated expenditures filter for Close Project to exclude expenditures from colleges removed from the plan',
      'SWP: Enabled the Submit button for 2022-23 plans'
    ]
  },
  {
    date: 'September 29, 2022',
    version: '5.0.10',
    features: [],
    bugs: [
      'Perkins 1C: Added a new question to the Forms/Responses tab',
      'SEA Program: Fixed a grammar error on the Mid-Allocation Report Expenditures tab'
    ]
  },
  {
    date: 'September 20, 2022',
    version: '5.0.9',
    features: [],
    bugs: [
      'CAEP: Updated Program Area reporting status cards to include certified reports in the "Submitted" statistic',
      'SPM: New users to signup and complete their profile are automatically directed to the appropriate dashboard when the sign-up link is used'
    ]
  },
  {
    date: 'September 16, 2022',
    version: '5.0.8',
    features: [],
    bugs: [
      'SWP: Fixed a task filter to allow close project requests to be approved'
    ]
  },
  {
    date: 'September 7, 2022',
    version: '5.0.7',
    features: [],
    bugs: [
      'K12-SWP: Moved "Underserved Student Populations" card to Lead & Partner tab',
      'K12-SWP: For Round 5, added a required upload of Cal-Pass Plus MOU for lead and partner agencies who received funding in the prior year',
      'K12-SWP: Removed a page number reference from the Workplan',
      'Perkins 1C: Added Dates & Messaging tab and events to Grant Settings'
    ]
  },
  {
    date: 'August 29, 2022',
    version: '5.0.6',
    features: [],
    bugs: [
      'K12-SWP: Display full name of selected participating schools',
      'K12-SWP: Fixed file upload not allowing upload of same file twice in a row',
      'K12-SWP: Added "Application Region" filter to Plans and Reporting dashboards',
      'K12-SWP: Fixed app error when navigating to Lead & Partner guidance page',
      'Perkins 1B: Funding dashboard shows correct applications',
      'System: Fixed minor UI display and CSS issues'
    ]
  },
  {
    date: 'August 16, 2022',
    version: '5.0.4',
    features: [],
    bugs: [
      'System: Fixed a formatting issue with the User Admin pop-up menu seen by administrators'
    ]
  },
  {
    date: 'August 9, 2022',
    version: '5.0.3',
    features: [],
    bugs: [
      'Equity Plan: Corrected a spelling error on the Equity Plan Reflection tab',
      'CAI-NI: Changed reporting cadence to bi-annual per CO request'
    ]
  },
  {
    date: 'August 3, 2022',
    version: '5.0.2',
    features: [],
    bugs: [
      'RCM: Fixed a system error when navigating to the Fiscal Reporting dashboard',
      'SWP 2.0: Improved Continue Plan functionality so it will become available automatically when a new year is allocated',
      'SWP 2.0: Funding year can now be changed while plans are in Draft status in case of error when created or rejection due to insufficient funds',
      'System: Fixed a number of minor style issues for improved UI appearance'
    ]
  },
  {
    date: 'July 29, 2022',
    version: '5.0.1',
    features: [],
    bugs: [
      'CAI: Fixed RSI reporting validation to identify empty required fields',
      'CAI: Reporting dashboard Clear Filter button retains reporting period',
      'Reporting: Fixed a bug where drop-down question types were not displayed on the preview or in reports and could not be completed'
    ]
  },
  {
    date: 'July 12, 2022',
    version: '5.0',
    features: [
      'System: Updated to Angular 13 system-wide'
    ],
    bugs: [
      'RCM: Fixed reporting validation to use correct prior quarter values'
    ]
  },
  {
    date: 'July 1, 2022',
    version: '4.50.6',
    features: [],
    bugs: [
      'CAEP: Updated helper text for 2022-23+ Annual Plan',
      'SPM: Fixed a validation issue in custom steps with the file upload validator preventing tab from showing complete',
      'SPM: Fixed field display for uploaded documents so Uploaded By is shown and comments display without visible HTML tags'
    ]
  },
  {
    date: 'June 24, 2022',
    version: '4.50.5',
    features: [],
    bugs: [
      'CAEP: Show approver name in card always',
      'SWP L/R: Updated SWP 2.0 FAQ button hyperlink to point to CCCCO website FAQ'
    ]
  },
  {
    date: 'June 15, 2022',
    version: '4.50.4',
    features: [],
    bugs: [
      'Perkins 1C: Fixed a storage bug in the Target Performance 2P1 narrative field',
      'SEA Plan 2.0: Renamed the Retention tab to Persistence'
    ]
  },
  {
    date: 'June 14, 2022',
    version: '4.50.3',
    features: [],
    bugs: [
      'Perkins 1B: Updated validation of Details tab to fix system error preventing validation to pass',
      'SPM: Fixed followup question refresh and updated validation within question groups to require at least one response when the group is required, but all questions within it are optional',
      'Surveys: Added "Allow multiple responses" setting (unchecked by default) to Question Group so related questions can be grouped together without a multi-response button showing',
      'SWP: Temporarily disable submit of 2023 plans until final allocations are determined'
    ]
  },
  {
    date: 'June 10, 2022',
    version: '4.50.2',
    features: [
      'CAEP: 2022-23 Program Area Reporting now available in NOVA'
    ],
    bugs: [
      'CAEP: Updated storage approach for Annual Plan strategies to respect uniqueness',
      'CAEP: Updated logic to determine which years allocation amendments are permitted',
      'SPM: Updated validator to handle an obscure case where hidden characters were not triggering a validation error'
    ]
  },
  {
    date: 'June 8, 2022',
    version: '4.50.1',
    features: [],
    bugs: [
      'SEA Plan 2.0: Fixed a permission check error preventing Project Lead from completing the Contacts tab',
      'SWP 2.0: Extended max plan duration to 3 years for 2020-21 and later plans'
    ]
  },
  {
    date: 'June 3, 2022',
    version: '4.50',
    features: [
      'Student Success Metrics 4.0 Now Available In NOVA'
    ],
    bugs: [
      'CAEP 3YP: Updated calculation for "Percent of Available Funds Spent" to match the FIFO report calculation',
      'CAEP Annual Plan: Individual metrics can now be selected for each strategy; Quick link button to 3YP added to header',
      'CAEP Budget & Workplan: Any institution with available funds now required to submit and report',
      'SEA Plan 2.0: Helper text updated in the metrics subworkflow',
      'System: Improved Grant cloning logic'
    ]
  },
  {
    date: 'May 31, 2022',
    version: '4.49.6',
    features: [],
    bugs: [
      'SEA Plan 2.0: Updates to Metric sub-workflows',
      'System: Improved Grant cloning logic'
    ]
  },
  {
    date: 'May 25, 2022',
    version: '4.49.5',
    features: [],
    bugs: [
      'Perkins 1C: Updated Target Performance card in the Certification sub-workflow to display historical values',
      'System: Added Admin Quick-Access to users displayed in Plan History view'
    ]
  },
  {
    date: 'May 23, 2022',
    version: '4.49.4',
    features: [],
    bugs: [
      'CAEP: Fixed a preview display issue on Budget & Worplan where checkboxes were visible and should not have been',
      'CAEP: Any user may now be selected as a responsible party for Three Year Plan activities',
      'RCM: Updated budgeting start years and refactored validation to support additional funding beyond Year 1'
    ]
  },
  {
    date: 'May 16, 2022',
    version: '4.49.3',
    features: [],
    bugs: [
      'GP: Fixed a routing issue with some plans'
    ]
  },
  {
    date: 'May 14, 2022',
    version: '4.49.2',
    features: [],
    bugs: [
      'System: Fixed a routing issue with some programs not displaying the preview of certified plans'
    ]
  },
  {
    date: 'May 13, 2022',
    version: '4.49.1',
    features: [
      'CAEP: Launchboard 5.0 data now available in NOVA for Three Year Plan'
    ],
    bugs: [
      'GP: Fixed a system error when routing to v2 plans from certain pages',
      'Surveys: Fixed validation for drop-down question type',
      'System: Updated behavior of Guidance cards to display expanded by default'
    ]
  },
  {
    date: 'May 5, 2022',
    version: '4.49',
    features: [
      'CAEP: Annual Plan workflow now available in NOVA for Consortia with certified CFAD and Three Year Plan',
      'Apprenticeship: Added Apprenticeship Programs table to RSI Reporting'
    ],
    bugs: [
      'CAEP: Fixed a race condition within the preview page that was causing the Governance questions to not display properly without refresh',
      'CAEP: Fixed a comment display issue where HTML tags were visible on Fiscal Reporting',
      'Perkins 1C: Regional Priority Sectors list of values updated',
      'Perkins Reserve: Colleges may now apply for the grant',
      'RCM: Application and Plan Preview now show nested view of objectives/activities/budgets'
    ]
  },
  {
    date: 'April 29, 2022',
    version: '4.48.6',
    features: [],
    bugs: [
      'CAEP 3YP: Increase text limit on Objectives descriptions; "All" metrics options available for Activities; Updated validation on Assessment tab; and fixed Next button behavior'
    ]
  },
  {
    date: 'April 20, 2022',
    version: '4.48.4',
    features: [],
    bugs: [
      'SEP 2.0: Update helper text for metric sub-workflow',
      'SPM: Hide "Fiscal Reporting" menu item when no grants within the program require it',
      'User: Quick access to user actions added to more programs'
    ]
  },
  {
    date: 'April 19, 2022',
    version: '4.48.3',
    features: [
      'EWD: Plan allocation amendments now supported'
    ],
    bugs: [
      'CAEP 3YP: Update validataion on spending targets',
      'Perkins: Fix display issues on Invoicing Payment Report',
      'RCM: Fiscal reporting quarter status indicator updates correctly for unsubmitted quarters',
      'RCM: Fixed fiscal reporting dashboard to show proper links within cards',
      'SWP: Add plan id and funding year display and filters to Plans dashboard'
    ]
  },
  {
    date: 'April 15, 2022',
    version: '4.48.2',
    features: [
    ],
    bugs: [
      'SEP 2.0: Increased narrative field character limit to 7500 on the Completion tab',
      'SEP 2.0: Validation on Survey tab honors optional flag in question configuration'
    ]
  },
  {
    date: 'April 12, 2022',
    version: '4.48.1',
    features: [
    ],
    bugs: [
      'K12 Round 1: Added helper text and updated alert messaging to clearly communicate partner requirements',
      'SEP 2.0: Fixed a bug preventing fresh load of proposal models'
    ]
  },
  {
    date: 'April 8, 2022',
    version: '4.48',
    features: [
      'SEA Program: 2022-2025 Plan now available in NOVA'
    ],
    bugs: [
      'Institutions: Admin users can now add new institutions from the dashboard',
      'SPM: Non-educational institutions now available for applications and funding'
    ]
  },
  {
    date: 'April 5, 2022',
    version: '4.47.9',
    features: [
      'Custom Surveys: Added "Dropdown" question types'
    ],
    bugs: [
      'Custom Surveys: Enabled sortable response options for Radio, Checkbox, and Dropdown questions',
      'Perkins Reserve: Enabled plan length configuration'
    ]
  },
  {
    date: 'March 30, 2022',
    version: '4.47.8',
    features: [],
    bugs: [
      'CAEP: Disabled the Annual Plan link on Consortium Dashboard',
      'System: Enabled elastic search'
    ]
  },
  {
    date: 'March 25, 2022',
    version: '4.47.7',
    features: [],
    bugs: [
      'CAEP: On Three Year Plan, show the name of the institution reporting metrics to Launchboard for the NOVA consortium member',
      'EWD: Display grant amount in budget summary card on plan preview',
      'EWD: Grants may now be extended by the program manager more than one time',
      'System: Updated bookmark select filtering to improve performance of API call'
    ]
  },
  {
    date: 'March 21, 2022',
    version: '4.47.6',
    features: [],
    bugs: [
      'Perkins: Minor style updates to invoice PDF',
      'RCM: Fixed a display issue with guidance cards in plans',
      'SPM: Fixed a display issue with cumulative review surveys not showing link to uploaded files',
      'SWP: Updated Allied Health language and link to use new program in NOVA'
    ]
  },
  {
    date: 'March 18, 2022',
    version: '4.47.5',
    features: [],
    bugs: [
      'Perkins: Fixed logic for kebab menu delete on the Invoicing dashboard and a system error on the 1B reporting dashboard',
      'RCM: Added sticky card to Activities & Outcomes tab to show number of activities per objective to inform approvers',
      'Reporting: Squashed a bug where follow-up questions for radio buttons were not being properly linked to their parent during survey creation'
    ]
  },
  {
    date: 'March 17, 2022',
    version: '4.47.4',
    features: [],
    bugs: [
      'CAI: Fixed a permission check on the plan budget tab that was preventing the primary contact from editing the fields'
    ]
  },
  {
    date: 'March 11, 2022',
    version: '4.47.3',
    features: [],
    bugs: [
      'CAEP Three Year Plan: Display character count on Activity Name field',
      'CAEP Metrics: Updated institution mappings for more accurate display of historical values in NOVA',
      'Reporting: Squashed a bug where follow-up questions were not being properly linked to their parent during survey creation'
    ]
  },
  {
    date: 'March 8, 2022',
    version: '4.47.2',
    features: [],
    bugs: [
      'CAEP 3YP: Fixed a bug with the additional service providers checkbox processing on the Assessment tab',
      'Reporting: Fixed a custom survey display issue where some checkboxes were not being shown'
    ]
  },
  {
    date: 'March 7, 2022',
    version: '4.47.1',
    features: [],
    bugs: [
      'CAEP Three Year Plan: Reworked Assessment and Metrics tab tables to work better on lower resolution screens',
      'CAEP Three Year Plan: Share button on Preview sends correct content',
      'K12-SWP: Final Report now is only available to budgeted institutions',
      'K12-TAP/PC: Custom surveys available for all institutions'
    ]
  },
  {
    date: 'March 1, 2022',
    version: '4.47',
    features: [
      'Perkins: Invoicing now available in NOVA',
      'User: Admins now have quick access to user actions in some programs'
    ],
    bugs: [
      'Allied Health: Added project lookup analytics route for external linking',
      'CAEP: Newly created service providers through the Three Year Plan no longer displayed as members',
      'CAEP: Fixed a system error when unchecking the "Non-Allocated (Voting Member Only)" checkbox on the CFAD',
      'CAI: Fixed a bug that prevented adding a partner institution after one was removed',
      'K12-SWP: Only budgeted institutions see custom surveys; Final report survey available for all budgeted institutions, not just the lead',
      'RCM/SPM: Added a new narrative question to the budget tab for both programs',
      'Reporting: Preserve order of questions when copying a custom survey to a new version',
      'SWP 2.0: Plan length can be extended to 3 years',
      'System: Added new DEI objective type to all programs'
    ]
  },
  {
    date: 'February 18, 2022',
    version: '4.46.7',
    features: [],
    bugs: [
      'SPM: Fixed preview to include all budget years',
      'System: Implement cache for Redshift metric responses to reduce response time',
      'System: Handle invalid proposalId with 404 error'
    ]
  },
  {
    date: 'February 14, 2022',
    version: '4.46.5',
    features: [],
    bugs: [
      'K12-SWP: Fixed approval routing link creation for TAP-PC',
      'Reporting: Enhanced permission checks for subfunds'
    ]
  },
    {
      date: 'February 11, 2022',
      version: '4.46.4',
      features: [],
      bugs: [
        'CAEP: Updated Assessment and Metrics tabs to include all member types',
        'K12-SWP: Removed the Manage Deferred Allocations card from Allocations dashboard',
        'K12-SWP: Updated reporting so only budgeted partners complete Round 1 mid-year/final report surveys'
      ]
    },
    {
      date: 'February 4, 2022',
      version: '4.46.2',
      features: [
        'CAEP: Integrated Cal-PASS metrics data with Three Year Plans'
      ],
      bugs: [
        'SPM: Fixed an issue loading plan preview routes',
        'System: Re-expand navigation menu after refreshing the page'
      ]
    },
    {
      date: 'February 1, 2022',
      version: '4.46.1',
      features: [],
      bugs: [
        'CAEP 3YP: Updated programs on Assessment tab',
        'EWD: Fixed a bug where surveys were not being displayed on fiscal reports',
        'SPM: Fixed a bug where allocation amount was only displayed on first year of fiscal report for multi-year plans'
      ]
    },
    {
      date: 'January 31, 2022',
      version: '4.46',
      features: [
        'K12-SWP: Custom Surveys now specific to each grant for improved customization'
      ],
      bugs: [
        'CAEP 3YP: Removed display of 2018-19 Actuals on the Metrics tab',
        'Perkins 1B: Renamed last quarter before Final to "Closeout" on UI and in Share/Print',
        'Perkins 1C: Updated references and referenced report files on Headcount Certification to 2020-21',
        'Perkins 1C: Fixed a system error on the Headcount Certification dashboard',
        'SWP 2.0: Updated round 2 fiscal reporting to bi-annual cadence',
        'System: Improved Custom Survey validator'
      ]
    },
    {
      date: 'January 26, 2022',
      version: '4.45.2',
      features: [],
      bugs: [
        'CAEP: Helper text updates for 3 Year Plan',
        'Perkins 1C: Updated text for Core Indicators, ingested 2022-23 headcount numbers, updated linked documents',
        'System: Limit the institution types that users can add'
      ]
    },
    {
      date: 'January 20, 2022',
      version: '4.45.1',
      features: [],
      bugs: [
        'CAEP: Fixed a bug with fiscal report sharing',
        'Perkins 1B: Minor improvements for competitive applications',
        'SPM: Fixed a couple minor issues with Guidance text display and configuration',
        'System: Comment fields now support formatting via Froala toolbar'
      ]
    },
    {
      date: 'January 19, 2022',
      version: '4.45',
      features: [
        'K12-SWP: Enhanced TAPs/Coordinators now available in NOVA',
        'Perkins: Perkins Reserve as a competitive program now available in NOVA'
      ],
      bugs: [
        'CAEP: Restored approval timestamp for historical fiscal reports',
        'Perkins 1B: Minor improvements for competitive applications',
        'SPM: Fixed a couple minor issues with Guidance text display and configuration',
        'System: Comment fields now support formatting via Froala toolbar'
      ]
    },
    {
      date: 'January 17, 2022',
      version: '4.44.5',
      features: [],
      bugs: [
        'Apprenticeship: Enhanced RSI Reporting workflow to copy prior year institutions',
        'CAEP 3YP: Set Regional Service Providers type to Member Representative for member agencies; Correct display and link to Program Area data to 2 years prior',
        'Custom Surveys/Steps: Reorder questions after deletion and validate character limit on question titles',
        'Perkins 1B: Created page and routing for external analytics linking',
        'SPM: Fixed permission check so Program Managers can access all links for newly created programs; Budgets now optional for competitive funds',
        'System: Minimum character count for free-form entry fields is now two'
      ]
    },
    {
      date: 'January 12, 2022',
      version: '4.44.2',
      features: [],
      bugs: [
        'SPM: Fixed a bug preventing assigning additional reviewers to individual plans; Description field now optional on Details tab of plans and applications',
        'System: Added expandability to system message banner when text exceeds width of browser window'
      ]
    },
    {
      date: 'January 6, 2022',
      version: '4.44.1',
      features: [
        'SPM: Competitive grant review functionality enabled'
      ],
      bugs: [
        'CAEP: Fixed a bug where the incorrect year was being displayed on the Q10 close-out dialog',
        'K12-SWP/SWP: Added assurance to budget modification Save Changes modal',
        'Nursing: Updated a couple labels on the Workplan tab with more sustainable language'
      ]
    },
    {
      date: 'December 22, 2021',
      version: '4.44.0',
      features: [
        'K12-SWP: Changed to Bi-Annual Reporting Schedule',
        'SPM: Added "Analytics" dashboard for each program'
      ],
      bugs: []
    },
    {
      date: 'December 17, 2021',
      version: '4.43.0',
      features: [
        'NEP: Custom Surveys now available in NOVA'
      ],
      bugs: [
        'CAEP: Limited the "Project Budget" columns in fiscal reporting to the budget for the selected year',
        'SEA: Removed funding year filter and column from plans dashboard',
        'SEA: Resolved an error when viewing the Metrics tab of an SEP Mid-allocation report after removing a custom population group'
      ]
    },
    {
      date: 'December 16, 2021',
      version: '4.42',
      features: [
        'CAEP: Updated fiscal reporting and custom surveys now available in NOVA'
      ],
      bugs: [
        'GP: Added Project Editor role to Alternate Lead Contact so they may work on the Final Report',
        'SPM: Collect Metrics Target/Goals For Full Duration of Plan/Application',
        'SPM: Fixed a bug where HTML pasted into the Application Description field was not passing validation'
      ]
    },
    {
      date: 'December 10, 2021',
      version: '4.41.2',
      features: [
      ],
      bugs: [
        'Custom Surveys and Steps: Increased character limit for grid rows to 255 from 100',
        'SEA: Permit $0 allocations for all institution types',
        'SWP: Added logic to prevent report submit if expenditure exceeds budgeted amount',          
        'SWP: Added support for modifying budget amounts while plans are certified'
      ]
    },
    {
      date: 'December 8, 2021',
      version: '4.41.1',
      features: [
        'GP: Cycle closeout now available'
      ],
      bugs: [
        'GP: Restored visibility of GP reports',
        'RCM: Allocation and amendment UI improvements',
        'SPM: Resolved issue caused by projects belonging to deleted programs'
      ]
    },
    {
      date: 'December 1, 2021',
      version: '4.41',
      features: [
        'SWP 2.0: Continue Plan now available for Cycle 2 projects',
        'SWP-R: New Looker dashboard'
      ],
      bugs: [
        'K12-SWP: Prevent shifting funds for 7000 object code (Indirect) when adjusting budget on certified plan',
        'Perkins: Fixed an issue with RSI Reporting submit approval task filter preventing submit under rare circumstances',
        'SPM: Removed the Expand Budget button from the Plans dashboard as it was not applicable to these programs',
        'SPM: Fixed a bug where Assurances were not displaying the title or attached file on the application/plan'
      ]
    },
    {
      date: 'November 19, 2021',
      version: '4.40.5',
      features: [],
      bugs: [
        'GP: Updates to final report'
      ]
    },
    {
      date: 'November 12, 2021',
      version: '4.40.1',
      features: [
        'GP: Final report and survey now available in NOVA'
      ],
      bugs: []
    },
    {
      date: 'November 9, 2021',
      version: '4.40',
      features: [],
      bugs: [
        'SPM: Fixed optional field validation for custom tabs'
      ]
    },
    {
      date: 'November 8, 2021',
      version: '4.39.11',
      features: [],
      bugs: [
        'K12: Updated refresh logic for budget modifications',
        'K12: Added logic to prevent report submit if expenditure exceeds budgeted amount',
        'SPM: Fixed a bug where guidance card was not showing on some tabs and added configuration for custom tab guidance',
        'SPM: Workplan: Objectives "Regional Strategy" and "Sector Strategy/Regional Priority" narratives are now optional',
        'SWP 2.0: Fixed an obscure bug where the contacts tab on certified plans was showing incomplete if the District approver was removed',
        'System: Updated error stepper to trigger on keyup events'
      ]
    },
    {
      date: 'October 29, 2021',
      version: '4.39.10',
      features: [
        'K12-SWP: Added support for modifying budget amounts while plans are certified'
      ],
      bugs: [
        'Analytics Dashboards: Updated domain for embeded analytics dashboards'
      ]
    },
    {
      date: 'October 27, 2021',
      version: '4.39.9',
      features: [],
      bugs: [
        'Review Assessment: Fixed an issue rendering custom review assessments in template mode'
      ]
    },
    { 
      date: 'October 26, 2021',
      version: '4.39.8',
      features: [],
      bugs: [
        'Custom Surveys: Fixed an issue with multi-institution custom survey reports failing validation for all when only one institution had an error',
        'SWP: Fixed a display issue where the total row was miscalculating the year-to-date allocations sum'
      ]
    },
    { 
      date: 'October 20, 2021',
      version: '4.39.7',
      features: [],
      bugs: [
        'Custom Surveys: Updated a permissions check on previews to eliminate display of action buttons instead of disabling them',
        'SYSTEM: Fixed an issue where historical PDF/HTML files were not being generated for some event types'
      ]
    },
    { 
      date: 'October 18, 2021',
      version: '4.39.6',
      features: [],
      bugs: [
        'K12-SWP: Updated preview screen to show application record created at timestamp',
        'SWP 2.0: Fixed the available funds calculation',
        'SYSTEM: Fixed an issue where approver cards were not being updated after approval'
      ]
    },
    { 
      date: 'October 13, 2021',
      version: '4.39.4',
      features: [],
      bugs: [
        'Reporting: Fixed an issue where the Submit button was not available even though validation passed',
        'K12-SWP: Fixed a bug where submitted applications were not updating their status from Draft',
        'SEA Program: Fixed an issue with District Term End Reports preventing allocation changes when no initial allocation was made',
        'SYSTEM: Added wrap to selection lists where content exceeds boundaries of the control'
      ]
    },
    { 
      date: 'October 12, 2021',
      version: '4.39.3',
      features: [
        'Small Programs: Competitive application process now available in NOVA'
      ],
      bugs: [
        'Reporting: Custom surveys now support question groups',
        'SWP: Fixed a bug preventing addition of multiple objectives to 2.0 plans',
        'USER: Updated Notifications feature to display most recent first so infrequent visitors will not miss important messages'
      ]
    },
    { 
      date: 'October 8, 2021',
      version: '4.39.2',
      features: [
        'CAEP: Custom Governance tab added to CFAD for 2022-23 and beyond'
      ],
      bugs: [
        'K12-SWP: Fixed a rare bug in Round 4 application Historical Performance displaying incorrect Funded Amount',
        'SYSTEM: Standardized reporting notification subject lines to include fund abbreviation and common format across programs',
        'USER: User profile no longer displays roles for deleted projects'
      ]
    },
    { 
      date: 'October 6, 2021',
      version: '4.39.1',
      features: [],
      bugs: [
        'SYSTEM: Fixed an issue where the Submit button was available on already submitted plans'
      ]
    },
    { 
      date: 'October 4, 2021',
      version: '4.39',
      features: [
        'CAEP: Three Year Plan workflow now available in NOVA'
      ],
      bugs: [
        'CAEP: Moved Member Agencies card higher up on the Consortium Dashboard',
        'K12-SWP: Modified Historical Performance table to include plans where the lead or partners from current application were also a lead or partner on a past funded application',
        'SYSTEM: Handle special characters in search term fields better',
        'SYSTEM: Added multi-select to fund names when assigning fund specific roles to users'
      ]
    },
    {
      date: 'September 20, 2021',
      version: '4.38.4',
      features: [],
      bugs: [
        'K12-SWP: Fixed an issue with V1 plans not enforcing partner requirements',
        'SPM: Allow plans without budget items to create Fiscal Reports',
        'SPM: Plan/Details tab validates description text area correctly',
        'SPM: Refresh routes when switching programs/funds and require login before navigating to routes when not logged in',
        'SPM: Update objective type selection list to use latest set',
        'SPM: Regional Priorty Sectors are now optional',
        'SPM: Success Metrics optional when no Metrics tab present',
        'SPM: Fund preview tab updated to include all sections',
        'SPM: Fixed a bug with duplicate approver cards on submitted plans',
        'SPM: Allow special characters in custom steps without affecting route names'
      ]
    },
    {
      date: 'September 15, 2021',
      version: '4.38.3',
      features: [],
      bugs: [
        'K12-SWP: Updates to Round 4 application to better match RFA',
        'K12-SWP: Fixed a display issue with Historical Performance narrative fields',
        'Perkins: Fixed an issue where Amendment Status checkbox could not be unchecked', 
        'SPM: Fixed a duplicate workflow tab display error with custom steps'
      ]
    },
    {
      date: 'September 10, 2021',
      version: '4.38.2',
      features: [],
      bugs: [
        'K12-SWP: Updated round 4 application to support multiple CalPass designees with required MOU upload',
        'Fiscal Reports: Fixed a bug where duplicate entries were possible in extremely rare circumstances'
      ]
    },
    {
      date: 'September 8, 2021',
      version: '4.38.1',
      features: [],
      bugs: [
        'K12-SWP: Fixed a checkbox display issue for Positive Considerations',
        'RCM: Fixed a display issue on application preview where target values were not shown properly',
        'SPM: Fixed a template error on Plan Preview'
      ]
    },
    {
      date: 'September 6, 2021',
      version: '4.38',
      features: [
        'SPM: Small Programs updated with additional customizations for apportionment including custom workflow steps'
      ],
      bugs: [
        'CAEP: Threshold and trigger values for reporting and budgeting now included in program settings'
      ]
    },
    {
      date: 'August 25, 2021',
      version: '4.37.1',
      features: [],
      bugs: [
        'K12-SWP: Ingested additional school types for Participating Schools and updated selection criteria on Application'
      ]
    },
    {
      date: 'August 20, 2021',
      version: '4.37',
      features: [
        'EWD: Final Expenditure Report and Survey now available',
        'K12-SWP: Final Expenditure Report and Survey now available',
        'Perkins Title 1B - Final Expenditure Report and Survey now available'
      ],
      bugs: [
        'EWD: Fixed an issue preventing allocations to new grants',
        'K12-SWP: Added Cal-Pass Designee questions for ROP type leads',
        'K12-SWP: Fixed a system error when navigating to some Fiscal Reports',
        'K12-SWP: Expanded filtering by region on Fiscal Reports dashboard to include lead and partner institution matches',
        'K12-SWP: Updated supporting document types for Round 4',
        'RCM: Metrics fetched by application region of lead institution'
      ]
    },
    {
      date: 'August 17, 2021',
      version: '4.36.1',
      features: [],
      bugs: [
        'K12-SWP: Revised Work-Based Learning questions for Round 4 applications'
      ]
    },
    {
      date: 'August 6, 2021',
      version: '4.36',
      features: [
        'K12-SWP: Round 4 now available',
        'Perkins Title 1C: Final Report added in fiscal reporting',
        'System: Revised role permission display and role assignment notifications'
      ],
      bugs: [
        'SWP: Fixed display of incorrect report status in Institution Fiscal Reporting'
      ]
    },
    {
      date: 'July 27, 2021',
      version: '4.35.8',
      features: [
      ],
      bugs: [
        `CAI: Fixed a bug preventing the Application Budget tab from loading`
      ]
    },
    {
      date: 'July 23, 2021',
      version: '4.35.7',
      features: [
      ],
      bugs: [
        `SWP 2.0: Round 2 plans now include Work-Based Learning question and narratives`,
        `SWP: More updates around closed projects in fiscal reporting`,
        `System: Program Auditor role added to provide full read-only access to a program`
      ]
    },
    {
      date: 'July 19, 2021',
      version: '4.35.6',
      features: [
      ],
      bugs: [
        `CAEP: Budget forecasting for 2021-22 and beyond is now applicable to the entire budget`,
        `CAEP: Fixed a display bug on the allocations and amendments page. Consortia order is now correct as is total for amendments`,
        `RCM: Contributors can now select up to 4 years for spending duration`,
        `SWP: Fiscal reporting cards now show Project Closed for certification status of closed projects`,
        `System: Document upload now supports multi-select of files unless prohibited`,
        `System: Program Admin role added to provide access to create new programs`
      ]
    },
    {
      date: 'July 12, 2021',
      version: '4.35.5',
      features: [
      ],
      bugs: [
        `CAI: Removed the WIP flag from Fiscal Reporting navigation item`,
        `SPM: Added regions to the selections for participating institutions`,
        `SWP: Fixed a bug causing a system error on Institution Reporting dashboard`
      ]
    },
    {
      date: 'July 6, 2021',
      version: '4.35.3',
      features: [
      ],
      bugs: [
        `SPM: Implemented sign-up link for new funds`,
        `SPM: Updated allocation amendment to allow initial allocation to unfunded institutions`
      ]
    },
    {
      date: 'July 2, 2021',
      version: '4.35.2',
      features: [
      ],
      bugs: [
        `K12-SWP: Fixed a bug preventing funding deferrals`
      ]
    },
    {
      date: 'July 1, 2021',
      version: '4.35',
      features: [
        `Small Programs now available in NOVA`
      ],
      bugs: [
      ]
    },
    {
      date: 'June 28, 2021',
      version: '4.34.3',
      features: [],
      bugs: [
        `RCM: Application updated to require a minimum of 3, maximum of 5 Regional Priority Sector items`,
        `RCM: Application budget tab match and edit function updated`
      ]
    },
    {
      date: 'June 24, 2021',
      version: '4.34.2',
      features: [],
      bugs: [
        `CAEP: Monitor Report updated`
      ]
    },
    {
      date: 'June 23, 2021',
      version: '4.34.1',
      features: [],
      bugs: [
        `SWP 2.0: Round 2 updated to always require supporting documentation for LMI, limit sector selection to 5 max, display plan id on Allied Health tab`,
        `Fiscal Reporting: Updated service model`
      ]
    },
    {
      date: 'June 22, 2021',
      version: '4.34',
      features: [
        `RCM: Regional Consortia Management program now available in NOVA`
      ],
      bugs: [
        `Fiscal Reporting: For fund level approvers, Pending My Approval filter now available`
      ]
    },
    {
      date: 'June 16, 2021',
      version: '4.33.2',
      features: [],
      bugs: [
        `SWP: Updated program settings to support guidance for specific cycles and rounds`
      ]
    },
    {
      date: 'June 15, 2021',
      version: '4.33.1',
      features: [],
      bugs: [
        `CAI V2: Removed minimum project duration and reduced required objectives to 1`
      ]
    },
    {
      date: 'June 14, 2021',
      version: '4.33',
      features: [
        `SWP 2.0: Updates for Round 2 of Cycle 2 SWP workflows: `,
        `a) Details: Plan Rationale questions added`,
        `b) Contacts: Fiscal reporting contacts only required for budgeted institutions (Applies to SWP 1.0 also)`,
        `c) Allied Health: HWI Reporting replaces previous questions`,
        `d) Workplan Activities: Across All Sectors option removed and replaced with muliple sector selection and weighting`,
        `e) Approvals: When enough funds are not available to approve a plan, approver will see an informational dialog to prevent over committing funds`,
        `SWP (All): FIFO chart replaced with "Allocations vs Budgets" Looker report`
      ],
      bugs: [
        `System: Improved messaging to prevent duplicates`
      ]
    },
    {
      date: 'June 9, 2021',
      version: '4.32',
      features: [
        `CAI: Fiscal Reporting and custom surveys now available for Apprenticeship`
      ],
      bugs: [
        `CAI: Set scoring points per request`,
        `K12 & SWP: Fixed an issue where plan action buttons were mistakenly visible on Fiscal Reporting pages`, 
        `Perkins: Fixed a bug on Forms preview where CNLA fields should not be displayed for even duration years`
      ]
    },
    {
      date: 'May 19, 2021',
      version: '4.31.2',
      features: [],
      bugs: [
        `K12-SWP: Added display of Indirect Budgeted (%) for Round 2 and later budget summaries on plan preview`, 
        `Perkins: Fixed a routing issue on the Program Settings Grants submenu`
      ]
    },
    {
      date: 'May 13, 2021',
      version: '4.31.1',
      features: [],
      bugs: [
        `CAEP: Fixed a bug where the approver information would disappear on fiscal report certify card until refreshed`,
        `K12-SWP: Restored display of Indirect Budgeted (%) for Round 2 and later budget summaries`, 
        `Perkins Title 1C: Updated workflow to hide Local Needs Assessment tab for even numbered duration IDs`,
        `System: Fixed a couple routing bugs, including fiscal report to plan and back in some programs`
      ]
    },
    {
      date: 'May 7, 2021',
      version: '4.31',
      features: [
        `CAI: Application workflow updated for next grant cycle`
      ],
      bugs: [
        `CAEP: Fiscal report adjustments to past quarters in the current year no longer require manual uncertify and 
        unsubmit of all future quarters`,
        `SWP-R: Updated Program Monitor report`
      ]
    },
    {
      date: 'May 5, 2021',
      version: '4.30.9',
      features: [],
      bugs: [
        `SWP-R: Updated Monitor Report`
      ]
    },
    {
      date: 'April 28, 2021',
      version: '4.30.8',
      features: [],
      bugs: [
        `SWP: Added Guidance card to Fiscal Reporting dashboards`
      ]
    },
    {
      date: 'April 27, 2021',
      version: '4.30.7',
      features: [],
      bugs: [
        `Perkins Title 1C: Fixed a permissions issue with newly added contacts `
      ]
    },
    {
      date: 'April 23, 2021',
      version: '4.30.6',
      features: [],
      bugs: [
        `Perkins Grant Allocations: Fixed a routing issue for Title 1C`,
        `System: Fixed a styling issue on RFA funding dashboard`
      ]
    },
    {
      date: 'April 21, 2021',
      version: '4.30.5',
      features: [],
      bugs: [
        `Fiscal Reporting: Fixed an issue where indirect costs budget amounts were not being included on reporting cards`,
        `K12-SWP: Fiscal reporting approvals now required for all quarters`,
        `SWP: Updated fiscal reporting cadence to bi-annual with required annual approvals`,
        `SWP: Create 1.0 Plan functionality updated so Regional Monitors and above can now create historical plans`,
        `SWP: Continue Plan guidance updated to include specifics for historical plans`,
        `System: Fixed a bug preventing .xls and .xlsx files from being selected for upload in the file uploader`
      ]
    },
    {
      date: 'April 16, 2021',
      version: '4.30.4',
      features: [],
      bugs: [
        `Fiscal Reporting: Fixed a bug preventing the certifying authority from being displayed in approver cards`
      ]
    },
    {
      date: 'April 15, 2021',
      version: '4.30.3',
      features: [],
      bugs: [
        `EWD - Fixed a bug with Dates & Messaging in Program Settings`,
        `Custom Surveys: Fixed display order of follow-up questions`,
        `SWP Fiscal Reporting: Institution reporting enhanced to show 1.0 and 2.0 plans on same interface`,
        `System: Improved error handling on workflows system wide`,
        `System: System Administrators now see warning in Production environments`,
        `System: Storybook replaces Patternlab for style management`
      ]
    },
    {
      date: 'April 7, 2021',
      version: '4.30.2',
      features: [],
      bugs: [
        `CAEP - Fiscal Reporting: Fixed a bug where the submit button was not available when corrective action is not required`,
        `Document Uploads: Added .xls, .xlsx, .doc, docx file formats to permit Excel and Word files to be uploaded`,
        `RCM: Tweaks to the funding offer acceptance workflows and communications`,
        `SWP 1.0: Fixed a bug with the Outcomes tab validator showing incomplete when it is complete`
      ]
    },
    {
      date: 'April 1, 2021',
      version: '4.30.1',
      features: [],
      bugs: [
        `Funding Dashboards: Fixed a bug where the lead institution was not being displayed`,
        `Review & Funding: Fixed a bug with Perkins 1B routing and K12 year selection`
      ]
    },
    {
      date: 'March 31, 2021',
      version: '4.30',
      features: [
//          `RCM: Application Review and Funding now available in NOVA`
      ],
      bugs: [
        `CAEP: Fiscal Reporting shows alert when Indirect Costs exceed 5% of total budget amount`,
        `CAEP: Program Area Reporting now requires entry in all fields before submit is allowed`,
        `Institutions: Added Region and District to the details page`,
        `SWP 2.0: Lead institution changes now permitted on Regional plans`
      ]
    },
    {
      date: 'March 25, 2021',
      version: '4.29.10',
      features: [],
      bugs: [
        `SWP: Analytics tab now only available to Regional Monitors and above`,
        `SWP 2.0: Budget tables now sorted by Expenditure Type, Year, and Related Activity for easier reading`,
        `System: Updated e-mail address validator to be more generic`
      ]
    },
    {
      date: 'March 19, 2021',
      version: '4.29.9',
      features: [],
      bugs: [
        `Perkins Title 1C: Added hyperlinks to MIS Report 1 and Report 2`
      ]
    },
    {
      date: 'March 18, 2021',
      version: '4.29.8',
      features: [],
      bugs: [
        `Perkins Title 1C: Headcount certification now available for 2021-22`
      ]
    },
    {
      date: 'March 17, 2021',
      version: '4.29.7',
      features: [],
      bugs: [
        `Institutions: Added a "Type" column to the details page project list and enabled sorting`
      ]
    },
    {
      date: 'March 12, 2021',
      version: '4.29.6',
      features: [],
      bugs: [
        `Bookmarks: Fixed a routing issue with some RSI bookmarks`,
        `CAI RSI Reporting: Fixed a bug where clicking on the institution name in the summary card did not scroll down to the institution's reporting card`,
        `Fiscal Reporting: Removed regions from the institution selection list for Institution Reporting`,
        `K12-SWP: Fixed an issue causing a system error when removing a partner agency from a plan`
      ]
    },
    {
      date: 'March 10, 2021',
      version: '4.29.5',
      features: [],
      bugs: [
        `CAI RSI Reporting: Fixed a bug preventing NOVA Administrators from adding contacts to CCDs and also resolved an issue where only one CCCCO Project Manager was displayed in the approval card when there were multiples in that role`,
        `Fiscal Reporting: Added Regions to the Institutions Filter selection list for most programs`,
        `K12-SWP & SWP-R: When a new institution is added to a plan and budgeted to spend money, fiscal reports for all certified years are automatically submitted for the new institution`,
        `SWP: Plans dashboard - Added sorting of Funding Year column`
      ]
    },
    {
      date: 'March 8, 2021',
      version: '4.29.4',
      features: [],
      bugs: [
        `SWP: Updated the analytics reports for both Local and Regional programs`,
        `SWP-R 1.0: Fixed a bug preventing plan submission when the industry sector strategy is changed`
      ]
    },
    {
      date: 'March 5, 2021',
      version: '4.29.3',
      features: [],
      bugs: [
        `Perkins RSI: Fixed a bug preventing a new user invite for the Approver/CBO role`
      ]
    },
    {
      date: 'March 4, 2021',
      version: '4.29.2',
      features: [],
      bugs: [
        `SWP 1.0: Added a checkbox to Create Plan/Edit Plan for users to indicate the plan is using expired funds`,
        `Perkins RSI: Fixed a validation issue that prevented opening up of a new year for reporting`
      ]
    },
    {
      date: 'March 3, 2021',
      version: '4.29.1',
      features: [],
      bugs: [
        `CAEP: CFAD member allocations updated to set default to $0 instead of copying from prior year`,
        `Perkins RSI: Fixed an allocation calculation issue that prevented opening up of a new year for reporting`
      ]
    },
    {
      date: 'March 2, 2021',
      version: '4.29',
      features: [
        `EWD, K12 SWP, Perkins, SWPR: Allocations updated to provide for contributing funds to Regional Consortia Management (RCM) program`,
//          `RCM: Competitive grant application now available in NOVA`,
        `RFA/Grant Settings: Configurable options for Review and Funding now available`
      ],
      bugs: [
        `SWP: Plans dashboard Lead Institution filter replaced with Institution filter to allow broader matches. Display of institutions on multi-institution Plans now alpha sorted with (Lead) indicator added`,
        `SWP: Continue Plan guidance updated to include more details around the process`
      ]
    },
    {
      date: 'February 26, 2021',
      version: '4.28.13',
      features: [],
      bugs: [
        `Help: Updated help page and widget to point to CCCCO's ServiceNow portal`,
        `SWP: Added logic to not display link to continued plan when that plan has been deleted`,
        `System: Fixed a bug where certified plan PDF/HTML files were not displayed on Plan Timeline view`
      ]
    },
    {
      date: 'February 19, 2021',
      version: '4.28.12',
      features: [],
      bugs: [
        `SWP: Added lead institution name to e-mail notification subject for plan approval requests`,
        `SWP: Fixed a bug that hid the error stepper on Budget tab under certain circumstances`
      ]
    },
    {
      date: 'February 12, 2021',
      version: '4.28.11',
      features: [],
      bugs: [
        `Fiscal Reporting: Fixed a bug with availability of the Actions column on Custom Survey Document Upload question types and populated the "Uploaded By" user name column`,
        `SWP & K12: Updated "Lead Institution" filter to pull from institutions list for more accurate matching`,
        `SWP: Reopen Plan button now available to Regional Monitors and NOVA System Admins for closed Local and Regional plans`,
        `SWP: Regional plans updated to permit a participating institution to be set as the lead institution`
      ]
    },
    {
      date: 'February 5, 2021',
      version: '4.28.10',
      features: [],
      bugs: [
        `K12-SWP: Added Plan ID filter and improved filter performance`,
        `SWP: Updated filter operation for faster initial page loads and improved filter performance`,
        `SWP 2.0: Fixed a bug preventing institution removal from a continued plan`
      ]
    },
    {
      date: 'January 30, 2021',
      version: '4.28.9',
      features: [],
      bugs: [
        `CAI: Added error stepper to budget summary`,
        `SWP 2.0: Adjusted notifications for Fiscal Reporters to limit to project scope`,
        `SWP 2.0: Fixed a bug with budget forecasting not saving under certain situations`
      ]
    },
    {
      date: 'January 28, 2021',
      version: '4.28.8',
      features: [],
      bugs: [
        `SWP: Closure requests can now be rejected at any time by identified approvers`,
        `SWP 2.0: Fixed a bug where metrics data was getting cleared from display on the Outcomes tab`
      ]
    },
    {
      date: 'January 27, 2021',
      version: '4.28.7',
      features: [],
      bugs: [
        `Fiscal Reporting: Fixed a bug that obscured files uploaded during project reporting`,
        `SWP 2.0: Updated LMI tab "Demand" helper text to remove mention of Doing What Matters website and fixed broken hyperlink to LMI tab`
      ]
    },
    {
      date: 'January 26, 2021',
      version: '4.28.6',
      features: [],
      bugs: [
        `SEA Program: Fixed an issue with report sort order within cards`,
        `SWP: Added FAQ button to dashboard and 2.0 Plan headers`,
        `SWP 2.0: Updated LMI tab to not require Allied Health for Top Code: 123010 Registered Nursing`,
        `SWP 2.0: Corrected text for "No" option on Degree or Certificate question for Add Training Site modal`
      ]
    },
    {
      date: 'January 19, 2021',
      version: '4.28.4',
      features: [],
      bugs: [
        `Perkins Title 1C: Updated budget change helper text to be more clear`,
        `SWP: Ran migration to ingest CIP/SOC/TOP crosswalk data`,
        `System: Fixed an issue preventing file uploads for all caps filenames`
      ]
    },
    {
      date: 'January 15, 2021',
      version: '4.28.3',
      features: [],
      bugs: [
        `SWP 2.0: Fixed an invalid permissions check for Objectives & Activities tabs`
      ]
    },
    {
      date: 'January 13, 2021',
      version: '4.28.2',
      features: [],
      bugs: [
        `System: Minor styling updates to workflows`
      ]
    },
    {
      date: 'January 11, 2021',
      version: '4.28.1',
      features: [
        `Custom Survey: New question type for "document upload" added`
      ],
      bugs: [
        `SWP: Updated metrics tab validation to allow 0 entry for baseline to enable submission of historical projects where no value was previously provided`
      ]
    },
    {
      date: 'January 8, 2021',
      version: '4.28.0',
      features: [
        `SWP 2.0: Added Compare Changes feature to Application History to permit comparisons between versions of certified plans. Also excluded some system events from timeline to reduce noise`
      ],
      bugs: [
        `K12-SWP: Fixed a random crash of plan preview`,
        `Perkins Title 1C: Updated workflow navbar to show sub-application status as Unsubmitted or Submitted if sub-application's workflow status is valid`,
        `SWP: Updated preview to not show fiscal reporting status for previously closed plans`
      ]
    },
    {
      date: 'January 5, 2021',
      version: '4.27.10',
      features: [
        `SWP: Updated Outcomes tab to allow zero entry for Baseline values`
      ],
      bugs: [
      ]
    },
    {
      date: 'January 5, 2021',
      version: '4.27.9',
      features: [
        `SWP: Added "Create 1.0 Plan" button to allow users to create their own Round 4 plans`,
        `SWP: Minor changes to Details tab for Local and Regional plans`,
        `SWP: Updated name filter to return matches when user enters plan ID`
      ],
      bugs: [
      ]
    },
    {
      date: 'December 22, 2020',
      version: '4.27.7',
      features: [
      ],
      bugs: [
        `Fixed a routing issue with some programs reporting plans as not found`
      ]
    },
    {
      date: 'December 22, 2020',
      version: '4.27.6',
      features: [
      ],
      bugs: [
        `SWP 1.0: Fixed a bug with Outcomes and Workplan tabs preventing validation from passing`,
        `Help: Reinstated original Helpdesk widget and help page`
      ]
    },
    {
      date: 'December 18, 2020',
      version: '4.27.5',
      features: [
        `SWP: Enhanced close project feature now available for 1.0 and 2.0 plans`,
        `SWP: Fiscal and outcomes reporting now available for 2.0 plans`
      ],
      bugs: [
        `K12-SWP: Disabled validation for removed agencies`,
        `Login: Updated login screen`,
        `System: Miscellaneous fixes and improvements`
      ]
    },
    {
      date: 'December 17, 2020',
      version: '4.27.4',
      features: [
      ],
      bugs: [
        `SWP: Fixed issue with metrics ingest data for percentage values and updated UI to display properly`,
        `Help: Updated help page and widget to point to CCCCO's ServiceNow portal`
      ]
    },
    {
      date: 'December 16, 2020',
      version: '4.27.3',
      features: [
        `K12-SWP: Plan contacts can now be edited without recertification`
      ],
      bugs: [
        `SEA Program: Fixed an issue with reporting approval`,
        `System: Performance improvements`
      ]
    },
    {
      date: 'December 15, 2020',
      version: '4.27.2',
      features: [
        `K12-SWP: Plan contacts can now be edited without recertification`
      ],
      bugs: [
        `SWP 1.0: Plan duration validation updated to permit spending into fiscal year 2021-22`,
        `System: Performance improvements`
      ]
    },
    {
      date: 'December 14, 2020',
      version: '4.27.1',
      features: [
        `SWP 1.0: Plan contacts can now be edited without recertification`
      ],
      bugs: [
        `Bookmarks: Fixed an issue throwing a system error while parsing bookmarks`,
        `System: minor performance improvements`
      ]
    },
    {
      date: 'December 12, 2020',
      version: '4.27.0',
      features: [
        `SWP: Version 2.0 of SWP for Local and Regional Share projects now available in NOVA`,
        `SWP: Version 1.0 of SWP has been performance improved to match the newer Version 2.0 implementation`,
        `Navigation: NOVA's left navigation has been revamped for quicker access and better organization of programs`
      ],
      bugs: []
    },
    {
      date: 'November 23, 2020',
      version: '4.26.7',
      features: [
        `System: Implemented notification feature to display system message on Login and application pages when needed`
      ],
      bugs: [
        `K12-SWP: Ran migration to automatically submit 2018-19 fiscal reports for Q1-Q3 per CCCCO's request`,
        `SWP: Ran migration to update incentive allocations for 2017-18 and 2018-19 per CCCCO's request`
      ]
    },
    {
      date: 'November 16, 2020',
      version: '4.26.6',
      features: [
      ],
      bugs: [
        `K12-SWP: Disable tie-breaker scoring feature for application reviews`,
        `System: Updated permissions check algorithms`
      ]
    },
    {
      date: 'November 10, 2020',
      version: '4.26.4',
      features: [
      ],
      bugs: [
        `CAI-RSI: Minor updates to reporting UI`,
        `Guided Pathways: Closed all 2018-19 plans and opend 2020-21 plans; Added button to permit opening of 2021-22 and 2022-23 plans on Allocations tab`,
        `Institutions: Added Institution Manager role for District and College`
      ]
    },
    {
      date: 'November 6, 2020',
      version: '4.26.3',
      features: [
      ],
      bugs: [
        `EWD: Sector Monitors now approve Fiscal Reporting for Statewide and Regional Director grants. Fund Monitors approve for TAP and COE grants`,
        `System: Improved performance of permission checks and added role granularity to some programs`
      ]
    },
    {
      date: 'November 4, 2020',
      version: '4.26.2',
      features: [
      ],
      bugs: [
        `SWP L/R: Fixed an issue preventing project closures`,
        `SWP L/R: Fixed an issue showing a system error instead of a project alert when removing a participating institution from a plan`,
        `Perkins V - Title 1B -  Updated validator for Description tab`
      ]
    },
    {
      date: 'November 2, 2020',
      version: '4.26.1',
      features: [
      ],
      bugs: [
        `K12-SWP: Fixed an issue preventing Year 1 and 2 plans from successful request for changes`,
        `Fiscal Reporting: Fixed issues with printing and sharing of documents and added display date/time and user name submitting the report `
      ]
    },
    {
      date: 'October 30, 2020',
      version: '4.26.0',
      features: [
        `K12-SWP: Close Plan now available in NOVA`,
        `K12-SWP: Enhanced Remove Partner Agency from Plan now available in NOVA`,
        `K12-SWP: Funding Deferral now available in NOVA`,
        `K12-SWP: Updated Plan Contacts component to permit adding of one or more Fiscal Reporter contacts`
      ],
      bugs: [
        `EWD: Added Grant ID to grant titles displayed in NOVA and sent with notifications`,
        `EWD & Perkins V: Added "Fiscal Reporting" button to Preview Page of certified plans for direct access to associated reports`,
        `Fiscal Agent Hub: Refined page load when manually refreshing Fiscal Reporting`,
        `K12-SWP: Updated Scoring Rubric hyperlink for Application reviewers`,
        `SEA Program: Refined handling of required fields for Mid Allocation reports`,
        `System: Improved system performance with several query refinements`
      ]
    },
    {
      date: 'October 26, 2020',
      version: '4.25.1',
      features: [
      ],
      bugs: [
        `Perkins V: Fixed an issue with Title 1C Fiscal Reporting not saving survey responses`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'October 23, 2020',
      version: '4.25.0',
      features: [
        `K12-SWP: Fiscal and Outcomes reporting including Custom Surveys now available in NOVA`,
        `K12-SWP: K12 Pathway Coordinators & K14 TAPs Fiscal and Outcomes reporting now available in NOVA`
      ],
      bugs: [
        `Perkins V: Fixed an issue preventing Headcount dashboard from loading properly`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'October 20, 2020',
      version: '4.24.4',
      features: [
      ],
      bugs: [
        `K12-SWP: Process opening of reviews asynchronously to improve performance`,
        `SWP: Fixed an issue with fiscal reporting notification e-mail "Click here" link truncating query parameters`
      ]
    },
    {
      date: 'October 16, 2020',
      version: '4.24.2',
      features: [
      ],
      bugs: [
        `K12-SWP: Corrected an issue with lead agency district and region showing "N/A" in Preview`
      ]
    },
    {
      date: 'October 15, 2020',
      version: '4.24.1',
      features: [
      ],
      bugs: [
        `System: Increased character count for helper text in Custom Surveys`
      ]
    },
    {
      date: 'October 14, 2020',
      version: '4.24.0',
      features: [
      ],
      bugs: [
        `Perkins V: Custom surveys now available for Fiscal and Outcomes reporting for all grant types`
      ]
    },
    {
      date: 'October 13, 2020',
      version: '4.23.12',
      features: [
      ],
      bugs: [
        `K12-SWP: Fixed a permissions issue where view-only contacts could not view application previews`
      ]
    },
    {
      date: 'October 13, 2020',
      version: '4.23.10',
      features: [
      ],
      bugs: [
        `K12-SWP: Fixed an issue with the budget narrative validator failing when content is pasted from other editors like Word and Google Docs`
      ]
    },
    {
      date: 'October 9, 2020',
      version: '4.23.9',
      features: [
      ],
      bugs: [
        `CAEP: Updated available funds check for showing Budget & Workplans for members without funds to budget`,
        `System: Improved performance of elasticsearch for user and institution searches`
      ]
    },
    {
      date: 'October 6, 2020',
      version: '4.23.8',
      features: [
      ],
      bugs: [
        `Help: Updated help links to NOVA YouTube Channel and to include all program channels`,
        `Helpdesk: Fixed an issue with Chrome not referring the URL of the page where tickets originate`,
        `System: Improved error message when trying to add a new user to NOVA that already exists`
      ]
    },
    {
      date: 'October 1, 2020',
      version: '4.23.7',
      features: [
      ],
      bugs: [
        `CAEP: Budget & Workplans not required for members with $0 available funds`,
        `CAEP: Budget will accept $0 value when allocation amendment removes all funds`
      ]
    },
    {
      date: 'September 28, 2020',
      version: '4.23.5',
      features: [
      ],
      bugs: [
        `Perkins V 1B: Adjusted timeline selection list for Workplan - Activities/Outcomes to include funding year`
      ]
    },
    {
      date: 'September 22, 2020',
      version: '4.23.4',
      features: [
      ],
      bugs: [
        `EWD: Plan/Application Budget now includes a conditional upload card for Industry Match Letter of Proof`,
        `Fiscal Reporting: Resolved issue with budget-info columns not showing and extra filters present in Print View`,
        `K12: Resolved a grant configuration issue preventing budget validation passing when a COE is the lead agency`
      ]
    },
    {
      date: 'September 21, 2020',
      version: '4.23.3',
      features: [
      ],
      bugs: [
        `CAEP: Fixed an issue saving quarterly forecasts`,
        `SEA Program: Fixed an issue saving new activities within plans`,
        `SWP: Fixed an issue searching for fiscal reports using a specific combination of filter settings`
      ]
    },
    {
      date: 'September 17, 2020',
      version: '4.23.0',
      features: [
        `EWD: Fiscal and Outcomes Reporting with Custom Survey now available in NOVA`
      ],
      bugs: [
        `EWD: New Performance Outcomes selection lists added for each specific grant type`
      ]
    },
    {
      date: 'September 15, 2020',
      version: '4.22.4',
      features: [
      ],
      bugs: [
        `EWD: Updated messaging on the Applications page to direct users to Plans when no grants are open for applications`,
        `SEA Program: Term End Reporting allocation calculation updated to exclude request/revert fund amounts`
      ]
    },
    {
      date: 'September 9, 2020',
      version: '4.22.3',
      features: [
      ],
      bugs: [
        `K12-SWP: Removed funding year from display of High-Quality CTE Assessment file upload`,
        `K12-SWP: Restore ability to remove uploaded documents`,
        `System: Bump NodeJS runtime in buildspec`
      ]
    },
    {
      date: 'September 4, 2020',
      version: '4.22.2',
      features: [
      ],
      bugs: [
        `K12-SWP: Grant Guidance settings updated for Supporting Documents`,
        `K12-SWP: Visiblility of plans now matches other programs`,
        `SWP: Updated alerts for Close Project to include requirement to update budgets and fiscal reports before closing`,
        `SWP: Updated broken links referring to deleted Doing What Matters pages`,
        `System: Updated date-picker component to work with error stepper`
      ]
    },
    {
      date: 'August 28, 2020',
      version: '4.22.0',
      features: [
        `K12-SWP: Year 3 Application/Review/Funding, Grants, and Program Settings now available in NOVA`
      ],
      bugs: [
        `SEA Program: Contacts editable without certification on Plans, College Annual Reports, and District Term-End Reports `,
        `K12-SWP: ADA and Unemployment Data Updated`
      ]
    },
    {
      date: 'August 17, 2020',
      version: '4.21.0',
      features: [
        `SEA Program: District Term-End Expenditure Report available`,
        `SEA Program: Custom surveys for reporting available`
      ],
      bugs: [
        `SEA Program: Updates to College Annual Report`,
        `SWP-L: Fiscal reporting dashboard approval status shows "N/A" for quarters not requiring approval`
      ]
    },
    {
      date: 'August 10, 2020',
      version: '4.20.9',
      features: [
      ],
      bugs: [
        `System: Improved function of e-mailed approval links with increased security`,
        `System: Reduced load times of program dashboards`
      ]
    },
    {
      date: 'August 3, 2020',
      version: '4.20.8',
      features: [
      ],
      bugs: [
        `EWD: Resolved issue with start date validation year not permitting 2020 selection`,
        `EWD: Corrected a spelling error on the Goals & Metrics tab`
      ]
    },
    {
      date: 'July 22, 2020',
      version: '4.20.6',
      features: [
        `Perkins V: Fiscal reporting for 1C and 1B now available in NOVA`
      ],
      bugs: [
        'Perkins V 1C: Removed "District Superintendent/President" from application approvers list',
        `Perkins V 1C: Resolved an issue with back button losing the route back to a plan`,
        `K12-SWP: Updated preview to show match cards`,
        `K12-SWP: Resolved an issue with budget duration on plans`,
        `K12-SWP: Resolved an issue viewing year 1 applications`
      ]
    },
    {
      date: 'July 14, 2020',
      version: '4.20.3',
      features: [],
      bugs: [
        'Removed "Fund Manager" from Perkins V-Title 1C application approvers list',
        'Resolved an issue with entering match funds for SWPK12 plans',
        'Resolved an issue with untitled workplan item selections in EWD Plans'
      ]
    },
    {
      date: 'July 9, 2020',
      version: '4.20.2',
      features: [],
      bugs: [
        'Fixed validation errors in EWD plan workflows',
        'Assigned prior year contacts to new NEP plans',
        'Survey questions now accept "0" as a valid response',
        'Various service and performance improvements'
      ]
    },
    {
      date: 'July 1, 2020',
      version: '4.20.1',
      features: [],
      bugs: [
        'Corrected Grant Id values for EWD Plans',
        'EWD Renewal Dashboard accessibility improvements'
      ]
    },
    {
      date: 'June 30, 2020',
      version: '4.20.0',
      features: [
        `Economic and Workforce Development (EWD) Program now available in NOVA`
      ],
      bugs: []
    },
    {
      date: 'June 24, 2020',
      version: '4.19.4',
      features: [],
      bugs: [
        `Perkins V - Title 1C certification reminder restrictions have been removed`,
        `SWP-R/L Fiscal Reporting now displays "Closed" projects`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'June 6, 2020',
      version: '4.19.0',
      features: [
        `System: Update to Open API 3.0 system wide`
      ],
      bugs: [
        `Perkins V - Title 1C Local Needs Assessment allows additional documents to be uploaded`,
        `Perkins V - Title 1C Monitor approval of SPLT goals is now automatic when state goals are accepted`
      ]
    },
    {
      date: 'June 4, 2020',
      version: '4.18.7',
      features: [
      ],
      bugs: [
        `Perkins V - Replaced numeric spin control with entry field for Core Indicators`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'May 22, 2020',
      version: '4.18.6',
      features: [
      ],
      bugs: [
        `K12-SWP - Provided sorting capability to Lead LEA column in Plans list`,
        `SWP - Filtering on title now supports multiple search terms`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'May 20, 2020',
      version: '4.18.5',
      features: [
      ],
      bugs: [
        `LVG - Fixed an issue with metrics names and updated validation handling`,
        `SWP - Relaxed validation rules for Fiscal Reporting post upgrade`,
        `System - Fixed an issue with allocation amendment duration ID for non-Perkins funds`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'May 18, 2020',
      version: '4.18.4',
      features: [
      ],
      bugs: [
        `Perkins V - Fixed a lookup bug with Goals & Metrics`
      ]
    },
    {
      date: 'May 16, 2020',
      version: '4.18.3',
      features: [
      ],
      bugs: [
        `CAEP - Removed scrolling when quarter changed in fiscal reporting`,
        `Perkins V - Title 1C Local Needs Assessment now provides for certification form upload`,
        `SWP - Fiscal Reporting module upgraded to use latest methods`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'May 11, 2020',
      version: '4.18.2',
      features: [
      ],
      bugs: [
        `CAEP - Consortia listing page now displays last approved CFAD's funding channel`,
        `Perkins V - Title 1B Contacts can be edited without uncertification and recertification`,
        `System - Updated handling of [Next] in workflows using new contacts component`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'May 4, 2020',
      version: '4.18.0',
      features: [
        `Perkins V - Title 1B Plans, Program/Grant Settings, and Funding now available in NOVA`
      ],
      bugs: [
        `Perkins V - Reorganized menu to support additional grants`,
        `Perkins V - Revised budget cards for Title 1C where administrative expenditures are added`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'April 21, 2020',
      version: '4.17.0',
      features: [
        `Site wide update to Angular Version 9`
      ],
      bugs: [
        `Approvals: Notifications updated with warning against forwarding messages containing user-specific links`,
        `Budgets: Fixed a validation issue where forecasts were erroneously flagged as an error, but did not prevent submit`,
        `Perkins: Migration to adjust role scopes to accommodate 1B sub-fund implementation`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'April 16, 2020',
      version: '4.16.2',
      features: [],
      bugs: [
        `Approvals: Improved error handling to surface an alert when two users attempt to complete the same task simultaneously`,
        `CAI: Improved handling of start/end dates on plan details tab`,
        `Contact Search: Removed lexical sort from elasticsearch results to show closer matches at the top of results list`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'April 7, 2020',
      version: '4.16.1',
      features: [],
      bugs: [
        `User Profile: Fixed a bug with Perkins application routes`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'April 6, 2020',
      version: '4.16.0',
      features: [
        `Help Desk: New Help Desk widget provided on every screen in NOVA for submitting requests for help that can include screenshots`
      ],
      bugs: [
        `Add Contacts: Refined component retrofitted to many programs`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'April 3, 2020',
      version: '4.15.0',
      features: [
        `Request Access: New feature for users to request access to NOVA which collects useful information and provides for multiple requests in a single submission`
      ],
      bugs: [
        `Perkins: Updates to funding formula`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'March 27, 2020',
      version: '4.14.6',
      features: [],
      bugs: [
        `Perkins: Miscellaneous refinements`,
        `Nursing: New question added to final report, designees now show on Fiscal Report certification cards`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'March 25, 2020',
      version: '4.14.5',
      features: [],
      bugs: [
        `Perkins: Improvements to notifications, application history view added`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'March 20, 2020',
      version: '4.14.3',
      features: [],
      bugs: [
        `Perkins: Updated contacts tab to allow changes without recertification of approved applications`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'March 17, 2020',
      version: '4.14.1',
      features: [],
      bugs: [
        `Perkins: Updated messaging in Application listing page`,
        `CAEP: Added assertions to CFAD approval process and updated CFAD preview`,
        `K12-SWP: Added a required narrative to plan budgets and put all plans into Draft status`
      ]
    },
    {
      date: 'March 13, 2020',
      version: '4.14.0',
      features: [
        `Perkins V - Title 1C Plans, Program/Grant Settings, and Funding now available in NOVA`
      ],
      bugs: []
    },
    {
      date: 'March 11, 2020',
      version: '4.13.22',
      features: [],
      bugs: [
        `Various service and performance improvements`
      ]
    },
    {
      date: 'March 3, 2020',
      version: '4.13.18',
      features: [
        `Perkins: Updates to the Headcount Certification page to provide more guidance`
      ],
      bugs: [
        `Various service and performance improvements`
      ]
    },
    {
      date: 'March 2, 2020',
      version: '4.13.18',
      features: [
        `Perkins V: Updated permissions check for adding contacts`,
        `K12 SWP: Fixed filter performance on Plans listing page`
      ],
      bugs: [
        `Various service and performance improvements`
      ]
    },
    {
      date: 'February 25, 2020',
      version: '4.13.14',
      features: [
        `Perkins V: Headcount certification feature and supporting settings added to NOVA`
      ],
      bugs: [
        `Various service and performance improvements`
      ]
    },
    {
      date: 'January 20, 2020',
      version: '4.13.5',
      features: [],
      bugs: [
        `K12 SWP: Fixed an issue with application attachments that contained special characters in the filename`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'January 14, 2020',
      version: '4.13.4',
      features: [],
      bugs: [
        `K12 SWP: Disabled outlier and tie breaker functions`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'December 20, 2019',
      version: '4.13.3',
      features: [],
      bugs: [
        `Invite User: Refined validation of e-mail address to exclude non-alpha characters at end of entered text`,
        `K12 SWP: Added tie breaker function to Funding`,
        `Fiscal Agent Reporting: Added new required certifier role to contacts card and updated informational display for reporting extensions`,
        `Help: Added text to explain help request processing times`,
        `Nursing: Refined rules for display of approver cards when Fund Monitor rejects a plan or report`,
        `Routing: Fixed an issue where users would see a system error if they navigated to a page after their login token expired`,
        `System: Refined cell highlight behavior on integer transform fields`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'December 13, 2019',
      version: '4.13.2',
      features: [
        `System: A new "Error Stepper" feature has been added, which checks each workflow step for errors, displays a summary count and allows the user to step through and correct each error`
      ],
      bugs: [
        `CAEP: Improved performance of FIFO chart to include pending expenditures which corrects an issue with remaining funds calculations`,
        `GP: Corrected an issue with task updates for certain event types`,
        `K12 SWP: Improved performance of partner searches when adding collaborative partners`,
        `K12 SWP: Continued improvement of review and funding algorithms`,
        `K12 SWP: Applications dashboard now displays correct participating agencies individual ADA and total ADA values`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'December 10, 2019',
      version: '4.13.0',
      features: [
        `K12-SWP: Fiscal Agent Hub available to facilitate Fiscal Reporting for Fiscal Agents`
      ],
      bugs: [
        `SEA Program: Updated validation to allow reports with less than 5 available student groups`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'December 5, 2019',
      version: '4.12.2',
      features: [],
      bugs: [
        `CAEP: Added 'Save Filter' functionality to fiscal reporting dashboard`,
        `CAEP: Enhanced the 'Submittal Status' filter to only consider members required to submit for the selected parameters`,
        `CAEP: Added display of forecast percentage to fiscal reporting table`,
        `Nursing: Updated filter functions for approvers to see which plans and fiscal reports still need their approval`,
        `SEA Program: Enhanced expenditure reporting to allow a $0 next year forecast value`,
        `SEA Program: Increased the challenges description character limit to 5000 on the Annual Report`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'November 27, 2019',
      version: '4.12.1',
      features: [],
      bugs: [
        `CAEP: Updated allocation amendment process to allow moving money to non-allocated members post CFAD approval`,
        `CAEP: Fixed a bug that created duplicate program area hours records that affected user ability to save estimates`,
        `Forgot Password: Alert user when forgot password link has expired and redirect them to easily request a new one`,
        `K12 SWP: Improvements to review and funding for competitive grant process`,
        `Login: Users may not attempt login without both credentials entered`,
        `Nursing: Added approval filter options for approvers to see which plans and reports still need their approval`,
        `SWP: Fixed a display issue where institution ids were shown in certification cards instead of names`,
        `User Profile: Added two new professional categories: Adjunct Faculty and Faculty`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'November 18, 2019',
      version: '4.12.0',
      features: [
        `K12 SWP: Review and funding workflow updated for 2019-20 RFA usage`
      ],
      bugs: [
        `K12 SWP: Minor updates to 2019-20 application usability and validation`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'November 15, 2019',
      version: '4.11.5',
      features: [],
      bugs: [
        `SEA Program: Fixed a bug that truncated Success Story narrative in Allocation End reports`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'November 12, 2019',
      version: '4.11.4',
      features: [],
      bugs: [
        `CAEP - Fixed two issues with Fiscal Reporting listing page filter: 2019-20 now available in year filter and improved performance of Submittal Status filter.`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'November 7, 2019',
      version: '4.11.3',
      features: [
        `Nursing: Enhanced Print View now available`
      ],
      bugs: [
        `Various service and performance improvements`
      ]
    },
    {
      date: 'November 6, 2019',
      version: '4.11.2',
      features: [],
      bugs: [
        `Nursing: Added Fiscal Reporter contact to plans`,
        `SEA: Helper text updates in Annual Report workflow`,
        `SEA: North Orange Continuing Education plan added`,
        `Updated filter component labels to match table headings`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'October 31, 2019',
      version: '4.11.0',
      features: [
        `Guided Pathways 2019-20 plan workflow added to NOVA`
      ],
      bugs: [
        `Various service and performance improvements`
      ]
    },
    {
      date: 'October 30, 2019',
      version: '4.10.0',
      features: [
        `K12 Strong Workforce 2019-20 Grant Application added to NOVA`,
        `Added "Save Filter Settings" capabilities to all funds using the standard filter component`
      ],
      bugs: [
        `FIFO chart for fiscal reporting displays in a page instead of modal for better visibility`,
        `CAEP: Allocation amendment history now displayed in descending completed date order`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'October 15, 2019',
      version: '4.9.7',
      features: [],
      bugs: [
        `SEA Program: Updates to reporting workflow`,
        `LVG: Fixed a metrics data display issue`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'October 8, 2019',
      version: '4.9.6',
      features: [],
      bugs: [
        `CAI: Updated permissions for review and funding workflows`,
        `Addressed a queuing issue resulting in duplicate notifications`,
        `SEAP: Renamed menu item and removed reporting sub-menu`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'October 4, 2019',
      version: '4.9.4',
      features: [],
      bugs: [
        `NEP: E-mail template fixed to include link to plan for reminders`,
        `K12-SWP: Support ticket routing to proper e-mail address`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'October 2, 2019',
      version: '4.9.3',
      features: [
        `Added RSI settings workflow and RSI reporting pages`,
        `Added contact removal confirmation when removing self from proposals`
      ],
      bugs: [
      ]
    },
    {
      date: 'October 1, 2019',
      version: '4.9.2',
      features: [],
      bugs: [
        `Fixed an issue with fiscal report documents not displaying expenditures`,
        `Minor enhancements to fiscal reporting pages`,
        `Fixed an issue displaying the state of Integrated Planning bookmarks`
      ]
    },
    {
      date: 'September 30, 2019',
      version: '4.9.1',
      features: [],
      bugs: [
        `Fixed an issue with budget forecasts not saving as expected`,
        `Minor enhancements to SEAP plan and Reporting`,
        `Minor enhancements to Nursing Dashboard`,
        `Additional service and performance improvements`
      ]
    },
    {
      date: 'September 26, 2019',
      version: '4.9.0',
      features: [
        `Nursing fiscal reporting now available in NOVA`,
        `Student Equity and Achievement program reporting now available in NOVA`,
      ],
      bugs: [
        `Various service and performance improvements`
      ]
    },
    {
      date: 'September 25, 2019',
      version: '4.8.15',
      features: [],
      bugs: [
        `CAEP data corrections`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'September 18, 2019',
      version: '4.8.14',
      features: [],
      bugs: [
        `SWP: Fixed an issue with deletion of LMIs`,
        `Various service and performance improvements`
      ]
    },
    {
      date: 'September 11, 2019',
      version: '4.8.12',
      features: [],
      bugs: [
        `Various service and performance improvements`
      ]
    },
    {
      date: 'August 30, 2019',
      version: '4.8.11',
      features: [],
      bugs: [
        `Integrated Plan: Miscellaneous formatting and permissions improvements`,
        `CAEP: Fixed issue with FIFO chart member and region filter selection list`,
        `CAI Application: Miscellaneous improvements to workflow tabs`,
        `Increased idle timeout to 60 minutes`,
        `Various service improvements`
      ]
    },
    {
      date: 'August 27, 2019',
      version: '4.8.10',
      features: [],
      bugs: [
        `CAEP: Returned CAEP fund to default table for Leveraged Funds reporting`,
        `CAEP: Corrected logic for hiding the Fiscal Reporting Send Reminder button when reports are not ready to be approved`,
        `Integrated Plan: Corrected money display formatting in Fiscal Reporting`,
        `Fixed an issue with demand-load of institutions selection lists system-wide`,
        `Modified default year filtering for SWP-L and SWP-R project lists`,
        `Various infrastructure and service improvements including Angular 8 and Node 10`
      ]
    },
    {
      date: 'August 13, 2019',
      version: '4.8.8',
      features: [],
      bugs: [
        `Integrated Plan: Updates to fiscal reporting and budgeting`
      ]
    },
    {
      date: 'August 6, 2019',
      version: '4.8.7',
      features: [],
      bugs: [
        `Various system improvements related to loading proposal data`
      ]
    },
    {
      date: 'August 5, 2019',
      version: '4.8.6',
      features: [],
      bugs: [
        `LVG Plans now allow multiple "Other" type additional metrics`
      ]
    },
    {
      date: 'August 2, 2019',
      version: '4.8.5',
      features: [],
      bugs: [
        `Resolved an issue in multi-select form validation`
      ]
    },
    {
      date: 'August 1, 2019',
      version: '4.8.4',
      features: [],
      bugs: [
          `Miscellaneous service improvements`
      ]
    },
    {
      date: 'July 18, 2019',
      version: '4.8.1',
      features: [],
      bugs: [
        `K12 SWP: Fixed an issue where certified plans could not be uncertified`,
        `Fixed an issue where some workflow tabs were not showing completed status upon passing validation`,
        `Miscellaneous service improvements`
      ]
    },
    {
      date: 'July 16, 2019',
      version: '4.8.0',
      features: [
        `Nursing program now available in NOVA`,
      ],
      bugs: [
        `Fixed an issue where regions were not always available for institution filters`,
        `Fixed an issue where recipient name wasn't appearing in some notification emails`,
        `SCOE API updated to identify current year`
      ]
    },
    {
      date: 'July 10, 2019',
      version: '4.7.4',
      features: [],
      bugs: [
        `Minor updates to Apprenticeship Add RFA and Application`,
        `CAEP: Filtering on funding channel corrected to work for all channels`,
        `K12 SWP: Corrected an issue with plan rejection notification leaving out reviewer comments`
      ]
    },
    {
      date: 'July 8, 2019',
      version: '4.7.3',
      features: [],
      bugs: [
        `Minor updates to Apprenticeship Add RFA and Application`
      ]
    },
    {
      date: 'July 1, 2019',
      version: '4.7.1',
      features: [],
      bugs: [
        `Fixed an issue with K12 plans not showing the correct status`
      ]
    },
    {
      date: 'June 30, 2019',
      version: '4.7.0',
      features: [
        `New program California Apprenticeship Initiative now availabe in NOVA`
      ],
      bugs: [
        `Miscellaneous service updates and improvements`
      ]
    },
    {
      date: 'June 7, 2019',
      version: '4.6.8',
      features: [],
      bugs: [
        `CAEP: Reworked logic for re-opening Budgets & Workplans when an allocation amendment is approved to make sure the correct year is handled.`,
        `Miscellaneous service updates and improvements`
      ]
    },
    {
      date: 'May 31, 2019',
      version: '4.6.7',
      features: [],
      bugs: [
        `CAEP: Updated helper text for Regional Planning Overview narrative`,
        `Service updates and improvements`
      ]
    },
    {
      date: 'May 21, 2019',
      version: '4.6.5',
      features: [],
      bugs: [
        `K12 Strong Workforce Program: Removed requirement for budget forecasts in years without budget items; Monetary match requirements enforced for plan submission;
        Updated Budget Summary card information display; Certified plans now visible to all NOVA users; Adding partners re-enabled`,
        `Student Equity Program: Activities tab helper text updated`
      ]
    },
    {
      date: 'May 10, 2019',
      version: '4.6.4',
      features: [],
      bugs: [
        `Strong Workforce Program: Only certified projects can be submitted for closure`,
        `Strong Workforce Program: Modified logic that displays a submit warning during fiscal reporting`,
        `K12 Strong Workforce Program: Adjusted starting year to 2018-19 and extended budgeting through 2021-22`,
        `Local Vision Goals: Fixed display of baseline data when moving between previews for different institutions`
      ]
    },
    {
      date: 'May 3, 2019',
      version: '4.6.3',
      features: [],
      bugs: [
        `Student Equity Program: DI groups with denom under 10 will not be shown or required, but can be added manually if desired`,
        `Service side updates to improve performance of Looker reports for some programs`
      ]
    },
    {
      date: 'April 30, 2019',
      version: '4.6.2',
      features: [],
      bugs: [
        `Improved performance of institution type-ahead searches`,
        `Fixed an issue with bulk e-mail scheduler sending duplicate messages after an unexpected error condition`
      ]
    },
    {
      date: 'April 25, 2019',
      version: '4.6.1',
      features: [
        `Integrated Plan: All plans set to draft so they can be updated and certified and have a close-out report submitted`,
        `NOVA now detects outdated browsers and displays an alert that provides users with information and links to download recommended browsers`
      ],
      bugs: [
        `Various small bug fixes in SWP and CAEP`,
        `Miscellaneous service performance enhancements`
      ]
    },
    {
      date: 'April 19, 2019',
      version: '4.6.0',
      features: [
        `K12 SWP Plan & Expanded Budget workflow now available for funded applications`,
        `K12 SWP Program Settings now supports scheduling event notifications to be sent to specific roles`
      ],
      bugs: [
        `Student Equity Program plan metrics tab no longer requires disproportionately impacted (DI) groups where a baseline value was ‘Not Available’ and displays all of the required DI groups  at the top of the list`,
        `Metric name and text changes to Local Vision Goals`,
        `Users attempting to upload supporting documents in excess of 45MB will now see a warning message that the file exceeds the size limit`,
        `Various small bug fixes in SWP and CAEP`,
        `Miscellaneous service performance enhancements`
      ]
    },
    {
      date: 'April 11, 2019',
      version: '4.5.2',
      features: [],
      bugs: [
        `Student Equity Program: Updated helper text for DI groups, migration to update baseline metric data`,
        `Updates to the Help submission form and service support `,
        `Various fixes to SWP Fiscal Reporting permissions and guards`,
        `Miscellaneous SWP data migrations`
      ]
    },
    {
      date: 'April 4, 2019',
      version: '4.5.0',
      features: [
        `K12 SWP Funding workflow including batch notification of approved applications and  response processing`,
        `Student Equity Program (SEP) workflow now available in NOVA. Visit the Programs menu to navigate to Student Equity`
      ],
      bugs: [
        `System-wide update to rename AEBG/Adult Education Block Grant program to CAEP/California Adult Education Program`,
        `Miscellaneous improvements to Local Vision Goals`,
        `Institutions list and types pruned and updated to be more user friendly, improved performance of institution lookups system-wide`,
        `Miscellaneous service performance enhancements`
        ]
    },      {
      date: 'March 22, 2019',
      version: '4.4.6',
      features: [],
      bugs: [
        `Updated the Bookmarks display table to include a type as a separate column and display an alert when bookmarked proposal has been deleted.`,
        `Fixed a scenario where two K12 applications were showing both in LA and OC on the Applications list when filtering by region.`,
        `Updated K12 LOI and Application list page to no longer allow editing of drafts since the submission period has expired.`,
        `Miscellaneous service performance enhancements.`
        ]
    },
    {
      date: 'March 18, 2019',
      version: '4.4.5',
      features: [
        `Added a new option to Local Vision Goals to view and print a template version of the report as a worksheet for offline use`
      ],
      bugs: [
        `Updated Local Vision Goals to show baseline numbers rounded to nearest whole number and corrected display of Goal 5 baseline values to match Launchboard data`,
        `K12 Application Review workflow updated to support chairperson as reviewer, training applications created in sandbox environment, miscellaneous other tweaks and improvements`,
      ]
    },
    {
      date: 'March 13, 2019',
      version: '4.4.3',
      features: [
        `Local Vision Goals now populates baseline data for goals 4A and 4B when available`
      ],
      bugs: [
        `For K12 SWP Application, updated function of the dropout rate field to only save numeric input`,
        `Fixed an issue with missing data on Preview & Submit and related functions of share PDF/HTML and printing`,
        `Updated LOI preview to not display the “Time window expired…” message when creating an archive at application start time`,
        `Various migrations to correct AEBG data entry errors`,
        `Added sorting capability to the Institutions list table view`
      ]
    },
    {
      date: 'March 1, 2019',
      version: '4.4.1',
      features: [
        `K12 SWP Application Review functionality including selection committee role assignment, assign reviewers to applications, and full review and scoring functionality for submitted applications.
          Role specific landing/listing pages for Selection Committee Chairperson and individual reviewers and supporting functions`,
        `Bookmarking now available on most pages within NOVA`,
        `Los Angeles and Orange County are split into two separate regions throughout NOVA`
      ],
      bugs: [
        `Miscellaneous fixes to AEBG Program Area reporting submit validation rules to assist with problem reports`
      ]
    },
    {
      date: 'February 22, 2019',
      version: '4.3.4',
      features: [
        'Local Vision Goals baseline metrics 2B, 4A, 4B, and 4C updated to provide values that support “Vision Baseline Year” of 2016-17. Added baseline metric disclaimer to preview/submit page.',
        'Miscellaneous small bug fixes',
      ],
      bugs: []
    },
    {
      date: 'February 17, 2019',
      version: '4.3.2',
      features: [
        `Improvements to AEBG Program Area Reporting validation and save strategy. Decimal number input for program hours now rounded to nearest whole number.
          Corrected an error that displayed incorrect certification status for consortia with optional members.`,
        `Improvements to K12 SWP Application workflow validation and submit.`,
        `K12 SWP Letter of Intent submission suspended as of 2/15/19 at 1700.`
      ],
      bugs: []
    },
    {
      date: 'February 8, 2019',
      version: '4.3.1',
      features: [
        `New menu item “Institutions” added to the main menu which provides a hierarchical view of institutions and related roles`
      ],
      bugs: [
        `All Guided Pathways projects have been re-opened for updating and certification after generating an archive file available
        for viewing in a new Project History card on the project preview page. Minor content updates to all Guided Pathway pages to eliminate display of “Timeframe”`,
        `Minor fix to document sharing where delivered documents could contain no content`,
        `Contact cards display formatted phone numbers`,
        `Minor textual updates to Local Vision Goals`
      ]
    },
    {
      date: 'February 6, 2019',
      version: '4.3.0',
      features: [],
      bugs: [
        'Local Vision Goals updated to provide baseline metrics from Student Success Metrics v2.0. One minor change to Goal 1A and one new metric added to Goal 1C',
        'Fixed an issue with K12 SWP Application that removed contacts when the lead institution was changed'
      ]
    },
    {
      date: 'January 24, 2019',
      version: '4.2.2',
      features: [
        `New analytics dashboard added for Local Vision Goals. Navigate to Local Vision Goals under Programs and click on the Analytics sub-menu item`
      ],
      bugs: [
        `Updated AEBG allocation amendment functionality to allow addition of members without requiring allocation of funds`,
        `FIFO chart updated to more accurately represent expenditures and forecasts by quarter`,
        `Added more granular tagging for Google Analytics reporting`,
        `Improved file handling and tagging across all programs`,
        `Share file feature updated to inform user of additional options available for printing`,
        `Improved form validation of SWP-Regional proposal steps`,
        `Improved filter capabilities on listing pages`,
        `System generated notifications now arrive via e-mail and in-app message`,
        `Improved information displayed in certification cards to better identify approvers`
      ]
    },
    {
      date: 'January 9, 2019',
      version: '4.2.0',
      features: [
        `K12 SWP Application functionality added providing those who have submitted a K12 SWP Letter of Intent the option of completing and submitting a grant application for consideration.`,
        `Document sharing and archiving within NOVA now supports PDF and accessible HTML. “Share PDF” is now “Share.”
        The user can now enter a comment to be included in the e-mail notifications sent when sharing documents. Both PDF and HTML files are attached.`
      ],
      bugs: [
        `Minor updates and improvements to the Local Vision Goals form validation.`,
        `More migrations to correct data entry errors and add Charter Schools.`
      ]
    },
    {
      date: 'December 18, 2018',
      version: '4.1.1',
      features: [],
      bugs: [`Various updates and improvements to the Local Vision Goals`,
        `Added permissions to contacts of partner institutions to provide additional K12 SWP Letter of Intent capabilities`,
        `Updated AEBG permissions for member contacts to edit Program Area reporting for their institution`],
    },
    {
      date: 'December 12, 2018',
      version: '4.1.0',
      features: [
        `In support of the California Community Colleges Vision for Success, NOVA now includes a new program “Local Vision Goals” which provides a platform for institutions to declare their goals.`,
        `”Funds” menu item renamed to “Programs” with Local Vision Goals added`,
        `New combined logos and footer adjustments for application pages and system generated e-mails`,
      ],
      bugs: [
        `Fixed an issue with K12 SWP Letter of Intent list throwing an exception when lead institution is missing`,
        `More migrations to correct data entry errors`
      ]
    },
    {
      date: 'November 28, 2018',
      version: '4.0.23',
      features: [],
      bugs: [
        `Applied numerous migrations to correct data entry errors`,
        `Fixed an issue where AEBG member agency dashboard was displaying consortium contact list instead of member agency contact list`,
        `Updated “Scope” helper text for K12 SWP Letter of Intent to direct user to include partner information`
      ]
    },
    {
      date: 'November 21, 2018',
      version: '4.0.22',
      features: [],
      bugs: [
        `Updated FIFO for AEBG to use earmarked expenditures to improve accuracy and filtered out K12 SWP institution types from chart display`,
        `Removed year display from SWP headers`,
        `Removed filter requirement from Admin Console bulk e-mail feature to allow send to all users. Implemented a fix to more accurately estimate recipient count`,
        `Corrected recipient filter in Member Plan approval & rejection emails so only affected members are notified`,
        `Reorganized Funds submenu to be alphabetic`,
        `AEBG Budget & Workplan archive display fixed to show historical archives in file list`,
        `Adjusted method for determining AEBG active members based on available funds`,
        `ROC/Ps now available for K12 SWP Letter of Intent selection`,
        `Corrected display of indirect cost percentage so it is red only when the percentage is greater than 5%`
      ]
    },
    {
      date: 'November 5, 2018',
      version: '4.0.20',
      features: [
        `Added K12 Strong Workforce Program fund including Letter of Intent submission and Fund Settings. Users will be invited to submit via e-mail and will create their own user accounts if needed.`,
        `AEBG Certification cards updated with additional information for consistency with other funds.`,
      ],
      bugs: [
        `Improved error checking for SWP project duration changes.`
      ]
    },
    {
      date: 'October 25, 2018',
      version: '4.0.19',
      features: [],
      bugs: [
        `SWP project end date/fiscal report migration to correct cases where end date was shortened to before last fiscal report was submitted`,
        `SWP alert for users attempting to reduce project duration too far in the past. When a project has Fiscal Reports for 3 years, for instance, and the duration is reduced to 2 years, this is not allowed.`,
        `SWP project in Edit Submitted state can now be approved.`
      ]
    },
    {
      date: 'October 15, 2018',
      version: '4.0.17',
      features: [
        `AEBG Consortium Dashboard updated to provide separate area for Three-Year plan document uploads and viewing`,
        `AEBG Consortium Dashboard now provides unified user interface to make allocation amendments to all available funding years with member certification workflow`,
        `AEBG Annual Plan amendments with member certification workflow`,
        `AEBG expense reporting of CDE program area hours of instruction and leveraged funds with member certification workflow`,
        `AEBG Q4 Fiscal Year closeout workflow`,
        `AEBG Allocation Year closeout workflow`,
        `AEBG Minor adjustments to budgets and expenditure reporting submission validation`,
        `AEBG Automation and dependencies for the annual fiscal cycle (CFAD, Annual Plan, Budget & Workplan and Fiscal Reporting)`,
        `SWP projects get the ability to close projects with certification`,
        `SWP ability to filter on new project statuses (pending close & closed)`,
        `SWP Regional Share Fiscal Reporting Dashboard updated to display only active member reporting status on project cards.`
      ],
      bugs: [
        `Miscellaneous improvements to AEBG Fiscal Reporting functions`
      ]
    },
    {

      date: 'October 2, 2018',
      version: '4.0.15',
      features: [],
      bugs: [
        `Fixed an issue with invite user e-mail template missing temporary password credentials`
      ]
    },
    {
      date: 'October 1, 2018',
      version: '4.0.14',
      features: [],
      bugs: [
        `Fixed an issue preventing certification of AEBG Annual Plans`,
        `Fixed an issue preventing uncertification of SWP Fiscal Reports`
      ]
    },
    {
      date: 'September 28, 2018',
      version: '4.0.13',
      features: [],
      bugs: [
        `Fixed a service side issue displaying an error dialog when sending a reminder`
      ]
    },
    {
      date: 'September 28, 2018',
      version: '4.0.12',
      features: [
        `Expanded the permissions of the 'NOVA Administrator' role to be able to edit, submit and approve all projects and reports for all funds`,
        `Improved formatting of contact phone numbers across the system`,
        `Updated system email strategy to prevent potential spamming and reduce the risk of unacceptable bounce-rates`,
        `NOVA now supports two years of open AEBG Budget & Workplans simultaneously. Users can work on their current year and also have access to update the previous year`
      ],
      bugs: [
      ]
    },
    {
      date: 'September 25, 2018',
      version: '4.0.11',
      features: [
      ],
      bugs: [
        `Fixed an issue with AEBG Budget Item entry to eliminate erroneous alert related to empty Carry-over field`,
      ]
    },
    {
      date: 'September 14, 2018',
      version: '4.0.10',
      features: [
        'FIFO (first in, first out) Report added to Fiscal Reporting dashboard for SWP and AEBG projects',
        'AEBG Budget & Workplans can now be edited without requiring previously submitted and certified quarterly reports to be unsubmitted',
        'Added enhanced support for AEBG non-allocated voting members',
        'Database update: Role updates applied to AEBG users to remove consortium-level access and provide member-level access instead',
        'Database update: SWP Allocations and Incentives imported for 2017-2018',
      ],
      bugs: [
      ]
    },
    {
      date: 'August 30, 2018',
      version: '4.0.7',
      features: [
        'Split Strong Workforce into two separate funds on the navigation, SWP-Local and SWP-Regional',
        'Removed requirement for Strong Workforce Q4 Annual Survey'
      ],
      bugs: [
        'Fixed issue where Guided pathway proposals were not displaying Launchboard Performance Indicators',
        'Updated displayed list of institutions on the Add Role screen so Institution roles can be assigned for member agencies',
        'Fixed issue where “project progress” buttons were displayed for AEBG Fiscal Reporting project cards',
        'Fixed an issue with Admin Console User Management filter to show deleted users',
        'Fixed an issue to display auto submit of fiscal reports for Strong Workforce projects with no budgets'
      ]
    },
    {
      date: 'August 21, 2018',
      version: '4.0.6',
      features: [

      ],
      bugs: [
        'Corrected issue when attempting to Uncertify SWP Projects',
        'Updated budget summary & field labels on AEBG Consortium pages',
        'Improved read-only view of text fields on fiscal feporting cards'
      ]
    },
    {
      date: 'August 20, 2018',
      version: '4.0.4',
      features: [
        'Increased character limit for AEBG work plan template fields to 5000',
        'Removed limit for number of strategies on AEBG work plans',
        'Added display of estimated count of recipients for bulk e-mails sent through Admin Console communications tab',
      ],
      bugs: [
        'Fixed broken Help link to AEBG FAQs/Manual',
        'Updated heading and helper text for AEBG fiscal reporting of corrective action plan',
        'Corrected display of indirect costs in AEBG fiscal reporting',
        'Corrected issue submitting Guided Pathways projects',
      ]
    },
    {
      date: 'August 16, 2018',
      version: '4.0.3',
      features: [
        'Improved display and functionality in the header on Fiscal Reporting pages',
      ],
      bugs: [
        'Corrected issue preventing uncertification of Annual Reports after adding a new Member Representative',
      ]
    },
    {
      date: 'August 15, 2018',
      version: '4.0.2',
      features: [
        'Improved security around password reset workflow',
        'Added \'Display Inactive Users\' checkbox on Role Management page',
      ],
      bugs: [
        'Corrected the display of per-institution status icons for SWP Fiscal Reporting',
        'Corrected issue with circular navigation during password reset workflow'
      ]
    },
    {
      date: 'August 13, 2018',
      version: '4.0.1',
      features: [],
      bugs: [
        'Corrected a permissions issue preventing AEBG budgets from being edited',
        'Corrected a permissions issue preventing AEBG annual plans from being approved',
        'Corrected route for user profiles of admin users'
      ]
    },
    {
      date: 'August 7, 2018',
      version: '4.0.0',
      features: [
        'Reorganized and ADA compliant navigation, plus site-wide ADA improvements including screen reader compatibility',
        'Role-based access control and improved security for all users',
        'Administration Console to manage users and roles and send group emails',
        'Enhanced user profile and password management',
        'Fund Settings including milestone dates and automated system notifications (in-app and scheduled e-mail)',
        'Allocation amendments for all funds'
      ],
      bugs: [
        'Corrected calculation of indirect cost percentage for AEBG budgets',
      ]
    }
  ];

console.log(ALL_RELEASES[0].version);
