import { Component } from '@angular/core';
import { SurveyQuestionComponent } from '../survey-question.component';
import { Actions, Model } from '@app-ngrx-domains';

@Component({
  selector: 'survey-table-question',
  templateUrl: './table-question.component.html'
})
export class TableQuestionComponent extends SurveyQuestionComponent {

  private rowLength: number;

  buildForm() {
    this.rowLength = this.question.table_rows.length;
    const controls = {};
    this.question.table_rows.forEach(row => {
      this.question.survey_table_columns.forEach(column => {
        const key = `${row.id}_${column.id}`;
        const validators = this.validatorsForQuestionType(column.question_type);
        controls[key] = [this.getValueForKey(this.question.id, null, row.id, column.id), validators];
      });
    });

    this.form = this._fb.group(controls);
  }

  get displayAsColumns() {
    return this.rowLength > this.maxSingleColumnLength;
  }

  get column1Rows() {
    if (this.displayAsColumns) {
      return this.question.table_rows.slice(0, Math.ceil(this.rowLength / 2));
    } else {
      return this.question.table_rows;
    }
  }

  get column2Rows() {
    if (this.displayAsColumns) {
      return this.question.table_rows.slice(Math.ceil(this.rowLength / 2));
    } else {
      return [];
    }
  }

  createTempResponses() {
    if (this.responses.some(response =>
      response.question_id === this.question.id &&
      response.group_id === (this.groupId || null) &&
      response.response_group === (this.responseGroupNumber || null))) {
      return;
    }

    const responses = [];
    this.question.table_rows.forEach(row => {
      this.question.survey_table_columns.forEach(column => {
        responses.push({
          ...this.baseResponseFields,
          table_row_id: row.id,
          table_column_id: column.id
        });
      });
    });

    if (this.surveyService.useCurrentProposalStore) {
      this.store.dispatch(Actions.CurrentProposal.createTempEffortAreas({ eas: responses }));
    } else {
      this.store.dispatch(Actions.Proposals.createTempEffortAreas({ eas: responses, proposal_id: this.surveyService.proposalId }));
    }
  }

  trackById(index: number, item: Model.EASurveyQuestion) {
    return item.id;
  }
}
