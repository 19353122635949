// modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';

// shared modules
import { GenericModule } from '@app-generic/generic.module';

// components
import { ProjectComponents } from './components';

// services
import { PlanWorkflowValidatorService } from '@app/RCM/validators/plan-workflow-validator.service';
import { SurveysModule } from '@app/shared.surveys/surveys.module';

@NgModule({
  declarations: [
    ...ProjectComponents
  ],
  providers: [
    PlanWorkflowValidatorService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    RouterModule,

    // shared modules
    GenericModule,
    SurveysModule
  ],
  exports: [
    ...ProjectComponents
  ],
})
export class ProjectModule { }
