import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Model } from '@app-ngrx-domains';
import { BehaviorSubject } from 'rxjs';
import { SurveyResponseValidator } from '@app/shared.surveys/validators';

@Injectable()
export class SurveyService {

  canEdit: boolean;
  useCurrentProposalStore: boolean;
  proposalId: number;
  institutionId: number;
  durationId: number;
  effortAreaId: number;
  fundId: number;
  userId: number;
  isPreview: boolean;
  hideQuestionNumbers: boolean;
  validator: SurveyResponseValidator;

  questions: BehaviorSubject<Array<{
    id: number,
    responseGroup?: number,
    form: FormGroup,
    getResponse: (responseOptionId?: number) => any
  }>> = new BehaviorSubject([]);

  initialize(config: {
    canEdit?: boolean,
    useCurrentProposalStore?: boolean,
    proposalId?: number,
    institutionId?: number,
    durationId?: number,
    effortAreaId?: number,
    fundId?: number,
    userId?: number,
    isPreview?: boolean,
    hideQuestionNumbers?: boolean
  }) {
    this.canEdit = config.canEdit;
    this.useCurrentProposalStore = config.useCurrentProposalStore;
    this.proposalId = config.proposalId;
    this.institutionId = config.institutionId;
    this.durationId = config.durationId;
    this.effortAreaId = config.effortAreaId;
    this.fundId = config.fundId;
    this.userId = config.userId;
    this.isPreview = config.isPreview;
    this.hideQuestionNumbers = config.hideQuestionNumbers;
  }

  createValidator(
    surveyQuestions: Array<Model.EASurveyQuestion>,
    surveyResponses: Array<Model.EASurveyResponse>,
    files: Array<Model.Document>
  ) {
    const config = {
      proposalId: this.proposalId,
      institutionId: this.institutionId,
      durationId: this.durationId,
      effortAreaId: this.effortAreaId,
      fundId: this.fundId
    };

    this.validator = new SurveyResponseValidator(config, surveyQuestions, surveyResponses, files);
    return this.validator;
  }

  registerQuestion(question: { id: number, responseGroup?: number, form: FormGroup, getResponse: () => any }) {
    this.questions.next([...this.questions.value, question]);
  }

  unregisterQuestion(questionId: number, responseGroup?: number) {
    const remainingQuestions = this.questions.value.filter(q => {
      return (q.id !== questionId) || (q.responseGroup !== responseGroup);
    });

    this.questions.next(remainingQuestions);
  }

}
