<survey-response-field
  [labelText]="questionLabel"
  [questionType]="question.question_type"
  [canEdit]="surveyService.canEdit"
  [form]="form"
  [controlName]="question.id"
  [isRequired]="isRequired"
  [uniqueTag]="uniqueTag"
  [helpText]="helpText"
  [textLimit]="question.text_limit"
  (onChange)="persistResponse(question.id, responseType)">
</survey-response-field>