import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Actions, Model, Queries, State } from '@app-ngrx-domains';
import { ALLOWED_EXTENSIONS, FILE_UPLOAD_TYPES, TIMEOUT_DELAYS } from '@app/core/consts';
import { ApiService } from '@app/core/services';
import { Store } from '@ngrx/store';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-document-upload-inline',
  templateUrl: './document-upload-inline.component.html'
})
export class DocumentUploadInlineComponent implements OnInit, OnDestroy {

  @Input() canEdit: boolean;
  @Input() required: boolean;
  @Input() labelText: string = 'File';
  @Input() helpText: string;
  @Input() uploadText: string = 'Choose File';
  @Input() documentTypeId: number;
  @Input() allowedExtensions = ALLOWED_EXTENSIONS;
  @Input() institutionOptions: Array<Model.SelectOption> = [];
  @Input() fundId?: number;
  @Input() institutionId?: number;
  @Input() proposalId?: number;
  @Input() durationId?: number;
  @Input() effortAreaId?: number;
  @Input() uploadType: string = FILE_UPLOAD_TYPES.PROJECT_FILE;
  @Input() useTitle?: string;

  @ViewChild('uploadInput') uploadInputRef: ElementRef;

  existingFile: Model.Document;
  fileUploader: FileUploader;
  statusMessage: Model.StatusMessage = { type: null, message: null };
  showRemoveAlert: boolean;

  private currentUserId: number;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private apiService: ApiService,
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.store.select(Queries.Files.getItems).pipe(
      withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId)),
      takeUntil(this.destroy$)
    ).subscribe(([files, userId]) => {
      this.currentUserId = userId;
      this.existingFile = this.findExistingFile(files);

      this.fileUploader = this.apiService.fileUploader(this.uploadType);
      this.fileUploader.onAfterAddingFile = this.onAddingFile();
      this.fileUploader.onCompleteItem = this.onUploadComplete();
    });
  }

  onAddingFile() {
    return file => {
      this.uploadInputRef.nativeElement.value = ''; // Clear the input's value for uploading duplicate files (https://github.com/valor-software/ng2-file-upload/issues/220)
      const extension = file.file.name.substr(file.file.name.lastIndexOf('.'));
      if (!this.allowedExtensions.includes(extension) && !this.allowedExtensions.includes(file.file.type)) {
        this.statusMessage.type = 'fail';
        this.statusMessage.message = `Invalid file type selected. Allowed types are ${this.allowedExtensions.join(' ')}`;
        this.fileUploader.removeFromQueue(file);
      } else {
        if (this.existingFile) {
          this.store.dispatch(Actions.Files.delete(this.existingFile.id));
        }

        this.statusMessage.type = 'loading';
        this.statusMessage.message = 'Uploading file';

        file.withCredentials = false;
        this.fileUploader.options.additionalParameter = this.getFileOptions();
        this.fileUploader.uploadItem(this.fileUploader.queue[0]);
      }
    };
  }

  onUploadComplete() {
    return (item, res, status, headers) => {
      if (status < 200 || status > 299 || !res) {
        this.statusMessage.type = 'fail';
        this.statusMessage.message = 'There was a problem uploading this file. Please try again.';
      } else {
        const file = JSON.parse(res);
        this.store.dispatch(Actions.Files.append(file));
        this.statusMessage.type = 'success';
        this.statusMessage.message = 'File uploaded successfully';

        // Clear status messages after upload
        setTimeout(() => {
          this.statusMessage.type = undefined;
        }, TIMEOUT_DELAYS.SHORT);
      }
    };
  }

  findExistingFile(files: Array<Model.Document>) {
    return files.find(file =>
      file.document_type_id === this.documentTypeId &&
      file.proposal_id === (this.proposalId || null) &&
      file.fund_id === (this.fundId || null) &&
      file.institution_id === (this.institutionId || null) &&
      file.duration_id === (this.durationId || null) &&
      file.effort_area_id === (this.effortAreaId || null)
    );
  }

  getFileOptions() {
    const fileOptions: any = { document_type_id: this.documentTypeId, creator_user_id: this.currentUserId };
    if (this.proposalId) { fileOptions.proposal_id = this.proposalId; }
    if (this.fundId) { fileOptions.fund_id = this.fundId; }
    if (this.durationId) { fileOptions.duration_id = this.durationId; }
    if (this.institutionId) { fileOptions.institution_id = this.institutionId; }
    if (this.effortAreaId) { fileOptions.effort_area_id = this.effortAreaId; }

    return fileOptions;
  }

  get allowedExtensionsExpanded() {
    return this.allowedExtensions.join(',');
  }

  toggleRemoveAlert() {
    this.showRemoveAlert = !this.showRemoveAlert;
  }

  removeFile() {
    this.store.dispatch(Actions.Files.delete(this.existingFile.id));
    this.toggleRemoveAlert();
    this.statusMessage.type = undefined;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
