import { Component } from '@angular/core';
import { SurveyQuestionComponent } from '../survey-question.component';
import { Model } from '@app-ngrx-domains';
import { ValidatorsEx } from '@app/core/utilities';
import { DROPDOWN_TYPES } from '@app/shared.surveys/consts';
import { sortBy } from 'lodash';

@Component({
  selector: 'survey-dropdown-question',
  templateUrl: './dropdown-question.component.html'
})
export class DropdownQuestionComponent extends SurveyQuestionComponent {

  public options: Array<Model.SelectOption> = [];
  public isMulti: boolean;

  ngOnInit() {
    this.options = sortBy(this.question.response_options, ['sort_order']).map(c => ({ label: c.title as string, value: c.id }));
    this.isMulti = this.question.dropdown_type === DROPDOWN_TYPES.MULTI;
    super.ngOnInit();
  }

  buildForm() {
    let value;

    if (this.isMulti) {
      value = [];

      for (let choice of this.question.response_options) {
        const choiceId = choice.id;
        const boolResponse = this.getValueForKey(this.question.id, choiceId);
        if (boolResponse) {
          value.push(choiceId);
        }
      }
    } else {
      const textResponse = this.getValueForKey(this.question.id);
      if (textResponse) {
        const option = this.question.response_options.find(c => c.title === textResponse);
        if (option) {
          value = option.id;
        }
      }
    }

    this.form = this._fb.group({
      [this.question.id]: [value, this.isRequired ? ValidatorsEx.requiredSelection : undefined]
    });
  }

  setDropdownValue(id: number, add: boolean) {
    if (this.isMulti) {
      this.persistValue(add, 'bool_response', { responseOptionId: id });
    } else {
      let title;
      if (add) {
        const option = this.question.response_options.find(c => c.id === id);
        if (option) {
          title = option.title;
        }
      }

      this.persistValue(title, 'text_response');
    }
  }
}
