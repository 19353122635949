
<label for="file_upload" [class.required]="required" [attr.data-invalid]="required && !existingFile">{{labelText}}</label>
<div class="file-upload margin-bottom" [ngClass]="{ 'file-upload--block': !!helpText }">
  <div *ngIf="helpText" class="help-text">
    <p>{{helpText}}</p>
  </div>
  <div class="flex">
    <div *ngIf="existingFile" class="file-upload__file">
      <a [href]="existingFile.url" target="_blank" class="pre-wrap text-link">{{existingFile.filename}}</a>
      <button *ngIf="canEdit" class="file-upload__clear-button" title="Remove File" (click)="toggleRemoveAlert()">
        <icon iconId="close"></icon>
      </button>
    </div>
    <button *ngIf="canEdit" type="button" class="action-button action-button--primary action-button--small margin-right-sm"
      [disabled]="statusMessage?.type === 'loading'"
      (click)="uploadInput.click()">
      {{uploadText}}
    </button>
  </div>
  <input #uploadInput id="file_upload" type="file" ng2FileSelect [uploader]="fileUploader" [hidden]="true" #fileUploadInput [accept]="allowedExtensionsExpanded"/>
  <po-status-message *ngIf="statusMessage" [statusMessage]="statusMessage" [alignLeft]="true"></po-status-message>
</div>

<alert *ngIf="showRemoveAlert"
  button-action="Remove"
  button-dismiss="Cancel"
  level="warning"
  (action)="removeFile()"
  (dismiss)="toggleRemoveAlert()">
  <p>Are you sure you want to remove this document?</p>
</alert>
