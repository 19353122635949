import { CHAR_LIMITS, DOCUMENT_TYPES } from '@app-consts';
import { Model } from '@app-ngrx-domains';
import { Utilities } from '@app/core/models';
import { AppUtils, Validate } from '@app/core/utilities';
import { uniq } from 'lodash';

export const DROPDOWN_TYPES = {
  SINGLE: 'single',
  MULTI: 'multi'
};

export const QUESTION_TYPES = {
  TABLE: 'table',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  FILE: 'file',
  TEXT: 'text',
  NUMBER: 'number',
  PERCENT: 'percent',
  CURRENCY: 'currency',
  GROUP: 'group'
};

export const INSTITUTION_TYPES = {
  ALL: null,
  LEAD: 'lead',
  BUDGETED: 'budgeted'
};

export const DEFAULT_TEXT_LIMIT = CHAR_LIMITS.SHORT;

export const SURVEY_FILE_TYPE = DOCUMENT_TYPES.REPORT_SUPPORTING_DOC.id;

export const SURVEY_AREAS = {
  REPORTING: 'Reporting',
  REVIEW: 'Review',
  CUSTOM_WORKFLOW_STEP: 'Custom Workflow Step',
  CFAD_GOVERNANCE: 'CFAD Governance',
  FISCAL_AGENT: 'Fiscal Agent',
  WRITTEN_EXPENDITURE_PLAN: 'Written Expenditure Plan'
};

// Move these to Utils

export const SURVEY_UTILS = {
  findResponse: (response: Model.EASurveyResponse) => {
    if (response) {
      const field = ['text_response', 'int_response', 'bool_response'].find(f => response[f] != null && response[f] !== '');
      return field ? response[field] : undefined;
    }
  },
  isMultiSelectQuestion(question: Model.EASurveyQuestion) {
    return question.question_type === QUESTION_TYPES.CHECKBOX || (question.question_type === QUESTION_TYPES.DROPDOWN && question.dropdown_type === DROPDOWN_TYPES.MULTI);
  },
  formatQuestionNumber(question: Model.EASurveyQuestion, questionIndex?: number, parentQuestionNumber?: number, groupNumber?: number, showFollowUpAnswer?: boolean) {
    const sections = [groupNumber, parentQuestionNumber, questionIndex || question.question_number].filter(section => !!section);
    const questionNumber = (sections.length > 1) ? sections.join('.') : sections[0] + '.';
    return questionNumber + ((showFollowUpAnswer && question.if_answer_is) ? ` (${question.if_answer_is})` : '');
  },
};