<form [formGroup]="form">
  <input-group [headingText]="questionLabel" [control]="form" [required]="isRequired" [errorsOnUntouched]="surveyService.canEdit && form.invalid"
    id="radio_question_{{question.id}}_{{uniqueTag}}">
    <div *ngIf="helpText" class="help-text">
      <p [innerHtml]="helpText"></p>
    </div>

    <div [ngClass]="{'columns columns--2': displayAsColumns}">
      <div>
        <radio-check *ngFor="let choice of column1Choices" [labelText]="choice.title" [canEdit]="surveyService.canEdit">
          <input inputRef id="question{{question.id}}_choice{{choice.id}}_{{uniqueTag}}" type="radio"
            [value]="choice.title" [formControlName]="question.id" (change)="persistResponse(question.id, 'text_response')">
        </radio-check>
      </div>
      <div *ngIf="displayAsColumns">
        <radio-check *ngFor="let choice of column2Choices" [labelText]="choice.title" [canEdit]="surveyService.canEdit">
          <input inputRef id="question{{question.id}}_choice{{choice.id}}_{{uniqueTag}}" type="radio"
            [value]="choice.title" [formControlName]="question.id" (change)="persistResponse(question.id, 'text_response')">
        </radio-check>
      </div>
    </div>
  </input-group>
</form>
