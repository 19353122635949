<ng-container *ngIf="survey">
  <h3>
    {{survey.title}}
    <span *ngIf="surveyVersion" class="text--small">v.{{surveyVersion}}</span>
    <span *ngIf="surveyVersion && isPreview"> - Preview</span>
  </h3>

  <ng-container *ngIf="!showAsDraft; else isDraft">
    <div *ngFor="let question of surveyQuestions; let index = index; trackBy: trackById;" id="survey_question_{{question.id}}">
      <ng-container [ngTemplateOutlet]="questionTemplate" [ngTemplateOutletContext]="{ question: question, questionIndex: index + 1 }"></ng-container>

      <div *ngIf="!!followUpQuestions(question)?.length" class="list-filter__container" [@collapsible]>
        <div *ngFor="let followUp of followUpQuestions(question); let subIndex = index; trackBy: trackById" id="survey_question_{{question.id}}_{{followUp.id}}">
          <ng-container [ngTemplateOutlet]="questionTemplate" [ngTemplateOutletContext]="{ question: followUp, parentQuestionNumber: index + 1, questionIndex: subIndex + 1 }"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #questionTemplate let-question="question" let-parentQuestionNumber="parentQuestionNumber" let-questionIndex="questionIndex">
  <ng-container [ngSwitch]="question.question_type">
    <survey-table-question *ngSwitchCase="questionTypes.TABLE"
      [question]="question"
      [questionIndex]="questionIndex"
      [parentQuestionNumber]="parentQuestionNumber">
    </survey-table-question>

    <survey-radio-question *ngSwitchCase="questionTypes.RADIO"
      [question]="question"
      [questionIndex]="questionIndex"
      [parentQuestionNumber]="parentQuestionNumber">
    </survey-radio-question>

    <survey-checkbox-question *ngSwitchCase="questionTypes.CHECKBOX"
      [question]="question"
      [questionIndex]="questionIndex"
      [parentQuestionNumber]="parentQuestionNumber">
    </survey-checkbox-question>

    <survey-file-question *ngSwitchCase="questionTypes.FILE"
      [question]="question"
      [questionIndex]="questionIndex"
      [parentQuestionNumber]="parentQuestionNumber">
    </survey-file-question>

    <survey-dropdown-question *ngSwitchCase="questionTypes.DROPDOWN"
      [question]="question"
      [questionIndex]="questionIndex"
      [parentQuestionNumber]="parentQuestionNumber">
    </survey-dropdown-question>

    <survey-question-group *ngSwitchCase="questionTypes.GROUP"
      [groupQuestion]="question"
      [questions]="questionsForGroup(question)">
    </survey-question-group>

    <ng-container *ngSwitchDefault>
      <ng-container [ngSwitch]="!!question.multi_response">
        <multi-response-question *ngSwitchCase="true"
          [question]="question"
          [questionIndex]="questionIndex"
          [parentQuestionNumber]="parentQuestionNumber">
        </multi-response-question>

        <survey-question *ngSwitchDefault
          [question]="question"
          [questionIndex]="questionIndex"
          [parentQuestionNumber]="parentQuestionNumber">
        </survey-question>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #isDraft>
  <p class="empty-state">This survey is being modified. Please check back later or contact your administrator.</p>
</ng-template>