import { Model } from '@app-ngrx-domains';
import { QUESTION_TYPES, DROPDOWN_TYPES, DEFAULT_TEXT_LIMIT, SURVEY_UTILS, SURVEY_FILE_TYPE } from '@app/shared.surveys/consts';
import { CHAR_LIMITS } from '@app-consts';
import { AppUtils, Validate } from '@app/core/utilities';
import { uniq } from 'lodash';

export class SurveyResponseValidator {
  proposalId: number;
  institutionId: number = null;
  durationId: number = null;
  effortAreaId: number = null;
  fundId: number = null;
  files: Array<Model.Document>;
  surveyQuestions: Array<Model.EASurveyQuestion>;
  surveyResponses: Array<Model.EASurveyResponse>;

  constructor(
    config: {
      proposalId: number,
      institutionId?: number,
      durationId?: number,
      effortAreaId?: number,
      fundId?: number,
    },
    surveyQuestions: Array<Model.EASurveyQuestion>,
    surveyResponses: Array<Model.EASurveyResponse>,
    files?: Array<Model.Document>,
  ) {
    this.proposalId = config.proposalId;
    this.institutionId = config.institutionId;
    this.durationId = config.durationId;
    this.effortAreaId = config.effortAreaId;
    this.fundId = config.fundId;
    this.files = files || [];
    this.surveyQuestions = surveyQuestions;

    this.surveyResponses = (surveyResponses || []).filter(response => {
      return this.institutionId == response.institution_id &&
      this.durationId == response.duration_id &&
      this.effortAreaId == response.parent_effort_area_id &&
      this.fundId == response.fund_id;
    });

    // Filter questions if we have multiple versions provided
    const providedVersions = uniq(this.surveyQuestions.map(question => question.survey_version));
    if (providedVersions.length > 1) {
      let useVersion = Math.max(...providedVersions);
      const response = this.surveyResponses.length ? this.surveyResponses[0] : undefined;
      if (response) {
        const question = this.surveyQuestions.find(q => q.id === response.question_id);
        useVersion = question ? question.survey_version : useVersion;
      }
      this.surveyQuestions = this.surveyQuestions.filter(question => question.survey_version === useVersion);
    }
  }

  validateResponses() {
    return this.surveyQuestions.filter(question => !question.follow_up_to && !question.group_id).every(question => {
      if (question.question_type === QUESTION_TYPES.GROUP) {
        return this.validateQuestionGroup(question);
      }

      if (!this.validateSurveyQuestion(question)) {
        return false;
      }

      // Validate follow-up questions
      const followUpQuestions = this.surveyQuestions.filter(q => q.follow_up_to === question.id);
      return followUpQuestions.every(followUp => {
        let doValidate = false;
        if (SURVEY_UTILS.isMultiSelectQuestion(question)) {
          const choice = question.response_options.find(choice => choice.title === followUp.if_answer_is);
          if (choice) {
            const response = this.surveyResponses.find(response =>
              response.question_id === question.id &&
              response.response_option_id === choice.id);

            doValidate = !!SURVEY_UTILS.findResponse(response);
          }
        } else {
          const response = this.surveyResponses.find(response => response.question_id === question.id);
          const responseValue = SURVEY_UTILS.findResponse(response);
          doValidate = String(responseValue) === followUp.if_answer_is;
        }

        return !doValidate || this.validateSurveyQuestion(followUp);
      });
    });
  }

  baseValidator(responseKey: {
    questionId: number,
    group_id?: number,
    response_group?: number,
    responseOptionId?: number,
    tableRowId?: number,
    tableColumnId?: number
  }, isOptional: boolean, questionType: string, textLimit: number = DEFAULT_TEXT_LIMIT, checkHasResponse?: boolean) {
    const responses = this.surveyResponses.filter(r => r.question_id === responseKey.questionId &&
      r.group_id == (responseKey.group_id || null) &&
      r.response_group == (responseKey.response_group || null) &&
      r.response_option_id == (responseKey.responseOptionId || null) &&
      r.table_row_id == (responseKey.tableRowId || null) &&
      r.table_column_id == (responseKey.tableColumnId || null));


    // If checkHasResponse is true, we don't want to validate everything, just check if a response exists
    if ((checkHasResponse || !isOptional) && !responses.length) {
      return false;
    }

    if (responses.length > 1 && [QUESTION_TYPES.TEXT, QUESTION_TYPES.NUMBER, QUESTION_TYPES.CURRENCY, QUESTION_TYPES.PERCENT].includes(questionType)) {
      // only validate the first response (duplication error)
      responses.splice(1);
    }

    const testMethod = checkHasResponse ? 'some' : 'every';

    return responses[testMethod](response => {
      const responseValue = SURVEY_UTILS.findResponse(response);
      const hasResponse = responseValue != null;

      if (checkHasResponse) {
        return hasResponse;
      }

      if (!hasResponse) {
        return isOptional;
      }

      if (questionType === QUESTION_TYPES.TEXT) {
        const useHtmlValidator = textLimit > DEFAULT_TEXT_LIMIT; // Validate text length
        return useHtmlValidator ? Validate.htmlHasMinimumChars(responseValue, CHAR_LIMITS.NARRATIVE_MIN, isOptional) : Validate.hasMinimumChars(responseValue);
      }

      return true; // response is not nil, assumed to be ok
    });
  };

  validateSurveyQuestion(question: Model.EASurveyQuestion, responseGroup?: number, checkHasResponse?: boolean) {
    const isOptional = question.is_optional && !checkHasResponse;
    if ([QUESTION_TYPES.TEXT, QUESTION_TYPES.NUMBER, QUESTION_TYPES.CURRENCY, QUESTION_TYPES.PERCENT, QUESTION_TYPES.RADIO].includes(question.question_type)
      || (question.question_type === QUESTION_TYPES.DROPDOWN && question.dropdown_type === DROPDOWN_TYPES.SINGLE)) {
      return this.baseValidator({ questionId: question.id, group_id: question.group_id, response_group: responseGroup }, isOptional, question.question_type, question.text_limit, checkHasResponse);
    } else if (SURVEY_UTILS.isMultiSelectQuestion(question)) {
      if (isOptional) {
        return true;
      }

      // Find at least one response for the available options
      return question.response_options.some(choice => {
        const response = this.surveyResponses.find(response => response.question_id === question.id && response.response_option_id === choice.id);
        return !!SURVEY_UTILS.findResponse(response);
      });
    } else if (question.question_type === QUESTION_TYPES.TABLE) {
      const responseKeys: Array<{ key: {
        questionId: number,
        group_id?: number,
        response_group?: number,
        responseOptionId?: number,
        tableRowId?: number,
        tableColumnId?: number
      }, question_type: string }> = [];

      question.table_rows.forEach(row => {
        question.survey_table_columns.forEach(column => {
          responseKeys.push({
            key: { questionId: question.id, group_id: question.group_id, response_group: responseGroup, tableRowId: row.id, tableColumnId: column.id },
            question_type: column.question_type
          });
        });
      });

      // If checkHasResponse is true, we don't want to validate everything, just check if a response exists
      const testMethod = checkHasResponse ? 'some' : 'every';

      return responseKeys[testMethod](({ key, question_type }) => {
        return this.baseValidator(key, isOptional, question_type, question.text_limit, checkHasResponse);
      });
    } else if (question.question_type === QUESTION_TYPES.FILE) {
      if (isOptional) { return true; }
      const response = this.surveyResponses.find(response => response.question_id === question.id && response.response_group === (responseGroup || null));
      return response && this.files.some(file => file.effort_area_id === response.id);
    }
  }

  hasResponse(question, responseGroup?) {
    if (question.question_type === QUESTION_TYPES.GROUP) {
      const groupQuestionId = question.id;
      const questionsWithinGroup = this.surveyQuestions.filter(q => q.group_id === groupQuestionId && !q.follow_up_to);
      const responseGroups = uniq(this.surveyResponses.filter(response => response.group_id === groupQuestionId).map(response => response.response_group));

      return responseGroups.some(responseGroup =>
        questionsWithinGroup.some(q => this.hasResponse(q, responseGroup))
      );
    }

    return this.validateSurveyQuestion(question, responseGroup, true);
  }

  validateQuestionGroup(group: Model.EASurveyQuestion) {
    // If there are no response groups with at least one answer, it's valid if optional, invalid if required
    if (!this.hasResponse(group)) {
      return group.is_optional;
    }

    const responses = this.surveyResponses.filter(response => response.group_id === group.id);
    const responseGroups = uniq(responses.map(response => response.response_group));
    const questionsWithinGroup = this.surveyQuestions.filter(question => question.group_id === group.id && !question.follow_up_to);

    return responseGroups.every(responseGroup => {
      return questionsWithinGroup.every(question => {
        if (!this.validateSurveyQuestion(question, responseGroup)) {
          return false;
        }

        const followUpQuestions = this.surveyQuestions.filter(q => q.group_id === group.id && q.follow_up_to === question.id);
        return !followUpQuestions.length || followUpQuestions.every(followUp => {
          let doValidate = false;
          if (SURVEY_UTILS.isMultiSelectQuestion(question)) {
            const choice = question.response_options.find(choice => choice.title === followUp.if_answer_is);
            if (choice) {
              doValidate = this.surveyResponses.some(response =>
                response.question_id === question.id &&
                response.group_id === group.id &&
                response.response_group === responseGroup &&
                response.response_option_id === choice.id &&
                !!SURVEY_UTILS.findResponse(response));
            }
          } else {
            const response = this.surveyResponses.find(response =>
              response.question_id === question.id &&
              response.group_id === group.id &&
              response.response_group === responseGroup);

            doValidate = String(SURVEY_UTILS.findResponse(response)) === followUp.if_answer_is;
          }

          return !doValidate || this.validateSurveyQuestion(followUp, responseGroup);
        });
      });
    });
  }
}