<form [formGroup]="form">
  <input-group [headingText]="questionLabel" [control]="form" [required]="isRequired" [errorsOnUntouched]="surveyService.canEdit"
    id="checkbox_question_{{question.id}}_{{uniqueTag}}">
    <div *ngIf="helpText" class="help-text">
      <p [innerHtml]="helpText"></p>
    </div>

    <div [ngClass]="{ 'columns columns--2': displayAsColumns }">
      <ng-container [ngTemplateOutlet]="tableTemplate" [ngTemplateOutletContext]="{ rows: column1Rows }"></ng-container>
      <ng-container *ngIf="displayAsColumns" [ngTemplateOutlet]="tableTemplate" [ngTemplateOutletContext]="{ rows: column2Rows }"></ng-container>
    </div>
  </input-group>

  <ng-template #tableTemplate let-rows="rows">
    <table class="po-table">
      <thead>
        <tr class="align-headings--center">
          <th scope="col"></th>
          <th *ngFor="let column of question.survey_table_columns" scope="col">{{column.title}}</th>
        </tr>
      </thead>
      <tbody *ngIf="!!form">
        <tr *ngFor="let row of rows; trackBy: trackById">
          <th class="align-left">{{row.value}}</th>
          <td *ngFor="let column of question.survey_table_columns; trackBy: trackById">
            <ng-container [ngSwitch]="column.question_type">
              <input-field *ngSwitchCase="'text'" [labelHidden]="true" [labelText]="column.title" [canEdit]="surveyService.canEdit">
                <input inputRef id="question{{row.id}}_{{column.id}}_input_{{uniqueTag}}"
                  type="text"
                  formControlName="{{row.id}}_{{column.id}}"
                  (change)="persistResponse(row.id + '_' + column.id, 'text_response', { tableRowId: row.id, tableColumnId: column.id })"
                  [required]="isRequired" [attr.aria-required]="isRequired">
              </input-field>

              <input-field *ngSwitchCase="'percent'" [labelHidden]="true" [labelText]="column.title" [canEdit]="surveyService.canEdit">
                <input inputRef id="question{{row.id}}_{{column.id}}_input_{{uniqueTag}}"
                  type="text"
                  transform="percent"
                  formControlName="{{row.id}}_{{column.id}}"
                  (change)="persistResponse(row.id + '_' + column.id, 'int_response', { tableRowId: row.id, tableColumnId: column.id })"
                  [required]="isRequired" [attr.aria-required]="isRequired">
              </input-field>

              <input-field *ngSwitchCase="'currency'" [labelHidden]="true" [labelText]="column.title" [canEdit]="surveyService.canEdit">
                <input inputRef id="question{{row.id}}_{{column.id}}_input_{{uniqueTag}}"
                  type="text"
                  integerCurrency
                  placeholder="$"
                  formControlName="{{row.id}}_{{column.id}}"
                  (change)="persistResponse(row.id + '_' + column.id, 'int_response', { tableRowId: row.id, tableColumnId: column.id })"
                  [required]="isRequired" [attr.aria-required]="isRequired"/>
              </input-field>

              <input-field *ngSwitchCase="'number'" [labelHidden]="true" [labelText]="column.title" [canEdit]="surveyService.canEdit">
                <input inputRef id="question{{row.id}}_{{column.id}}_input_{{uniqueTag}}"
                  type="number"
                  formControlName="{{row.id}}_{{column.id}}"
                  (change)="persistResponse(row.id + '_' + column.id, 'int_response', { tableRowId: row.id, tableColumnId: column.id })"
                  [required]="isRequired" [attr.aria-required]="isRequired"/>
              </input-field>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
</form>
