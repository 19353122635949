<ng-container [ngSwitch]="!!cardless">
  <ng-container *ngSwitchCase="false">
    <div class="card project-card__collapsible" [ngClass]="collapsed ? 'project-card__collapsible--collapsed' : 'project-card__collapsible--open'">
      <ng-container *ngTemplateOutlet="mainContent"></ng-container>
    </div>

    <div *ngIf="systemRoles?.length" class="card">
      <ng-container *ngTemplateOutlet="systemRolesContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="true">
    <ng-container *ngTemplateOutlet="mainContent"></ng-container>
    <ng-container *ngIf="systemRoles?.length" [ngTemplateOutlet]="systemRolesContent"></ng-container>
  </ng-container>
</ng-container>


<ng-template #mainContent>
  <div [ngClass]="{ 'columns columns--2 no-margin' : collapsed, 'card-title' : !collapsed && !cardless }">
    <h3>{{headerText}}</h3>

    <p *ngIf="collapsed && !isValid" [@fadeInOut] class="bold status-message__message__fail margin-right align-right">
      <icon iconId="alert" class="icon--negative icon--vertical-align" title="Additional contacts required"></icon>
      Additional contacts required
    </p>
  </div>

  <button type="button" *ngIf="collapsible && !cardless"
    class="no-print action-button action-button--tertiary project-card__collapsible__trigger"
    attr.aria-controls="contact-table-card"
    [title]="collapsed ? 'Show Contacts' : 'Hide Contacts'"
    (click)="toggleCollapsed()">
    <icon iconId="disclosure-down"></icon>
  </button>

  <div id="contact-table-card" *ngIf="!collapsed" [@collapsible]>
    <!-- Required Contacts List (don't show in preview mode)-->
    <div *ngIf="!isPreview && requiredContacts?.length">
      <div class="margin-bottom-sm">
        <strong>Required Contacts</strong>
        <ng-container *ngIf=requiredSubtitle> {{ requiredSubtitle }}</ng-container>
      </div>

      <ul class="no-padding">
        <ol *ngFor="let contactType of requiredContacts" class="no-margin no-padding required"
        [attr.data-invalid]="contactRequirementComplete(contactType) === false">
          <ng-container [ngSwitch]="contactRequirementComplete(contactType)">
            <icon *ngSwitchCase="true" iconId="check-circle"
              aria-label="Requirement Satisfied" title="Requirement Satisfied"
              class="icon--success icon--vertical-align margin-right-xxs">
            </icon>
            <icon *ngSwitchCase="false" iconId="alert"
              aria-label="Additional contacts required" title="Additional contacts required"
              class="icon--negative icon--vertical-align margin-right-xxs">
            </icon>
          </ng-container>
          <span class="bold">{{contactType.roleName}}</span>
          <ng-container *ngIf="contactType.roleNote"> {{ contactType.roleNote }}</ng-container>
          <ng-container *ngIf="!hideNumberRequirement"> {{getContactRequirementString(contactType)}}</ng-container>
        </ol>
      </ul>
    </div>

    <!-- Optional Contacts List (don't show in preview mode)-->
    <div *ngIf="!isPreview && optionalContacts?.length">
      <h5>Optional Contacts</h5>
      <ul class="no-padding">
        <ol *ngFor="let contactType of optionalContacts" class="no-margin no-padding">
          <span class="bold">{{contactType.roleName}}</span>
          <ng-container *ngIf="contactType.roleNote"> {{ contactType.roleNote }}</ng-container>
        </ol>
      </ul>
    </div>

    <table *ngIf="contacts?.length; else empty" class="po-table" id="{{headerText}}_contact_table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Responsibility</th>
          <th scope="col">Institution</th>
          <th scope="col">Email Address</th>
          <th scope="col">Phone Number</th>
          <th *ngIf="canEdit" scope="col" class="col-1-rem">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of contacts; let i=index">
          <td>
            <app-user-actions
              [count]="i"
              [menuText]="getUserFullName(contact.user)"
              [userData]="contact.user"
              class="contacts__contact__options">
            </app-user-actions>
          </td>
          <td>{{contactRoleName(contact)}}</td>
          <td>{{contactInstitutionName(contact)}}</td>
          <td><a href="mailto:{{contactEmail(contact)}}" target="_blank" class="text-link">{{contactEmail(contact)}}</a></td>
          <td>{{contactPhone(contact)}}</td>
          <td *ngIf="canEdit" class="align-center">
            <!-- Action Buttons -->
            <div *ngIf="canDeleteContact(contact)" class="inline-button-group__container">
              <button class="inline-button action-button action-button--small action-button--tertiary icon-only"
                title="Remove Contact" (click)="removeContactAlert(contact)">
                <icon iconId="close"></icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="canEdit" class="card-footer card-footer--right">
      <button class="action-button action-button--secondary action-button--small icon--left"
        type="button" (click)="setAddModalStep(1)">
        <icon iconId="plus"></icon>
        Add Contact
      </button>
    </div>
  </div>
</ng-template>

<ng-template #systemRolesContent >
  <div class="card-title">
    <h3 class="h3">System Contacts</h3>
    <div *ngIf="!isPreview && showSystemHelperText" class="help-text">
      <p>
        If no user has been set to any of the following roles, or if the user listed is incorrect, please contact
        <a id="qa_contacts_help_link" href="/help" target="_blank" class="text-link">NOVA support</a>
        to set the roles.
      </p>
    </div>
  </div>

  <div class="contacts">
    <div *ngFor="let role of systemRoles" class="contacts__contact" [attr.data-invalid]="role.allowAssignment && !role.users?.length">
      <h4 class="contacts__contact__heading">{{role.roleName}}</h4>

      <div *ngFor="let user of role.users; let i=index" class="flex">
        <div *ngIf="canEdit && role.allowAssignment" class="inline-button-group__container flex--align-items-center">
          <button class="inline-button action-button action-button--small action-button--tertiary icon-only margin-left-sm"
            title="Remove Contact" (click)="removeSystemRoleAlert(user, role)">
            <icon iconId="close"></icon>
          </button>
        </div>

        <p>
          <strong>
            <app-user-actions
              [count]="-1-i"
              [menuText]="getUserFullName(user)"
              [userData]="user"
              class="contacts__contact__options">
            </app-user-actions>
          </strong>
          <ng-container *ngIf="user.title">{{user.title}}<br></ng-container>
          <ng-container *ngIf="user.email_address">
            <a href="mailto:{{user.email_address}}" class="text-link" id="qa_contact_{{role.roleName}}_email_{{user.id}}">
              {{user.email_address}}</a><br>
          </ng-container>
          <ng-container *ngIf="user.phone">
            {{ user.phone | phone }} {{ user.phone_extension | phone_extension }}
          </ng-container>
        </p>
      </div>

      <div *ngIf="!role.users?.length" class="padding">
        <p class="empty-state">No users have been assigned to this role.</p>
      </div>

      <div *ngIf="canEdit && role.allowAssignment" class="align-center margin-bottom-sm">
        <button class="action-button action-button--secondary action-button--small icon--left"
          (click)="openAddSystemRole(role.roleId)">
          <icon iconId="plus"></icon>
          Add Contact
        </button>
      </div>
    </div>
  </div>
</ng-template>

<full-page-modal *ngIf="showModalStep1"
  [small]="modalSmall"
  heading="Add Contact"
  button-action="Next"
  button-cancel="Cancel"
  [button-action-disabled]="!form?.get('role').value"
  (action)="setAddModalStep(2)"
  (dismiss)="setAddModalStep()"
  (cancel)="setAddModalStep()">

  <div class="help-text">
    <p>Select the role that you'd like to assign from the dropdown list below, then click 'Next'.</p>
  </div>

  <form [formGroup]="form">
    <po-select id="{{headerText}}_add_contact_role_select"
      labelText="Responsibility"
      [options]="roleOptions"
      [selectOnce]="true"
      (optionSelected)="updateRoleData()">
      <select inputRef formControlName="role" class="po-select__select" aria-required="true" required></select>
    </po-select>

    <div *ngIf="roleData">
      <h6>Permissions</h6>
      <ng-container [ngSwitch]="!!roleData?.permissions?.length">
        <ul *ngSwitchCase="true" class="unordered-list">
          <li *ngFor="let permission of roleData.permissions">
            <strong>{{permission.name}}</strong>: {{permission.actionsJoined}}
          </li>
        </ul>

        <p *ngSwitchDefault class="empty-state">None have been assigned</p>
      </ng-container>
    </div>
  </form>
</full-page-modal>

<app-add-role-users-modal *ngIf="showModalStep2"
  headerText="Add Role to User(s)"
  cancelButtonText="Go Back"
  [roleData]="roleData"
  [selectedFund]="program"
  [institutionOptions]="filteredInstitutions"
  [existingContacts]="contacts"
  (closeAction)="setAddModalStep()"
  (cancelAction)="setAddModalStep(1)"
  (addSuccessAction)="assignRoles($event)">
</app-add-role-users-modal>

<alert *ngIf="!!contactToRemove"
  level="warning"
  button-action="Remove"
  button-dismiss="Cancel"
  (action)="removeContact()"
  (dismiss)="removeContactAlert()">
  <p>
    Are you sure you want to remove
    <strong>{{getUserFullName(contactToRemove.user)}}</strong>
    ({{contactRoleName(contactToRemove)}})?
  </p>
</alert>

<alert *ngIf="!!systemRoleToRemove"
  level="warning"
  button-action="Remove"
  button-dismiss="Cancel"
  (action)="removeSystemRole()"
  (dismiss)="systemRoleToRemove=null;">
  <p>
    Are you sure you want to remove
    <strong>{{getUserFullName(systemRoleToRemove.user)}}</strong>
    ({{systemRoleToRemove.roleType?.roleName}})?
  </p>
</alert>

<ng-template #empty>
  <p class="empty-state">No contacts have been set.</p>
</ng-template>
