import {Component, Input, Output, forwardRef, AfterContentInit, OnDestroy, EventEmitter, ElementRef, Injector, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {CHAR_LIMITS, COMMENT_ID_CLASSES, FROALA_HIGHLIGHT_CLASS} from '@app-consts';
import {environment} from '../../../../environments/environment';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import FroalaEditor from 'froala-editor';
import Tribute from 'tributejs';
import { ApiService, CommentService, LookupService } from '@app/core/services';

import { Store } from '@ngrx/store';
import { State, Queries, Actions } from '@app-ngrx-domains';

@Component({
  selector: 'po-html-textarea',
  templateUrl: './html-text.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlTextComponent),
      multi: true
    }
  ]
})
export class HtmlTextComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @Input() enableParagraphFormat = false;
  @Input() enableCodeView = false;
  @Input() enableCharCounter = true;
  @Input() htmlVariables = false;
  @Input() htmlInputHeight: number;
  @Input() htmlInputMaxHeight: number;
  @Input() htmlCharLimit = CHAR_LIMITS.EXTRA_LONG;
  @Input() toggleToolbar = true;
  @Input() hideToolbar = false;
  @Input() disableComments = false;
  @Input() placeholderText = '';
  @Input() setFocus = false;
  @Input() isCommentField = false;
  @Input() enableMentions = false;
  @Output() change = new EventEmitter();
  @Output() blur = new EventEmitter();

  // Enables editor focus when when clicking on the field's label
  @HostListener('body:click', ['$event.target.id'])
  onClick(labelId: string) {
    if (this.froalaElement && this.hostElement && labelId === this.hostElement.id + '_label') {
      this.froalaElement.focus();
    }
  }

  tributeInstance;
  froalaOptions = {};
  froalaModel: any;

  currentUserId: number = 0;
  isOpen = false;
  isCommentsOpen = false;
  previousSelectedText = '';

  private destroy$: Subject<boolean> = new Subject();
  private froalaEditor: any;
  private froalaElement: HTMLElement;
  private hostElement: HTMLInputElement;
  private hostControl: any;
  private isDisabled: boolean = false;

  constructor(
    private el: ElementRef,
    private injector: Injector,
    private renderer: Renderer2,
    private apiService: ApiService,
    private commentService: CommentService,
    private store: Store<State>,
  ) {
    this.hostElement = this.el.nativeElement;
  }

  public async searchUsers(filter: string) {
    try {
      const result = await this.apiService.listProfiles({ match_strings: filter, limit: 15 }).toPromise();
      return result.users.map(user => ({ key: `${user.first_name} ${user.last_name}`, email: user.email_address, value: user.id }))
    } catch (err) {
      return [];
    }
  }

  // *** Begin ControlValueAccessor methods.
  onChange = (_: any) => {};
  onTouched = () => {};
  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }

  /**
   * Update froala model with form content.
   * @param content
   */
  writeValue(content: any): void {
    this.froalaModel = content;
  }

  /**
   * Disables or enables control.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    if (this.froalaEditor) {
      if (isDisabled) {
        this.froalaEditor.edit.off();
      } else {
        this.froalaEditor.edit.on();
      }
    }
  }

  // *** End ControlValueAccessor methods.

  ngAfterContentInit() {
    // use injector, otherwise will get cyclic dependency error.
    this.hostControl = this.injector.get(NgControl);
    this.hostControl.statusChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.toggleFroalaValidation();
    });

    this.store.select(Queries.Auth.getCurrentUser).pipe(
      takeUntil(this.destroy$)
    ).subscribe(cu => {
      this.currentUserId = cu && cu.id || undefined;
    });

    this.store.select(Queries.Layout.isCommentsOpen).pipe(
      takeUntil(this.destroy$)
    ).subscribe(isOpen => {
      this.isCommentsOpen = isOpen;
    });

    this.setFroalaOptions();

    if (this.htmlVariables) {
      this.createFroalaButtons();
    }

    this.commentService.textAreaCommentClick.pipe(
      skipWhile(val => !val),
      takeUntil(this.destroy$)
    ).subscribe(inputElementId => {
      if (inputElementId && inputElementId == this.hostElement.id) {
        // console.log(`textAreaCommentClick for ${inputElementId} caught in ${this.hostElement.id} `)
        if (!this.froalaEditor) {
          const timeOutVal = 150;
          console.warn(`textAreaCommentClick for ${inputElementId} caught in ${this.hostElement.id}. But froalaEditor not ready. Wait ${timeOutVal} ms and try again.`)
          setTimeout(() => {
            this.froalaEditor.commands.exec('comment');
          }, timeOutVal);

        } else {
          this.froalaEditor.commands.exec('comment');
        }
      }
    });

  }

  private setFroalaOptions() {
    const parent = this;

    // TODO - ran into issues using an icon from assets. Revisit later since that would be preferred
    // FroalaEditor.DefineIconTemplate(
    //   'po_icons',
    //   '<icon class="icon"><svg aria-hidden="true"><use xlink:href="assets/images/icons/svg-defs.svg#icon-[NAME]" href="assets/images/icons/svg-defs.svg#icon-[NAME]"></use></svg></icon>',
    // );
    // FroalaEditor.DefineIcon('message1', { NAME: 'message', template: 'po_icons'});

    FroalaEditor.DefineIcon('message', {
    NAME: 'message',
    PATH: 'M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z'
    });

    FroalaEditor.RegisterCommand('comment', {
      title: 'Comment',
      icon: 'message',
      false: true,
      focus: true,
      showOnMobile: true,
      refreshAfterCallback: true,
      callback: function (this) {
        const selectedText: string = this.selection.text().trim();
        if (!selectedText.length) {
          return;
        }

        const elementId = this.selection.element().closest('po-html-textarea').id;
        const creatorIdClass = parent.commentService.getCommentCreatorIdClass(parent.currentUserId);

        parent.commentService.setTextAreaCommentData(elementId, (commentId) => {
          const commentIdClass = parent.commentService.getCommentIdClass(commentId);
          this.inlineClass.apply(`${COMMENT_ID_CLASSES.DRAFT} ${creatorIdClass} ${commentIdClass}`);
        });
      },
      refresh: function ($btn) {
        // console.log (this.selection.element());
      }
    });

    const plugins = ['align', 'charCounter', 'link', 'lists', 'inlineClass'];
    if (this.enableParagraphFormat) {
      plugins.push('paragraphFormat');
    }
    if (this.enableCodeView) {
      plugins.push('codeView');
    }
    let placeholderText = '';
    if (this.placeholderText) {
      placeholderText = this.placeholderText;
    }
    // rich buttons
    const richButtons = ['insertLink', 'insertHR'];
    if (this.htmlVariables) {
      richButtons.push('customInsert')
    }

    const scrollAnchor = document.querySelector('.content-body__container');

    if (this.enableMentions) {
      this.tributeInstance = new Tribute({
        values: (text, cb) => {
          this.searchUsers(text).then(users => {
            cb(users);
          });
        },
        containerClass: 'tribute-container',
        positionMenu: true,
        replaceTextSuffix: ' ',
        menuShowMinLength: 1,
        allowSpaces: true, // find a way to hide after 2 spaces entered?
        menuItemTemplate: function(item) {
          return `
            <div class="mentions-option">
              <div class="mentions-option-name">${item.string}</div>
              <div class="mentions-option-email">${item.original['email']}</div>
            </div>
          `;
        },

        // Custom template for "selected" items
        selectTemplate: function (item) {
          return `<span data-mention-id="${item.original['value']}" class="fr-deletable user-mention" contenteditable="false">${item.original['key']}</span>`;
        },
        noMatchTemplate: function () {
          return `<span class="margin-sm">No match found.</span>`;
        }
      });
    }

    const options = {
      key: environment.froalaKey,
      attribution: false,  // turn off  "Powered By Froala message"
      pastePlain: true,
      heightMin: this.htmlInputHeight,
      heightMax: this.htmlInputMaxHeight,
      theme: 'po',
      placeholderText: placeholderText,
      charCounterMax: this.htmlCharLimit,
      charCounterCount: this.enableCharCounter, // show char counter
      paragraphFormatSelection: this.enableParagraphFormat ? true : false,
      pluginsEnabled: plugins,

      toolbarButtons: this.enableParagraphFormat
        ? [['bold', 'italic', 'underline'], ['paragraphFormat', 'outdent', 'indent', 'align', 'formatOL', 'formatUL'], richButtons, ['clearFormatting', 'html']]
        : [['bold', 'italic', 'underline'], ['align', 'formatOL', 'formatUL'], richButtons, ['clearFormatting', 'html']],
      linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
      events: {
        'initialized': function () {
          const editor = this;
          parent.froalaEditor = editor;
          parent.froalaElement = editor.el;
          editor.el.setAttribute('aria-labelledby', parent.hostElement.id + '_label'); // For screen-reader
          if (parent.hideToolbar) {
            parent.showFroalaToolbar(false);
          }

          if (parent.enableMentions) {
            // should only be set true when the editor is for a comment field
            parent.showFroalaToolbar(false);

            // Prevent scrolling while tribute menu is open
            parent.froalaElement.addEventListener('tribute-active-true', () => {
              parent.renderer.addClass(scrollAnchor, 'scroll-disabled');
            });

            parent.froalaElement.addEventListener('tribute-active-false', () => {
              parent.renderer.removeClass(scrollAnchor, 'scroll-disabled');
            });

            parent.tributeInstance.attach(editor.el);
            editor.events.on('keydown', function (e) {
              if (e.which == FroalaEditor.KEYCODE.ENTER && parent.tributeInstance.isActive) {
                return false;
              }
            }, true);
          }

          // https://froala.com/wysiwyg-editor/examples/external-button
          // editor.events.bindClick(editor.$('body'), `button#${parent.hostElement.id}_comment_widget_button`, function () {
          //   editor.commands.exec('comment');
          // });

          const debounce = (callback: Function, waitTime = 10) => {
            let timer = 0;
            return (...args: any) => {
              clearTimeout(timer);
              timer = setTimeout(() => {
                callback(...args);
              }, waitTime);
            };
          };

          const handleMouseup = (event: Event) => {
            if (!parent.disableComments && !parent.isCommentField) {
              const selectedText = editor.selection.text().trim();
              let closestHighlightSpan = editor.selection.element().closest(`.${FROALA_HIGHLIGHT_CLASS}`);
              const commentCreatorIdClass = parent.commentService.getCommentCreatorIdClass(parent.currentUserId);
              const commentIdClassPrefix = parent.commentService.getCommentIdClass();
              if (!closestHighlightSpan) {
                // check for a draft instead
                closestHighlightSpan = editor.selection.element().closest(`.${commentCreatorIdClass}`);
              }

              if (closestHighlightSpan) {
                const classString = closestHighlightSpan.className;
                const regExString = `(?:[^ ]+ )?${commentIdClassPrefix}(\\d+)`;
                const commentIdRegEx = new RegExp(regExString);
                const matches = classString.match(commentIdRegEx);
                if (matches && matches[1]) {
                  const commentId = parseInt(matches[1]);
                  if (commentId && !Number.isNaN(commentId)) {
                    if (!parent.isCommentsOpen) {
                      parent.store.dispatch(Actions.Layout.setCommentsOpen(true));
                    }
                  }
                }
              } else if (parent.isCommentsOpen && selectedText.length) {
                // display highlight widget and save selectedText for later
                parent.commentService.setShowCommentWidget({id: parent.hostElement.id, isTextArea: true});
              } else if (parent.isCommentsOpen && parent.previousSelectedText.length && !selectedText.length) {
                parent.commentService.setHideCommentWidget({id: parent.hostElement.id});
              }
              parent.previousSelectedText = selectedText;
            }
          };

          const debounceMouseUpHandler = debounce(handleMouseup, 100);
          parent.hostElement.addEventListener('mouseup', debounceMouseUpHandler);

          parent.showFroalaToolbar(false);
          if (parent.isDisabled) {
            editor.edit.off();
          } else {
            parent.toggleFroalaValidation();
          }
        },
        'contentChanged': function () {
          const value = this.html.get();
          parent.onChange(value);
          if (parent.change) {
            parent.change.emit(value);
          }
        },
        'focus': function () {
          parent.showFroalaToolbar(true);
        },
        'blur': function (event) {
          // toolbar closing can interfere with clicking on the comment widget
          const timeOutVal = parent.isCommentsOpen ? 150 : 0;
          setTimeout(() => {
            parent.showFroalaToolbar(false);
          }, timeOutVal);
          if (parent.blur) {
            const value = this.html.get();
            parent.blur.emit(event);
          }
        },
      }
    };

    this.froalaOptions = options;
  }

  private createFroalaButtons() {
    FroalaEditor.DefineIcon('customInsertIcon', { NAME: 'plus'});

    FroalaEditor.RegisterCommand('customInsert', {
      title: 'Insert',
      type: 'dropdown',
      icon: 'customInsertIcon',
      options: {
        'fullName': 'Full Name',
        'firstName': 'First Name',
        'lastName': 'Last Name'
      },
      focus: false,
      refreshAfterCallback: true,
      callback: function (cmd, value, params) {
        let htmlValue = '';
        switch (value) {
          case 'fullName':
            htmlValue = 'user.full_name';
            break;
          case 'firstName':
            htmlValue = 'user.first_name';
            break;
          case 'lastName':
            htmlValue = 'user.last_name';
            break;
          default:
            break;
        }

        this.html.insert(`{{${htmlValue}}}`);
      },
    })
  }

  private toggleFroalaValidation() {
    if (!this.hostControl || !this.froalaElement) {
      return;
    }

    if (this.hostControl.control.valid) {
      this.froalaElement.classList.remove('ng-invalid');
    } else {
      this.froalaElement.classList.add('ng-invalid');
    }
  }

  private showFroalaToolbar(show: boolean) {
    if (this.froalaEditor && this.toggleToolbar) {
      try {
        if (this.hideToolbar || !show) {
          this.froalaEditor.toolbar.hide();
        } else if (show) {
          this.froalaEditor.toolbar.show();
        }
      } catch (err) {
        // Possible froala error if component is being destroyed while attempting to hide toolbar
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
