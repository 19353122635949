import { ModelBase } from './model.base';
import { Model } from '@app-ngrx-domains';
import { AREAS, FUND_SETTINGS, FUND_TYPES, PROGRAM_KEYS, PROPOSAL_TYPES, STATE_TYPES, STATES, TASK_TYPES } from '../consts';
import { Duration } from './duration';
import { Utilities } from './utilities';
import { get, uniq } from 'lodash';
import { Allocation } from './allocation';
import { formatCurrency } from '@angular/common';
import { PERKINS_1C_2025 } from '@app/Perkins/Perkins-1C/consts';

export interface IProposalBase {
  id: number | null;
  deleted: boolean;
  fund_ids: Array<number>;
  funds?: Array<Model.Fund>;
  project_type: number;
  title: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  creator_id: number;
  creator?: any;
  lead_institution?: any;
  institutions: Array<any>;
  statuses: Array<string>;
  states?: { [key: string]: Array<Model.ResourceState> };
  tasks: Array<Model.Task>;
  duration_id: number;
  year?: Model.Duration;
  plan_length: number;
  start_date?: string;
  end_date?: string;
  parent_id?: number;
  type?: string;
  clone_id?: number;
  cloned?: Model.ProposalBase;
  comments?: any;
}

export abstract class ProposalBase extends ModelBase implements IProposalBase {
  public id: number;
  public deleted: boolean;
  public fund_ids: Array<number>;
  public funds: Array<Model.Fund>;
  public project_type: number;
  public title: string;
  public description: string;
  public created_at: Date;
  public updated_at: Date;
  public lead_institution: any;
  public institutions: Array<any>;
  public creator_id: number;
  public creator: any;
  public duration_id: number;
  public year: Model.Duration;
  public plan_length: number;
  public start_date: string;
  public end_date: string;
  public tasks: Array<Model.Task>;
  public statuses: Array<string>;
  public states?: { [key: string]: Array<Model.ResourceState> };
  public parent_id: number;
  public type: string;
  public clone_id: number;
  public cloned: Model.ProposalBase;
  public comments: any;

  constructor(raw?: any) {
    super();

    if (!raw) {
      // nothing to hydrate
      return;
    }

    this.id = raw.id;
    this.deleted = raw.deleted;
    this.funds = raw.funds;
    this.fund_ids = raw.fund_ids;
    // if fund_ids are not defined, then build it from funds
    if (this.funds && !this.fund_ids) {
      this.fund_ids = this.funds.map(f => f.id);
    }
    // determine project type.
    this.project_type = this.fund_ids[0];
    this.title = raw.title;
    this.description = raw.description;
    this.lead_institution = raw.lead_institution;
    this.institutions = raw.institutions;
    this.creator = raw.creator;
    this.created_at = raw.created_at;
    this.updated_at = raw.updated_at;
    this.duration_id = raw.duration_id;
    this.year = raw.year;
    this.plan_length = raw.plan_length || 1;
    this.start_date = raw.start_date;
    this.end_date = raw.end_date;
    this.tasks = raw.tasks || [];
    this.statuses = raw.statuses; // deprecating this, don't use it more
    this.states = raw.states;
    this.parent_id = raw.parent_id;
    this.type = raw.type;
    this.clone_id = raw.clone_id;
    this.cloned = raw.cloned;
    this.comments = raw.comments;
  }

  static getProgramKey(p: IProposalBase): string {
    if (!p) { return; }

    if (p.type === PROPOSAL_TYPES.FISCAL_AGENT) {
      const fund = p.funds[0];
      const parentFundKey = fund.parent_key;

      if (parentFundKey === PROGRAM_KEYS.SWP_K12) {
        return PROGRAM_KEYS.FISCAL_AGENTS;
      } else {
        return PROGRAM_KEYS.SEP;
      }

    } else if (p.funds && p.funds.length) {
      const fund = p.funds[0];
      return fund.key === PROGRAM_KEYS.RSI
        ? fund.key
        : fund.parent_key || fund.key;
    }
  }

  static getModelKey(p: IProposalBase): string {
    if (p && p.funds.length) {
      return p.funds[0].key;
    }
  }

  /**
   * Returns state id's name.
   * @param state_id
   */
  static formatStateHeader(state_id: number): string {
    if (ProposalBase.isDraft(state_id)) {
      return 'Draft';
    } else {
      switch (state_id) {
        case STATE_TYPES.SUBMITTED:
          return 'Submitted';

        case STATE_TYPES.CERTIFIED:
          return 'Certified';

        case STATE_TYPES.REJECTED:
          return 'Rejected';

        case STATE_TYPES.CANCELED:
          return 'Canceled';

        case STATE_TYPES.CLOSE_REQUESTED:
          return 'Closure Requested';

        case STATE_TYPES.CLOSED:
          return 'Closed';

        case STATE_TYPES.EDITED:
          return 'Editing';

        case STATE_TYPES.EDIT_SUBMITTED:
          return 'Edits Submitted';

        default:
          return '???';
      }
    }
  }

  /**
   * Format the state header that appears on the header bar.
   * @param proposal
   */
  static formatProposalStateHeader(proposal: any): string {
    const result = ProposalBase.formatStateHeader(proposal.state_id);
    return result;
  }

  /**
   * Format the state name.
   * @param proposal
   */
  static stateName(proposal: any): string {
    return ProposalBase.formatStateHeader(proposal.state_id);
  }

  /**
   * Format the program year name, within the context of the project type.
   * @param proposal
   */
  static programYearName(proposal: any, longName = false): string {
    if (proposal.fund_ids.includes(FUND_TYPES.GP)) {
      if (proposal.year && FUND_SETTINGS[FUND_TYPES.GP].programYears[proposal.year.id]) {
        if (longName) {
          return (proposal.project_type === FUND_TYPES.GP) ? FUND_SETTINGS[FUND_TYPES.GP].programYears[proposal.year.id].longName : proposal.year.name;
        } else {
          return (proposal.project_type === FUND_TYPES.GP) ? FUND_SETTINGS[FUND_TYPES.GP].programYears[proposal.year.id].name : proposal.year.name;
        }
      } else {
        return '';
      }
    } else {
      return proposal.year ? proposal.year.name : proposal.duration_id;
    }
  }

  /**
   * Format the college header that appears on the header bar.
   * @param proposal
   */
  static formatProposalLeadCollege(proposal: any): string {
    if (proposal.project_type === FUND_TYPES.SWP_L) {
      return proposal.lead_institution ? (' : ' + proposal.lead_institution.name) : ' : Undefined College';
    } else if (proposal.project_type === FUND_TYPES.LVG) {
      return proposal.lead_institution ? proposal.lead_institution.name : 'Undefined College';
    } else {
      return '';
    }
  }

  /**
   * Format the header 1 that appears on the header bar.
   * @param proposal
   */
  static formatProposalTitleHeader(proposal: any): string {
    return proposal.title ? proposal.title : 'New Project';
  }

  /**
   * Format the header 2 that appears on the header bar.
   * @param proposal
   */
  static formatProposalAuthorHeader(proposal: any): string {
    let authorHeader = 'Created';
    if (proposal.creator && proposal.creator.first_name && proposal.creator.last_name) {
      authorHeader += ` by ${proposal.creator.first_name} ${proposal.creator.last_name}`;
    }

    if (proposal.created_at) {
      authorHeader += ' on ' + Utilities.convertDateToString(proposal.created_at, false, true);
    }

    return authorHeader;
  }

  /**
   * Returns true if state is in draft mode.
   * @param {number} state_id
   * @returns {boolean}
   */
  static isDraft(state_id: number): boolean {
    return !state_id || state_id === STATE_TYPES.DRAFT;
  }

  static isDraftOrEdit(state_id: number): boolean {
    return !state_id || state_id === STATE_TYPES.DRAFT || state_id === STATE_TYPES.EDITED;
  }

  static isSubmitted(state_id: number): boolean {
    return [STATE_TYPES.SUBMITTED, STATE_TYPES.EDIT_SUBMITTED].includes(state_id);
  }

  static isCertified(state_id: number): boolean {
    return state_id === STATE_TYPES.CERTIFIED;
  }

  static isClosed(state_id: number): boolean {
    return state_id === STATE_TYPES.CLOSED;
  }

  /**
   * Returns proposal's title/name according to the project type.
   * @static
   * @param {(ProposalBase | Model.ProposalBase)} p
   * @returns {string}
   */
  static title(p: ProposalBase | Model.ProposalBase): string {
    return p.title;
  }

  /**
   * Returns proposal's cycle years name for Fiscal Reporting.
   * @static
   * @param {(ProposalBase | Model.ProposalBase)} p
   * @param {string} fundKey
   * @returns {string}
   */
  static fiscalReportCycle(durationId: number, fundKey: string): string {
    if (fundKey === PROGRAM_KEYS.GP) {
      // Get the start and end years since the report is attached to the 4th year
      const endYear = durationId + 1;
      const startYear = endYear - 5;
      return `${startYear}-${endYear}`;
    }
    return '';
  }

  /**
   * Returns proposal's title/name for Fiscal Reporting.
   * @static
   * @param {(ProposalBase | Model.ProposalBase)} p
   * @param {string} fundKey
   * @returns {string}
   */
   static fiscalReportTitle(p: ProposalBase | Model.ProposalBase, fundKey: string): string {
    if (fundKey === PROGRAM_KEYS.GP) {
      const cycle = ProposalBase.fiscalReportCycle(p.duration_id, fundKey);
      const title = p.title.split("(")[0];
      return `${title} (${cycle})`;
    }
    return p.title;
  }

  /**
   * Returns routing link to proposal according to the project type.
   * @static
   * @param {(Proposal | Model.ProposalItem)} p
   * @param {string} [workflowStep]
   * @param {string} [useProgramKey]
   * @returns
   */
  static routerLink(p: ProposalBase | Model.ProposalBase, workflowStep?: string, useProgramKey?: string): string {
    const programKey = useProgramKey ? useProgramKey : ProposalBase.getProgramKey(p);
    const fund = get(p, 'funds[0]') || {};
    const projectType = (p.type === PROPOSAL_TYPES.APPLICATION) ? 'applications' : 'plans';
    let link = '';

    if (fund.is_small_program && ![PROGRAM_KEYS.SWP_K12_PC, PROGRAM_KEYS.SWP_K12_TAP, PROGRAM_KEYS.PERKINS_RESERVE, PROGRAM_KEYS.PERKINS_NTCT].includes(fund.key)) {
      link = `/${PROGRAM_KEYS.SMALL_PROGRAMS}/${programKey}/${projectType}/${p.id}`;
    } else {
      switch (programKey) {
        case PROGRAM_KEYS.CAEP:
          return `/${programKey}/consortia/${p.id}/${p.lead_institution.id}`;
        case PROGRAM_KEYS.SWP_K12: {
          if ([PROGRAM_KEYS.SWP_K12_PC, PROGRAM_KEYS.SWP_K12_TAP].includes(fund.key)) {
            link = `/${programKey}/tap-pc/${projectType}/${p.id}`;
          } else {
            link = `/${programKey}/${projectType}/${p.id}/${ProposalBase.version(p)}`;
          }
          break;
        }
        case PROGRAM_KEYS.RSI: {
          return `/${PROGRAM_KEYS.CAI}/${PROGRAM_KEYS.RSI}/${p.id}`;
        }
        case PROGRAM_KEYS.CAI: {
          link = `/${programKey}/${projectType}/${p.id}/${ProposalBase.version(p)}`;
          break;
        }
        case PROGRAM_KEYS.EWD:
        case PROGRAM_KEYS.RCM: {
          link = `/${programKey}/${projectType}/${p.id}`;
          break;
        }
        case PROGRAM_KEYS.PERKINS: {
          link = `/${programKey}/${fund.key}/${projectType}/${p.id}`;
          break;
        }
        case PROGRAM_KEYS.LVG: {
          link = `/${programKey}/goals/${p.id}`;
          break;
        }
        case PROGRAM_KEYS.NEP: {
          link = `/${programKey}/plans/${p.id}`;
          break;
        }
        case PROGRAM_KEYS.GP_1:
        case PROGRAM_KEYS.GP_2:
        case PROGRAM_KEYS.GP: {
          link = `/${PROGRAM_KEYS.GP}/proposals/${ProposalBase.version(p)}/${p.id}`;
          break;
        }
        case PROGRAM_KEYS.IPLAN: {
          link = `/${programKey}/proposals/${p.id}`;
          break;
        }
        case PROGRAM_KEYS.SEP: {
          link = `/${programKey}/plans/${p.id}/${ProposalBase.version(p)}`;
          break;
        }
        case PROGRAM_KEYS.SWP_L:
        case PROGRAM_KEYS.SWP_R: {
          link = `/${programKey}/proposals/${p.id}/${ProposalBase.version(p)}`;
          break;
        }
        default: {
          return '';
        }
      }
    }

    return (workflowStep) ? `${link}/${workflowStep}` : link;
  }

  static version(p: Model.ProposalBase): string {
    const programKey = ProposalBase.getProgramKey(p);
    const childProgramKey = p.funds[0].key;

    switch (programKey) {
      case PROGRAM_KEYS.SWP_K12:
        switch (childProgramKey) {
          case PROGRAM_KEYS.SWP_K12_v5:
            return 'v5';
          case PROGRAM_KEYS.SWP_K12_v4:
            return 'v4';
          case PROGRAM_KEYS.SWP_K12_v2:
            return 'v2';
          case PROGRAM_KEYS.SWP_K12_v1:
            return 'v1';
          default:
            return '';
        }

      case PROGRAM_KEYS.SWP_L:
        switch (childProgramKey) {
          case PROGRAM_KEYS.SWPL_v3:
            return 'v3';
          case PROGRAM_KEYS.SWPL_v2:
            return 'v2';
          default:
            return 'v1';
        }

      case PROGRAM_KEYS.SWP_R:
        switch (childProgramKey) {
          case PROGRAM_KEYS.SWPR_v3:
            return 'v3';
          case PROGRAM_KEYS.SWPR_v2:
            return 'v2';
          default:
            return 'v1';
        }

      case PROGRAM_KEYS.CAI:
        return (!!p.funds[0].key && p.funds[0].key === PROGRAM_KEYS.RFA_v2) ? 'v2' :  'v1';

      case PROGRAM_KEYS.SEP:
        switch (childProgramKey) {
          case PROGRAM_KEYS.SEP_V3:
            return 'v3';
          case PROGRAM_KEYS.SEP_V2:
            return 'v2';
          default:
            return 'v1';
        }

      case PROGRAM_KEYS.GP:
        switch (childProgramKey) {
          case PROGRAM_KEYS.GP_2:
            return 'gp-v2';
          default:
            return 'gp-v1';
        }

      default:
        return '';
    }
  }

  static versionNumber(proposal: Model.ProposalBase) {
    const version = this.version(proposal);
    const versionNumber = version.replace(/.*v(\d*)$/i, '$1');
    return +versionNumber;
  }

  static closedState: string = 'Closed';
  static certifiedState: string = 'Certified';
  static draftState: string = 'Draft';

  ///////////////////////////////////////////////////////////////
  // Proposal type checks
  ///////////////////////////////////////////////////////////////
  static isApplication(proposal: Model.ProposalBase): boolean {
    return proposal.type === PROPOSAL_TYPES.APPLICATION;
  }

  static isPlan(proposal: Model.ProposalBase): boolean {
    return proposal.type === PROPOSAL_TYPES.PLAN;
  }

  static isProject(proposal: Model.ProposalBase): boolean {
    return !proposal.type;
  }

  ///////////////////////////////////////////////////////////////
  // Resource State functions - singular
  ///////////////////////////////////////////////////////////////

  /**
   * Returns the state_name of the specified area.
   */
  static getAreaState(proposal, area_name, duration_id?: number, institution_id?: number): string {
    const resources = get(proposal, 'states.' + area_name) || [];
    let state: Model.ResourceStateExtended;

    if (duration_id && institution_id) {
      state = resources.find(resource => resource.duration_id === duration_id && resource.institution_id === institution_id);
    } else if (duration_id) {
      state = resources.find(resource => resource.duration_id === duration_id);
    } else if (institution_id) {
      state = resources.find(resource => resource.institution_id === institution_id);
    } else {
      state = resources[0];
    }

    return get(state, 'state_name');
  }

  /**
   * Returns true if the  project has the specified area.
   */
  static hasAreaState(proposal, area_name, duration_id?: number, institution_id?: number): boolean {
    return !!ProposalBase.getAreaState(proposal, area_name, duration_id, institution_id);
  }

  static areaIsDraftOrEdit(proposal, area_name, duration_id?: number, institution_id?: number): boolean {
    const state = ProposalBase.getAreaState(proposal, area_name, duration_id, institution_id);
    return !!state && [STATES.DRAFT.name, STATES.EDITED.name].includes(state);
  }

  static areaIsSubmitted(proposal, area_name, duration_id?: number, institution_id?: number): boolean {
    const state = this.getAreaState(proposal, area_name, duration_id, institution_id);
    return !!state && [STATES.SUBMITTED.name, STATES.EDITS_SUBMITTED.name].includes(state);
  }

  static areaIsApproved(proposal, area_name, duration_id?: number, institution_id?: number): boolean {
    const state = this.getAreaState(proposal, area_name, duration_id, institution_id);
    return !!state && STATES.APPROVED.name === state;
  }

  ///////////////////////////////////////////////////////////////
  // Resource State functions - multi
  ///////////////////////////////////////////////////////////////
  static getAreaStates(proposal, area_name: string): Array<Model.ResourceStateExtended> {
    const areaStates = (proposal.states[area_name] || []) as Array<Model.ResourceStateExtended>;
    return areaStates;
  }

  ///////////////////////////////////////////////////////////////
  // Application States
  ///////////////////////////////////////////////////////////////
  /**
   * Returns whether a proposal is in the Application phase or not.
   * @param proposal
   */
  static hasStartedApplication(proposal, institution_id?: number): boolean {
    return !!ProposalBase.getAreaState(proposal, AREAS.APPLICATION, undefined, institution_id);
  }

  static getApplicationState(proposal, institution_id?: number ): string {
    return ProposalBase.getAreaState(proposal, AREAS.APPLICATION, undefined, institution_id);
  }

  static applicationIsDraft(proposal, institution_id?: number): boolean {
    return ProposalBase.areaIsDraftOrEdit(proposal, AREAS.APPLICATION, undefined, institution_id);
  }

  static applicationIsSubmitted(proposal, institution_id?: number): boolean {
    const state = this.getAreaState(proposal, AREAS.APPLICATION, undefined, institution_id);
    const submittedStates = [STATES.SUBMITTED.name, STATES.EDITS_SUBMITTED.name];
    if (proposal.duration_id < PERKINS_1C_2025) {
      submittedStates.push(STATES.SUBSTANTIALLY_APPROVED.name);
    }
    return !!state && submittedStates.includes(state);
  }

  static applicationIsApproved(proposal, institution_id?: number): boolean {
    const state = this.getAreaState(proposal, AREAS.APPLICATION, undefined, institution_id);
    const approvedStates = [STATES.APPROVED.name];
    if (proposal.duration_id >= PERKINS_1C_2025) {
      approvedStates.push(STATES.SUBSTANTIALLY_APPROVED.name);
    }
    return !!state && approvedStates.includes(state);
  }

  static applicationIsRejected(proposal): boolean {
    const state = this.getAreaState(proposal, AREAS.APPLICATION);
    return !!state && state === STATES.REJECTED.name;
  }

  ///////////////////////////////////////////////////////////////
  // Application Review States
  ///////////////////////////////////////////////////////////////
  static getReviewState(proposal): string {
    return ProposalBase.getAreaState(proposal, AREAS.REVIEW);
  }

  /**
   * Returns if reviewer has started the review, or if reviewer is not known,
   * if over-all review can begin.
   * @param proposal proposal
   */
  static hasStartedReview(proposal): boolean {
    return !!ProposalBase.getAreaState(proposal, AREAS.REVIEW);
  }

  ///////////////////////////////////////////////////////////////
  // Application Offer States
  ///////////////////////////////////////////////////////////////
  static getOfferState(proposal): string {
    return ProposalBase.getAreaState(proposal, AREAS.OFFER);
  }

  static hasStartedOffer(proposal): boolean {
    return !!ProposalBase.getAreaState(proposal, AREAS.OFFER);
  }

  static offerIsDraft(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.OFFER);
    return state === STATES.DRAFT.name;
  }

  static offerNotDraft(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.OFFER);
    return [STATES.OFFER_SENT.name, STATES.ACCEPTED.name, STATES.REJECTED.name].includes(state);
  }

  ///////////////////////////////////////////////////////////////
  // Plan States
  ///////////////////////////////////////////////////////////////
  static getPlanState(proposal): string {
    return ProposalBase.getAreaState(proposal, AREAS.PLAN);
  }

  static hasStartedPlan(proposal): boolean {
    return !!ProposalBase.getAreaState(proposal, AREAS.PLAN);
  }

  static planIsDraft(proposal): boolean {
    return ProposalBase.areaIsDraftOrEdit(proposal, AREAS.PLAN);
  }

  static planIsSubmitted(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PLAN);
    return state === STATES.SUBMITTED.name;
  }

  static planIsApproved(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PLAN);
    return state === STATES.APPROVED.name;
  }

  static planIsCertified(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PLAN);
    return state === STATES.CERTIFIED.name;
  }

  static planCloseRequested(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PLAN);
    return state === STATES.CLOSE_REQUESTED.name;
  }

  static planIsClosed(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PLAN);
    return state === STATES.CLOSED.name;
  }

  static getPlanRenewState(proposal): string {
    return ProposalBase.getAreaState(proposal, AREAS.RENEWAL);
  }

  static planIsRenewed(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.RENEWAL);
    return [STATES.RENEWED.name, STATES.NOT_RENEWED.name].includes(state);
  }

  ///////////////////////////////////////////////////////////////
  // Project States
  ///////////////////////////////////////////////////////////////
  static getProjectState(proposal): string {
    return ProposalBase.getAreaState(proposal, AREAS.PROJECT);
  }

  static hasStartedProject(proposal): boolean {
    return !!ProposalBase.getAreaState(proposal, AREAS.PROJECT);
  }

  // TODO: Consolidate the rest of these
  static projectIsDraft(proposal): boolean {
    return ProposalBase.areaIsDraftOrEdit(proposal, proposal.type || AREAS.PROJECT);
  }

  static projectIsSubmitted(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PROJECT);
    return [STATES.SUBMITTED.name, STATES.EDITS_SUBMITTED.name].includes(state);
  }

  static projectIsApproved(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PROJECT);
    return state === STATES.APPROVED.name;
  }

  static projectIsCertified(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, proposal.type || AREAS.PROJECT);
    return state === STATES.CERTIFIED.name;
  }

  static projectCloseRequested(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PROJECT);
    return state === STATES.CLOSE_REQUESTED.name;
  }

  static projectIsClosed(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.PROJECT);
    return state === STATES.CLOSED.name;
  }

  ///////////////////////////////////////////////////////////////
  // Report States
  ///////////////////////////////////////////////////////////////

  static getReportState(proposal): string {
    return ProposalBase.getAreaState(proposal, AREAS.MID_ALLOCATION_REPORT);
  }

  static reportIsDraft(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.MID_ALLOCATION_REPORT);
    return state === STATES.DRAFT.name;
  }

  static reportIsSubmitted(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.MID_ALLOCATION_REPORT);
    return state === STATES.SUBMITTED.name;
  }

  static reportIsCertified(proposal): boolean {
    const state = ProposalBase.getAreaState(proposal, AREAS.MID_ALLOCATION_REPORT);
    return state === STATES.CERTIFIED.name;
  }

  ///////////////////////////////////////////////////////////////
  // Generic States
  ///////////////////////////////////////////////////////////////

  static isAreaDraft(proposal: Model.ProposalBase, area: string, durationId?: number): boolean {
    const state = ProposalBase.getAreaState(proposal, area, durationId);
    return [STATES.DRAFT.name, STATES.EDITED.name].includes(state);
  }

  static isAreaSubmitted(proposal: Model.ProposalBase, area: string, durationId?: number): boolean {
    const state = ProposalBase.getAreaState(proposal, area, durationId);
    return [STATES.SUBMITTED.name, STATES.EDITS_SUBMITTED.name].includes(state);
  }

  static isAreaCertified(proposal: Model.ProposalBase, area: string, durationId?: number): boolean {
    const state = ProposalBase.getAreaState(proposal, area, durationId);
    return [STATES.CERTIFIED.name, STATES.APPROVED.name].includes(state);
  }

  static areaCloseRequested(proposal: Model.ProposalBase, area: string): boolean {
    const state = ProposalBase.getAreaState(proposal, area);
    return state === STATES.CLOSE_REQUESTED.name;
  }

  static isAreaClosed(proposal: Model.ProposalBase, area: string, durationId?: number): boolean {
    const state = ProposalBase.getAreaState(proposal, area, durationId);
    return state === STATES.CLOSED.name;
  }


  ///////////////////////////////////////////////////////////////
  // State Queries
  ///////////////////////////////////////////////////////////////
  static getSubmittedDurationIds(proposal: Model.ProposalBase, area: string, institution_id?: number): Array<number> {
    const durationIds = ProposalBase.getAreaStates(proposal, area)
      .filter(r => (!institution_id || r.institution_id === institution_id) && get(r, 'state_name') === STATES.SUBMITTED.name)
      .map(r => r.duration_id);
    return uniq(durationIds);
  }

  static getMinimumPlanLength(proposal: Model.ProposalBase): number {
    // get submitted durations - sort in descending order
    const durationIds = ProposalBase.getSubmittedDurationIds(proposal, TASK_TYPES.FISCAL_REPORT_SUBMIT).sort((a, b) => a > b ? -1 : 1);
    if (durationIds.length) {
      // durations are in quarters, get year from it
      const lastReportedYear = Duration.getPeriodFromDurationId(durationIds[0]).year;
      return lastReportedYear  - proposal.duration_id + 1;
    } else {
      return 1;
    };
  }

  static getRCLongestPlanLength(settings: Array<Model.EARCContributorSettings | Model.EARCContributorSnapshots>): number {
    if (settings.length === 0) {
      return 0;
    }

    const lengths: Set<number> = new Set();
    settings.forEach(settings => {
      if (settings.plan_length) {
        lengths.add(settings.plan_length)
      }
    });
    return Math.max(...lengths.values());
  }

  ///////////////////////////////////////////////////////////////
  // Application Review/Funding
  ///////////////////////////////////////////////////////////////
  /**
   * Returns list of reviewers from reviewer tasks.
   * @param p
   */
  static getReviewerTasks(p: IProposalBase): Array<Model.Task> {
    const reviewerTaskType = (p.funds[0].key === PROGRAM_KEYS.SWP_K12_v1)
      ? TASK_TYPES.SWPK_REVIEW_SUBMIT
      : TASK_TYPES.RFA_REVIEW_APPLICATION;
    return p.tasks.filter(task => task.task_type === reviewerTaskType && task.completed && !task.store.removed);
  }

  /**
   * Returns true if the reviewer was marked as excluded from average score calculation.
   * @param task
   */
  static reviewerWasExcluded(task: Model.Task): boolean {
    return task && task.client_state && task.client_state.excluded;
  }

  /**
   * Returns funding info associated with the application.
   * @param p
   */
  static applicationFundingInfo(p: IProposalBase, submitTaskType: string): Model.ProposalFundingInfo {
    const status = ProposalBase.getOfferState(p);
    const info = {
      proposal_id: p.id,
      title: p.title,
      status: status,
      status_display: status,
      lead_institution: p.lead_institution,
      client_state: {
        ada_total: null,
        requested_amount: null,
        score: null,
        rank: null,
        offered_amount: 0,
        accepted_amount: 0,
      },
    }

    const submitTask = (p.tasks || []).find(task => task.task_type === submitTaskType);
    if (submitTask) {
      info.client_state = {
        ...info.client_state,
        ...submitTask.client_state,
        ['task_id']: submitTask.id,
      };

      if (status === STATES.ACCEPTED.name) {
        // show type of acceptance.
        const allocationAmount = Allocation.getAllocationSum(p['plans_allocations']);
        const acceptedAmount = allocationAmount || info.client_state.accepted_amount; // Use the actual allocation amount rather than static value in task
        info.status_display = (info.client_state.offered_amount === acceptedAmount)
          ? 'Accepted: Offer Amount'
          : (info.client_state.requested_amount === acceptedAmount)
            ? 'Accepted: Requested Amount'
            : `Accepted: ${formatCurrency(acceptedAmount, 'en-US', '$', 'USD', '1.0')}`;
      } else if (status === STATES.DRAFT.name) {
        // show it as reviewed instead of draft
        info.status_display = STATES.REVIEWED.name;
      }
    }

    return info;
  }

  /**
   *  Returns metric definition ids selected as part of Vision Goals
   */
  static selectedMetricsDefinitions(p: Model.SWPProposal | Model.RCMProposal | Model.SPProposal | Model.EAThreeYearPlan): Array<number> {
    let metricDefIds = [];
    if (p.vision_goals) {
      p.vision_goals
        .filter(goal => goal.selected)
        .forEach(goal => {
          metricDefIds.push(...goal.success_metrics.map(attrib => attrib.value));
        });
      metricDefIds = (metricDefIds.length > 1) ? uniq(metricDefIds) : metricDefIds;
    }
    return metricDefIds;
  }

  static getEndYear(p: Model.ProposalBase) {
    return p.duration_id + (ProposalBase.getYearLength(p)) - 1;
  }

  static getYearLength(p: Model.ProposalBase) {
    if (p['quarterly_plan_length']) {
      return Math.ceil((p['start_quarter'] + p['quarterly_plan_length'] - 1) / 4);
    }
    return p.plan_length || 1;
  }
}

  /**
   * Adds models definitions to ngrx-domains table.
   */
  declare module '@app-ngrx-domains' {
    export namespace Model {
      export type ProposalBase = IProposalBase;
  }
}
