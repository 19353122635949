import { Component } from '@angular/core';
import { Actions, Model, Queries } from '@app-ngrx-domains';
import { SURVEY_UTILS } from '@app/shared.surveys/consts';
import { filter, map, takeUntil } from 'rxjs/operators';
import { SurveyQuestionComponent } from '../survey-question.component';

@Component({
  selector: 'multi-response-question',
  templateUrl: './multi-response-question.component.html'
})
export class MultiResponseQuestionComponent extends SurveyQuestionComponent {

  ngOnInit() {
    if (this.surveyService.isPreview) {
      this.setupAsPreview();
      return;
    }

    this.uniqueTag = this.formattedUniqueTag;

    const observable = this.surveyService.useCurrentProposalStore
      ? this.store.select(Queries.CurrentProposal.get)
      : this.store.select(Queries.Proposals.getAll).pipe(map(proposals => { return proposals.find(p => p.id === this.surveyService.proposalId) || null }));

    observable.pipe(
      filter(p => p && !!p.id),
      takeUntil(this.destroy$)
    ).subscribe(proposal => {
      const allResponses = proposal // proposalId can be null when showing the survey questions as a template
        ? proposal.survey_responses ? [].concat(proposal.survey_responses) : [] // [].concat() ensures `allResponses` will always be an array
        : [];

      this.responses = allResponses.filter(response => {
        return ((this.surveyService.institutionId ? this.surveyService.institutionId === response.institution_id : true)
        && (this.surveyService.durationId ? this.surveyService.durationId === response.duration_id : true)
        && (this.surveyService.effortAreaId ? this.surveyService.effortAreaId === response.parent_effort_area_id : true)
        && (this.surveyService.fundId ? this.surveyService.fundId === response.fund_id : true)
        && (this.surveyService.userId ? this.surveyService.userId === response.user_id : true)
        && (this.question.id === response.question_id));
      });

      if (!this.responses.length) {
        this.addResponse();
      } else {
        if (!this.form) {
          this.buildForm();
          this.surveyService.registerQuestion({ id: this.question.id, form: this.form, getResponse: () => this.getValueForKey(this.question.id) });
        } else {
          this.manageFormControls();
        }
      }
    });
  }

  buildForm() {
    const validators = this.validatorsForQuestionType(this.question.question_type);
    const controls = this.responses.reduce((result, response) => {
      result[`${this.question.id}_response${response['temp_id'] || response.id}`] = [SURVEY_UTILS.findResponse(response), validators];
      return result;
    }, {});

    this.form = this._fb.group(controls);
  }

  manageFormControls() {
    const validators = this.validatorsForQuestionType(this.question.question_type);
    this.responses.forEach(response => {
      const controlName = `${this.question.id}_response${response['temp_id'] || response.id}`;
      if (!this.form.get(controlName)) {
        this.form.addControl(controlName, this._fb.control(SURVEY_UTILS.findResponse(response), validators));
      }
    });
  }

  setupAsPreview() {
    // Seed a dummy response to display the question
    this.responses = [{
      id: -1,
      effort_area_type: 'survey_responses',
      parent_proposal_id: this.surveyService.proposalId,
      institution_id: this.surveyService.institutionId,
      duration_id: this.surveyService.durationId,
      parent_effort_area_id: this.surveyService.effortAreaId,
      fund_id: this.surveyService.fundId,
      user_id: this.surveyService.userId,
      question_id: this.question.id
    }];

    this.buildForm();
  }

  addResponse() {
    const payload = {
      ea: {
        effort_area_type: 'survey_responses',
        parent_proposal_id: this.surveyService.proposalId,
        institution_id: this.surveyService.institutionId || null,
        duration_id: this.surveyService.durationId || null,
        parent_effort_area_id: this.surveyService.effortAreaId || null,
        fund_id: this.surveyService.fundId || null,
        user_id: this.surveyService.userId || null,
        question_id: this.question.id
      }
    };

    if (this.surveyService.useCurrentProposalStore) {
      this.store.dispatch(Actions.CurrentProposal.createTempEffortArea(payload));
    } else {
      this.store.dispatch(Actions.Proposals.createTempEffortArea({ ...payload, proposal_id: this.surveyService.proposalId }));
    }
  }

  persistMultiResponse(responseType: string, responseId: number) {
    const responseEA = this.responses.find(response => responseId < 0 ? (response['temp_id'] === responseId) : (response.id === responseId));
    const value = this.form.get(`${this.question.id}_response${responseEA['temp_id'] || responseEA.id}`).value;

    const payload = { ea: responseEA, key: responseType, value };

    if (this.surveyService.useCurrentProposalStore) {
      this.store.dispatch(Actions.CurrentProposal.upsertAttribute(payload));
    } else {
      this.store.dispatch(Actions.Proposals.upsertAttribute({ ...payload, proposal_id: this.surveyService.proposalId }));
    }
  }

  deleteResponse(response: Model.EASurveyResponse) {
    if (this.surveyService.useCurrentProposalStore) {
      this.store.dispatch(Actions.CurrentProposal.deleteEffortArea({ ea: response }));
    } else {
      this.store.dispatch(Actions.Proposals.deleteEffortArea({ ea: response, proposal_id: this.surveyService.proposalId }));
    }
  }

  trackById(index: number, response: Model.EASurveyResponse) {
    return response.temp_id || response.id;
  }
}
