<form [formGroup]="form">
  <input-group [headingText]="questionLabel" [control]="form" [required]="isRequired" [errorsOnUntouched]="surveyService.canEdit && form.invalid"
  id="file_question_{{question.id}}_{{uniqueTag}}">
  <div *ngIf="helpText" class="help-text">
      <p [innerHTML]="helpText"></p>
    </div>

    <app-document-table
      [fileList]="fileList"
      [columnsToShow]="documentColumns"
      [enableUpload]="surveyService.canEdit"
      [canDeleteFn]="canRemoveFile"
      [emptyTemplate]="emptyDocuments"
      [formatDocTitleFn]="formatDocTitle"
      [noMargin]="true"
      (addDocumentClicked)="showUploadModal=true"
      (deleteDocumentClicked)="removeFile($event)">
    </app-document-table>
  </input-group>
</form>

<app-document-upload-modal *ngIf="showUploadModal"
  [preuploadCheck]="createResponse"
  [creatorUserId]="currentUserId"
  [documentTypeIds]="[documentTypeId]"
  [proposalId]="surveyService.proposalId"
  [institutionId]="surveyService.institutionId"
  [yearDurationId]="surveyService.durationId"
  [effortAreaId]="surveyResponseEA?.id"
  (closeSuccess)="uploadSuccess($event)"
  (closeFail)="showUploadModal=false;"
  (closeCancel)="showUploadModal=false;">
</app-document-upload-modal>

<ng-template #emptyDocuments>
  <p class="empty-state">
    No documents have been uploaded.
  </p>
</ng-template>
