<form [formGroup]="form">
  <input-group [headingText]="questionLabel" [control]="form" [required]="isRequired" [errorsOnUntouched]="surveyService.canEdit && form.invalid"
  id="checkbox_question_{{question.id}}_{{uniqueTag}}">
  <div *ngIf="helpText" class="help-text">
    <p [innerHtml]="helpText"></p>
  </div>

    <div [ngClass]="{'columns columns--2': displayAsColumns}">
      <div>
        <radio-check *ngFor="let choice of column1Choices" [labelText]="choice.title" [canEdit]="surveyService.canEdit">
          <input inputRef id="question{{question.id}}_choice{{choice.id}}_{{uniqueTag}}" type="checkbox" class="checkbox no-margin"
            formControlName="{{question.id}}_{{choice.id}}"
            (change)="persistResponse(question.id + '_' + choice.id, 'bool_response', { responseOptionId: choice.id })">
        </radio-check>
      </div>
      <div *ngIf="displayAsColumns">
        <radio-check *ngFor="let choice of column2Choices" [labelText]="choice.title" [canEdit]="surveyService.canEdit">
          <input inputRef id="question{{question.id}}_choice{{choice.id}}_{{uniqueTag}}" type="checkbox" class="checkbox no-margin"
            formControlName="{{question.id}}_{{choice.id}}"
            (change)="persistResponse(question.id + '_' + choice.id, 'bool_response', { responseOptionId: choice.id })">
        </radio-check>
      </div>
    </div>
  </input-group>
</form>
