import { Model } from '@app-ngrx-domains';
import { DEFAULT_STEP_OPTIONS, WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';
import { WORKFLOW_METRICS, WORKFLOW_METRICS_V3, WORKFLOW_METRIC_LABELS, WORKFLOW_METRIC_STEPS } from './sep.const';

export const SEP_METRIC_SUBWORKFLOW: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP_METRIC_SUBWORKFLOW,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: 'student-populations/:populationId',
      title: '[Student Population]',
      showStatus: true,
      valid: false
    }
  ]
};

export const SEP_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.DETAILS,
      title: 'Details',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      ...DEFAULT_STEP_OPTIONS
    },
    {
      route: WORKFLOW_STEPS.METRICS,
      title: 'Metrics',
      ...DEFAULT_STEP_OPTIONS
    },
    {
      route: WORKFLOW_STEPS.ACTIVITIES,
      title: 'Activities',
      ...DEFAULT_STEP_OPTIONS
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    }
  ]
};

export const SEP_V2_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP_v2,
  templateName: WORKFLOW_TYPES.SEP_v2,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.DETAILS,
      title: 'Details',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      ...DEFAULT_STEP_OPTIONS
    },
    {
      route: WORKFLOW_STEPS.EQUITY_PLAN_REFLECTION,
      title: 'Equity Plan Reflection',
      ...DEFAULT_STEP_OPTIONS
    },
    {
      route: WORKFLOW_STEPS.STUDENTS_EXPERIENCING_DI,
      title: 'Student Populations Experiencing DI',
      ...DEFAULT_STEP_OPTIONS
    },
    ...WORKFLOW_METRICS.map((metric) => {
      return {
        route: metric.value,
        title: metric.label,
        workflow: {
          name: metric.value,
          templateName: metric.value,
          static: false
        },
        ...DEFAULT_STEP_OPTIONS
      }
    }),
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    }
  ]
};

export const SEP_V3_WORKFLOW_CONFIG: Model.Workflow = {
  name: WORKFLOW_TYPES.SEP_v3,
  templateName: WORKFLOW_TYPES.SEP_v3,
  baseLink: undefined,
  show: false,
  steps: [
    {
      route: WORKFLOW_STEPS.DETAILS,
      title: 'Details',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.CONTACTS,
      title: 'Contacts',
      ...DEFAULT_STEP_OPTIONS
    },
    {
      route: WORKFLOW_STEPS.EQUITY_PLAN_REFLECTION,
      title: 'Student Equity Plan Reflection',
      ...DEFAULT_STEP_OPTIONS
    },
    {
      route: WORKFLOW_STEPS.EXECUTIVE_SUMMARY,
      title: 'Executive Summary',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.STUDENTS_EXPERIENCING_DI,
      title: 'Student Populations Experiencing DI',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false
    },
    ...WORKFLOW_METRICS_V3.map((metric) => {
      return {
        route: metric.value,
        title: metric.label,
        ...DEFAULT_STEP_OPTIONS
      }
    }),
    {
      route: WORKFLOW_STEPS.INTENSIVE_FOCUS,
      title: 'Intensive Focus',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.STUDENT_EDUCATION_PLANS,
      title: 'Student Education Plans',
      ...DEFAULT_STEP_OPTIONS,
    },
    {
      route: WORKFLOW_STEPS.PREVIEW,
      title: 'Preview',
      ...DEFAULT_STEP_OPTIONS,
      showStatus: false,
    }
  ]
};

export const APPLICATION_WORKFLOW_CONFIG_TABLE = {
  [WORKFLOW_TYPES.SEP]: SEP_WORKFLOW_CONFIG,
  [WORKFLOW_TYPES.SEP_v2]: SEP_V2_WORKFLOW_CONFIG,
  ...WORKFLOW_METRICS.reduce((config, metric) => {
    config[metric.value] = {
      ...SEP_METRIC_SUBWORKFLOW,
      name: metric.value
    };
    return config;
  }, {},),
  [WORKFLOW_TYPES.SEP_v3]: SEP_V3_WORKFLOW_CONFIG
};
