<div *ngIf="assurances?.length" [ngClass]="{'card': showCardInPreview || !isPreview}" class="assurance-card">
  <div class="card-title" class="help-text">
    <h3 class="h3">Assurances</h3>
    <p *ngIf="!isPreview">
    Please attest to the following assurances<ng-container *ngIf="hasQuestions"> and answer its associated question</ng-container>.
    </p>
  </div>

  <form *ngIf="form" [formGroup]="form" autocomplete="off" [ngClass]="{'first-touch': firstTouch}">
    <div *ngFor="let item of assurances; let i=index; let last=last" formGroupName="assurances" [ngClass]="{ 'margin-bottom': !last }">
      <div *ngIf="item.title" class="label-text">
        {{item.title}}
      </div>
      <radio-check [labelText]="item.description" [canEdit]="canEdit" readOnlyType="checked-unchecked" class="margin-bottom-none">
        <input inputRef id="qa_assurance_checkbox{{i}}"
          class="checkbox" type="checkbox"
          formControlName="item{{i}}"
          (change)="persistAssuranceChange(i)"
          [required]="!isPreview" aria-required="!isPreview"/>
        </radio-check>
        <ul *ngIf="item.files?.length" class="icon-list menu">
          <li *ngFor="let file of item.files" class="icon-list__item">
            <a [href]="file.url" target="_blank" [attr.aria-label]="'file: ' + file.filename" class="icon-list__item__link">
              <icon [iconId]="fileIconType(file)"></icon>
              <span> {{file.filename}}</span>
            </a>
          </li>
        </ul>
        <input-field *ngFor="let question of item.survey_questions" [labelText]="question.title" [canEdit]="canEdit" class="survey-question">
          <div *ngIf="!isPreview && question.help_text" class="help-text">
            <p [innerHtml]="question.help_text | safeHtml"></p>
          </div>
          <po-html-textarea inputRef id="assurance_question_{{i}}_{{question.id}}" formControlName="question_{{i}}_{{question.id}}"
            [htmlCharLimit]="question.text_limit"
            (change)="persistResponse(i, question.id)"></po-html-textarea>
        </input-field>
      </div>
  </form>
</div>