import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CHAR_LIMITS } from '@app-consts';
import { ValidatorsEx } from '@app-utilities';
import { isNil } from 'lodash';
import { DEFAULT_TEXT_LIMIT, QUESTION_TYPES, SURVEY_UTILS } from '@app/shared.surveys/consts';
import { SurveyService } from '../survey.service';

@Component({
  selector: 'survey-question',
  templateUrl: './survey-question.component.html'
})
export class SurveyQuestionComponent implements OnInit, OnDestroy {
  @Input() question: Model.EASurveyQuestion;
  @Input() questionIndex: number; // Used as the question's number
  @Input() groupId: number;
  @Input() groupNumber: number;
  @Input() parentQuestionNumber: number;
  @Input() responseGroupNumber: number;

  public form: FormGroup;
  public uniqueTag: string;
  public multiResponses: Array<Model.EASurveyResponse>;
  public responses: Array<Model.EASurveyResponse>;
  protected maxSingleColumnLength = 8;

  defaultTextLimit = DEFAULT_TEXT_LIMIT;

  protected destroy$: Subject<boolean> = new Subject();

  constructor(
    public surveyService: SurveyService,
    protected _fb: FormBuilder,
    protected store: Store<State>,
  ) { }

  ngOnInit() {
    if (this.surveyService.isPreview) {
      this.setupAsPreview();
      return;
    }

    this.uniqueTag = this.formattedUniqueTag;

    const observable = this.surveyService.useCurrentProposalStore
      ? this.store.select(Queries.CurrentProposal.get)
      : this.store.select(Queries.Proposals.getAll).pipe(map(proposals => { return proposals.find(p => p.id === this.surveyService.proposalId) || null }));

    observable.pipe(
      filter(p => p && !!p.id),
      takeUntil(this.destroy$)
    ).subscribe(proposal => {
      const allResponses = proposal // proposalId can be null when showing the survey questions as a template
        ? proposal.survey_responses ? [].concat(proposal.survey_responses) : [] // [].concat() ensures `allResponses` will always be an array
        : [];
      this.responses = allResponses.filter(response => {
        return ((this.surveyService.institutionId ? this.surveyService.institutionId === response.institution_id : true)
        && (this.surveyService.durationId ? this.surveyService.durationId === response.duration_id : true)
        && (this.surveyService.effortAreaId ? this.surveyService.effortAreaId === response.parent_effort_area_id : true)
        && (this.surveyService.fundId ? this.surveyService.fundId === response.fund_id : true)
        && (this.surveyService.userId ? this.surveyService.userId === response.user_id : true))
      });

      if (!this.form) {
        this.buildForm();

        if (this.isRequired && this.responseGroupNumber) {
          // Create temp-responses for group responses so that we can validate against them
          this.createTempResponses();
        }

        this.surveyService.registerQuestion({
          id: this.question.id,
          responseGroup: this.responseGroupNumber,
          form: this.form,
          getResponse: (responseOptionId?) => this.getValueForKey(this.question.id, responseOptionId)
        });
      }
    });
  }

  setupAsPreview() {
    this.responses = [];
    this.buildForm();
    this.form.disable();
  }

  protected get formattedUniqueTag(): string {
    return [this.surveyService.proposalId, this.surveyService.institutionId, this.surveyService.durationId,
      this.surveyService.effortAreaId, this.surveyService.fundId, this.surveyService.userId, this.responseGroupNumber
    ].map(value => value || '').join('_');
  }

  buildForm() {
    const key = this.question.id;
    const questionType = this.question.question_type;
    const validators = this.validatorsForQuestionType(questionType);
    const controls = {
      [key]: [this.getValueForKey(key), validators]
    }

    this.form = this._fb.group(controls);
  }

  validatorsForQuestionType(questionType) {
    const validators = this.isRequired ? [Validators.required] : [];
    if (questionType === QUESTION_TYPES.TEXT) {
      validators.push(...(this.useHtmlEditor
        ? [ValidatorsEx.htmlCharMinimum(CHAR_LIMITS.NARRATIVE_MIN, !this.isRequired), ValidatorsEx.htmlCharMaximum(this.textLimit)]
        : [ValidatorsEx.textCharMinimum(CHAR_LIMITS.NARRATIVE_MIN, !this.isRequired), Validators.maxLength(this.textLimit)]));
      }
    return validators;
  }

  getValueForKey(questionId: number,
    responseOptionId: number = null,
    tableRowId: number = null,
    tableColumnId: number = null,
    ) {
    const response = this.responses.find(r =>
      r.question_id === questionId &&
      r.response_option_id === responseOptionId &&
      r.table_row_id === tableRowId &&
      r.table_column_id === tableColumnId &&
      (r.group_id || null) === (this.groupId || null) &&
      (r.response_group || null) === (this.responseGroupNumber || null));

    if (!response) {
      return undefined;
    }

    // Get response by question type
    const getResponse = (questionType) => {
      if ([QUESTION_TYPES.NUMBER, QUESTION_TYPES.PERCENT, QUESTION_TYPES.CURRENCY, QUESTION_TYPES.FILE].includes(questionType)) {
        return response['int_response'];
      } else if ([QUESTION_TYPES.CHECKBOX].includes(questionType)) {
        return response['bool_response'];
      } else if (questionType === QUESTION_TYPES.TABLE) {
        const column = this.question.survey_table_columns.find(c => c.id === response.table_column_id);
        return getResponse(column.question_type);
      } else {
        // Remaining question types should be text_responses but iterate through other response types just in case
        return ['text_response', 'int_response', 'bool_response'].reduce((value, type) => {
          if (isNil(value) && !isNil(response[type])) {
            return response[type];
          } else {
            return value;
          }
        }, undefined);
      }
    }

    return getResponse(this.question.question_type);
  }

  persistResponse(formControlName: string | number, responseType: string, responseFields: { responseOptionId?: number, tableRowId?: number, tableColumnId?: number } = {}) {
    const value = this.getControl(formControlName).value;
    this.persistValue(value, responseType, responseFields);
  }

  persistValue(value: any,  responseType: string, responseFields: { responseOptionId?: number, tableRowId?: number, tableColumnId?: number } = {}) {
    const existing = this.responses.find(response => response.question_id === this.question.id &&
      (response.response_option_id || null) === (responseFields.responseOptionId || null) &&
      (response.table_row_id || null) === (responseFields.tableRowId || null) &&
      (response.table_column_id || null) === (responseFields.tableColumnId || null) &&
      (response.group_id || null) === (this.groupId || null) &&
      (response.response_group || null) === (this.responseGroupNumber || null)
    );

    if (!existing) {
      // Create a new response
      const payload = {
        ea: {
          ...this.baseResponseFields,
          response_option_id: responseFields.responseOptionId || null,
          table_row_id: responseFields.tableRowId || null,
          table_column_id: responseFields.tableColumnId || null,
          [responseType]: value
        },
        hasAttributes: false
      };

      if (this.surveyService.useCurrentProposalStore) {
        this.store.dispatch(Actions.CurrentProposal.createEffortArea(payload));
      } else {
        this.store.dispatch(Actions.Proposals.createEffortArea({ ...payload, proposal_id: this.surveyService.proposalId }));
      }
    } else {
      // Update the existing
      if (this.surveyService.useCurrentProposalStore) {
        this.store.dispatch(Actions.CurrentProposal.upsertAttribute({ key: responseType, value, ea: existing }));
      } else {
        this.store.dispatch(Actions.Proposals.upsertAttribute({ key: responseType, value, ea: existing, proposal_id: this.surveyService.proposalId }));
      }
    }
  }

  createTempResponses() {
    if (this.question.question_type === QUESTION_TYPES.FILE || this.responses.some(response =>
        response.question_id === this.question.id &&
        response.group_id === (this.groupId || null) &&
        response.response_group === (this.responseGroupNumber || null))) {
      return;
    }

    if (this.surveyService.useCurrentProposalStore) {
      this.store.dispatch(Actions.CurrentProposal.createTempEffortArea({ ea: { ...this.baseResponseFields } }));
    } else {
      this.store.dispatch(Actions.Proposals.createTempEffortArea({ ea: { ...this.baseResponseFields }, proposal_id: this.surveyService.proposalId }));
    }
  }

  get responseType() {
    switch (this.question.question_type) {
      case QUESTION_TYPES.TEXT:
        return 'text_response';
      case QUESTION_TYPES.NUMBER:
      case QUESTION_TYPES.PERCENT:
      case QUESTION_TYPES.CURRENCY:
        return 'int_response';
    }
  }

  get questionLabel() {
    if (this.surveyService.hideQuestionNumbers) {
      const title = this.question.title;
      if (this.question.if_answer_is) {
        return `(${this.question.if_answer_is}) ${title}`;
      }
      return `${title}`;
    } else {
      const questionNumber = SURVEY_UTILS.formatQuestionNumber(this.question, this.questionIndex, this.parentQuestionNumber, this.groupNumber, true);
      return `${questionNumber} ${this.question.title}`;
    }
  }

  get isRequired() {
    return !this.question.is_optional;
  }

  get helpText() {
    return this.question.help_text;
  }

  get textLimit() {
    return this.question.text_limit || this.defaultTextLimit;
  }

  get useHtmlEditor() {
    return this.textLimit > this.defaultTextLimit;
  }

  getControl(controlName: string | number) {
    return this.form.get(String(controlName));
  }

  get baseResponseFields() {
    return {
      effort_area_type: 'survey_responses',
      parent_proposal_id: this.surveyService.proposalId,
      institution_id: this.surveyService.institutionId || null,
      duration_id: this.surveyService.durationId || null,
      parent_effort_area_id: this.surveyService.effortAreaId || null,
      fund_id: this.surveyService.fundId || null,
      user_id: this.surveyService.userId || null,
      question_id: this.question.id,
      group_id: this.groupId || null,
      response_group: this.responseGroupNumber || null,
    };
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.surveyService.unregisterQuestion(this.question.id, this.responseGroupNumber);
  }
}
