import { forkJoin, of, Observable, EMPTY } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Action, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { State, Actions, Model } from '@app-ngrx-domains'
import { ActionWithPayload, errorPayload, NewTempId, AttributePayload } from '@app-libs';
import { ApiService } from './api.service';
import { Attribute } from '../models/attribute';
import { Utilities } from '../models/utilities';
import { get, isNil } from 'lodash';
import { ReducerUtils } from '@app/libs/ngrx/attribute-reducer.utils';
import { EffortArea } from '../models';

/**
 * Injectable attributes helper service
 */
@Injectable()
export class AttributesService {

  private effortAreaWaitlist: Array<number>;

  constructor(
    private apiService: ApiService,
    private logger: NGXLogger,
    private store: Store<State>
  ) {
    this.effortAreaWaitlist = [];
  }

  private upsertAttributeBase(req: AttributePayload, root: any, fund_id?: number, institution_id?: number): Observable<any> {
    const attribute: Model.Attribute = {
      proposal_id: !fund_id && !institution_id ? root.id : null,
      attribute_name: req.key,
      value: req.value,
      id: req.id
    };

    let oldValue: any;
    let eaDebugString = '-';
    if (req.ea) {
      // updating ea attribute.
      attribute['effort_area_id'] = req.ea.id;
      eaDebugString = `${req.ea.effort_area_type}/${req.ea.id}`;

      const effortArea = ReducerUtils.findEffortArea(root, req.ea, req.parentEffortAreas);
      oldValue = get(effortArea, req.key);

    } else {
      oldValue = root[req.key];
    }

    const debugLog = (action: string) => {
      this.logger.debug(`[${this.constructor.name}]upsertAttribute$ ${action} p=${attribute.proposal_id} ea=${eaDebugString} name=${attribute.attribute_name} new=${attribute.value} old=${oldValue}`);
    };

    // Make sure the value has changed and at-least one is not nil
    if (attribute.value !== oldValue && !(isNil(req.value) && isNil(oldValue))) {
      if (!isNil(oldValue) && isNil(req.value)) {
        debugLog('deleting');

        // delete existing attribute
        delete attribute.value;
        return this.apiService.deleteAttribute(attribute, fund_id, institution_id).pipe(
          map(() => ({ ...req, deleted: true }))
        );
      } else {
        debugLog('updating');

        // update existing attribute
        return this.apiService.upsertAttribute(attribute, fund_id, institution_id).pipe(
          map((response) => ({ ...req, response }))
        );
      }
    } else {
      debugLog('no-op');
      return EMPTY;
    }
  }

  /**
   * Upserts proposal/fund attribute.
   */
  upsertAttribute$(actionPrefix: string, req: AttributePayload, root: any, multi_data_id?: number, fund_id?: number, institution_id?: number): Observable<Action> {
    return this.upsertAttributeBase(req, root, fund_id, institution_id).pipe(
      map((result) => {
        if (result.deleted) {
          return this.deleteAttributeSuccess(actionPrefix, req.key, req.ea, req.value, req.parentEffortAreas, multi_data_id);
        } else {
          let value = result.response;
          if (req.storeValue) {
            value = { value: req.storeValue };
          }
          return this.upsertAttributeSuccess(actionPrefix, req.key, req.ea, value, req.parentEffortAreas, multi_data_id);
        }
      }),
      catchError((error) => of(this.throwError(error, `${actionPrefix}UPSERT_ATTRIBUTE`)))
    );
  }

  upsertAttributes$(actionPrefix: string, req: any, root: any, fund_id?: number): Observable<Action> {
    const upserts = [];
    req.attributes.forEach(attr => {
      const upsert = this.upsertAttributeBase(attr, root, fund_id);
      if (upsert !== EMPTY) {
        upserts.push(upsert);
      }
    });

    if (!!upserts.length) {
      if (upserts.length > 3) {
        // Only show the busy spinner if there are a ton of attributes to update.
        // Otherwise, it causes the page to look like it's blinking when it's executed too quickly
        this.store.dispatch(Actions.Layout.showBusySpinner(true));
      }
      return forkJoin(upserts).pipe(
        map((results: Array<{
          deleted?: boolean,
          response?: boolean,
          key: string,
          ea: Model.EffortArea,
          value: any,
          parentEffortAreas?: Array<Model.EffortArea>,
          storeValue?: any
        }>) => {
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          return this.upsertAttributesSuccess(actionPrefix, results)
        }),
        catchError((error) => of(this.throwError(error, `${actionPrefix}UPSERT_ATTRIBUTES`)))
      );
    } else {
      return of(this.upsertAttributesSuccess(actionPrefix, []));
    }

  }

  deleteAttributes$(actionPrefix: string, req: any, root: any, multi_data_id?: number, fund_id?: number): Observable<Action> {
    const deletes = [];
    const proposal_id = !fund_id ? root.id : null;
    req.attributes.forEach(del => {
      const attribute: Model.Attribute = {
        proposal_id,
        attribute_name: del.key,
        value: del.value,
      }
      if (del.ea) {
        attribute['effort_area_id'] = del.ea.id;
      }
      deletes.push(this.apiService.deleteAttribute(attribute, fund_id));
    });

    if (!!deletes.length) {
      this.store.dispatch(Actions.Layout.showBusySpinner(true));
      return forkJoin(deletes).pipe(
        map((result) => {
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          return this.deleteAttributesSuccess(actionPrefix, req.attributes, multi_data_id)
        }),
        catchError((error) => of(this.throwError(error, `${actionPrefix}DELETE_ATTRIBUTES`)))
      );
    } else {
      return EMPTY;
    }

  }

  /**
   * Adds proposal/fund multi attribute.
   */
  addMultiAttribute$(actionPrefix: string, req: any, root: any, multi_data_id?: number, fund_id?: number): Observable<Action> {
    const attribute: Model.Attribute = {
      proposal_id: !fund_id ? root.id : null,
      attribute_name: req.key,
      value: req.value,
    };
    let eaDebugString = '-';

    if (req.ea) {
      // updating ea attribute.
      attribute['effort_area_id'] = req.ea.id;
      eaDebugString = `${req.ea.effort_area_type}/${req.ea.id}`;
    }

    this.logger.debug(`[${this.constructor.name}]addMultiAttribute$ p=${attribute.proposal_id} ea=${eaDebugString} name=${attribute.attribute_name} value=${attribute.value} options=${req.options}`);
    return this.apiService.upsertAttribute(attribute, fund_id).pipe(
      map((response) => this.upsertAttributeSuccess(actionPrefix, req.key, req.ea, response, req.parentEffortAreas, multi_data_id)),
      catchError((error) => of(this.throwError(error, `${actionPrefix}ADD_MULTI_ATTRIBUTE`))));
  }

  /**
   * Deletes proposal multi attribute.
   */
  deleteMultiAttribute$(actionPrefix: string, req: any, root: any, multi_data_id?: number, fund_id?: number): Observable<Action> {
    const attribute: Model.Attribute = {
      proposal_id: !fund_id ? root.id : null,
      attribute_name: req.key,
      value: req.value,
    }
    let eaDebugString = '-';
    if (req.ea) {
      // updating ea attribute.
      attribute['effort_area_id'] = req.ea.id;
      eaDebugString = `${req.ea.effort_area_type}/${req.ea.id}`;
    }

    this.logger.debug(`[${this.constructor.name}]deleteMultiAttribute$ p=${attribute.proposal_id} ea=${eaDebugString} name=${attribute.attribute_name} value=${attribute.value}`);
    return this.apiService.deleteAttribute(attribute, fund_id).pipe(
      map(() => this.deleteAttributeSuccess(actionPrefix, req.key, req.ea, req.value, req.parentEffortAreas, multi_data_id)),
      catchError((error) => of(this.throwError(error, `${actionPrefix}DELETE_MULTI_ATTRIBUTE`))));
  }

  /**
   * Creates an effort area then adds an attribute.
   */
  creatAttributeEffortArea$(actionPrefix: string, req: any, multi_data_id?: number, fund_id?: number): Observable<Action> {
    const ea = { ...req.ea };

    delete ea.id;
    delete ea['temp_id'];

    const waitlistError = this.addToWaitlist(req.ea['temp_id']);
    if (waitlistError) {
      return waitlistError;
    }

    return this.apiService.createEffortArea(ea).pipe(
      mergeMap(newEa => {
        newEa.temp_id = req.ea['temp_id'];

        const attributes = (req.key && !isNil(req.value)) ? [{ key: req.key, value: req.value }] : [];
        const eaDebugString = `${newEa.effort_area_type}/${newEa.id}`;
        this.logger.debug(`[${this.constructor.name}]creatAttributeEffortArea$ p=${newEa.parent_proposal_id} ea=${eaDebugString} name=${req.key} value=${req.value}`);

        if (attributes.length) {
          return forkJoin(attributes.map(attribute => {
            return this.apiService.upsertAttribute({
              proposal_id: newEa.parent_proposal_id,
              attribute_name: attribute.key,
              effort_area_id: newEa.id,
              value: attribute.value,
            }, fund_id);
          })).pipe(map((response) => {
            const result = { ...req.ea, ...newEa };

            if (response instanceof Array) {
              response.forEach(attribute => {
                if (result[attribute.attribute_name] instanceof Array) {
                  result[attribute.attribute_name] = [...result[attribute.attribute_name], attribute];
                } else {
                  result[attribute.attribute_name] = attribute.value;
                }
              });
            }
            return this.createAttributeEffortAreaSuccess(actionPrefix, req.ea, result, req.parentEffortAreas, multi_data_id);
          }),
            catchError((error) => of(this.throwError(error, `${actionPrefix}CREATE_ATTRIBUTE_EFFORT_AREA`))));
        } else {
          return of(this.createAttributeEffortAreaSuccess(actionPrefix, req.ea, { ...req.ea, ...newEa }, req.parentEffortAreas, multi_data_id));
        }
      }),
      catchError((error) => of(this.throwError(error, `${actionPrefix}CREATE_ATTRIBUTE_EFFORT_AREA`))));
  }

  /**
   * Creates an effort area.
   */
  createEffortArea$(actionPrefix: string, req: any, multi_data_id?: number): Observable<Action> {
    if (NewTempId.isTemp(req.ea.parent_effort_area_id)) {
      const parentEffortAreas = [...req.parentEffortAreas];
      const tempParentEA = parentEffortAreas.pop();

      return this.createEffortArea$(actionPrefix, {
        ea: EffortArea.basePayload(tempParentEA),
        parentEffortAreas
      }, tempParentEA.parent_proposal_id).pipe(mergeMap((action: ActionWithPayload<any>) => {
        const newId = action.payload.newId;
        tempParentEA.newId = newId;
        req.ea.parent_effort_area_id = newId;
        return this.createEffortArea$(actionPrefix, req, multi_data_id);
      }));
    }

    // We need to copy the effortArea into a payload object to preserve the 'id' and 'temp_id' values
    const payload = { ...req.ea };

    const waitlistError = this.addToWaitlist(payload.temp_id);
    if (waitlistError) {
      return waitlistError;
    }

    delete payload.id;
    delete payload.temp_id;
    return this.apiService.createEffortArea(payload).pipe(
      map((response) => this.updateEffortAreaSuccess(actionPrefix, { ...req.ea, ...response, id: req.ea.id }, response, req.parentEffortAreas, multi_data_id)),
      catchError((error) => of(this.throwError(error, `${actionPrefix}CREATE_EFFORT_AREA`))));
  }

  createMultiEffortAreas$(actionPrefix: string, req: any, multi_data_id?: number): Observable<Action> {
    this.store.dispatch(Actions.Layout.showBusySpinner(true));

    const observables = [];
    req.eas.forEach(ea => {
      const payload = { ...ea };
      const waitlistError = this.addToWaitlist(payload.temp_id);
      if (!waitlistError) {
        delete payload.id;
        delete payload.temp_id;
        observables.push(this.apiService.createEffortArea(payload));
      }
    });

    if (!observables.length) {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      return of(this.createMultiEffortAreaSuccess(actionPrefix, [], req.parentEffortAreas));
    } else {
      return forkJoin(observables).pipe(
        mergeMap((res: Array<Model.EffortArea>) => {
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          return of(this.createMultiEffortAreaSuccess(actionPrefix, res, req.parentEffortAreas));
        }),
        catchError((error) => of(this.throwError(error, `${actionPrefix}CREATE_MULTI_EFFORT_AREAS`)))
      );
    }
  }

  /**
   * Updates an effort area.
   */
  updateEffortArea$(actionPrefix: string, req: any, multi_data_id?: number): Observable<Action> {
    if (NewTempId.isTemp(req.ea.id)) {
      const body = {
        ...req.ea,
      };
      delete body.id; // remove temp id.
      delete body.temp_id;
      return this.apiService.createEffortArea(body).pipe(
        map((response) => this.updateEffortAreaSuccess(actionPrefix, req.ea, response, req.parentEffortAreas, multi_data_id)),
        catchError((error) => of(this.throwError(error, `${actionPrefix}UPDATE_EFFORT_AREA`))));
    } else {
      return this.apiService.updateEffortArea(req.ea).pipe(
        map((response) => this.updateEffortAreaSuccess(actionPrefix, req.ea, response, req.parentEffortAreas, multi_data_id)),
        catchError((error) => of(this.throwError(error, `${actionPrefix}UPDATE_EFFORT_AREA`))));
    }
  }

  /**
   * Deletes an effort area
   */
  deleteEffortArea$(actionPrefix: string, req: any, multi_data_id?: number): Observable<Action> {
    return this.apiService.deleteEffortArea(req.ea).pipe(
      map(() => this.deleteEffortAreaSuccess(actionPrefix, req.ea, req.parentEffortAreas, multi_data_id)),
      catchError((error) => of(this.throwError(error, `${actionPrefix}DELETE_EFFORT_AREA`))));
  }

  /**
   * Deletes list of effort areas
   */
  deleteMultiEffortAreas$(actionPrefix: string, req: any, multi_data_id?: number): Observable<Action> {
    // turn on the spinner - may take awhile
    this.store.dispatch(Actions.Layout.showBusySpinner(true));

    const apiDeleteEA = [];
    // batch all the eas to delete that are not temporary.
    req.eas.forEach(ea => {
      if (!Utilities.isTempId(ea.id)) {
        apiDeleteEA.push(this.apiService.deleteEffortArea(ea));
      }
    });

    if (!apiDeleteEA.length) {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      return of(this.deleteMultiEffortAreasSuccess(actionPrefix, req.eas, req.parentEffortAreas, multi_data_id, req.effortAreaType));
    } else {
      return forkJoin(apiDeleteEA).pipe(
        mergeMap((res) => {
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          return of(this.deleteMultiEffortAreasSuccess(actionPrefix, req.eas, req.parentEffortAreas, multi_data_id, req.effortAreaType));
        }),
        catchError((error) => of(this.throwError(error, `${actionPrefix}DELETE_MULTI_EFFORT_AREAS`))));
    }
  }

  cloneEffortAreas$(actionPrefix: string, operations: Array<{ effortAreaId: number, options: {
    replacers?: Array<{ key: string, value: any }>,
    excludeAttributes?: Array<string>,
    excludeEffortAreas?: Array<string>,
    targetModel: string } }>,
    parentEffortAreas?: Array<Model.EffortArea>): Observable<Action> {

      this.store.dispatch(Actions.Layout.showBusySpinner(true));

      const observables = [];
      operations.forEach(operation => {
        observables.push(this.apiService.cloneEffortArea(operation.effortAreaId, operation.options));
      });
      if (!observables.length) {
        this.store.dispatch(Actions.Layout.showBusySpinner(false));
        return of(this.createMultiEffortAreaSuccess(actionPrefix, [], parentEffortAreas));
      } else {
        return forkJoin(observables).pipe(
          mergeMap((res: Array<Model.EffortArea>) => {
            this.store.dispatch(Actions.Layout.showBusySpinner(false));
            return of(this.createMultiEffortAreaSuccess(actionPrefix, res, parentEffortAreas));
          }),
          catchError((error) => of(this.throwError(error, `${actionPrefix}CLONE_EFFORT_AREAS`)))
        );
      }
  }

  /**
   * Replaces original multi EAs with pending EAs
   */
  applyPendingMultiEffortAreas$(actionPrefix: string, req: any): Observable<Action> {
    this.store.dispatch(Actions.Layout.showBusySpinner(true));

    const apiRequests = [];
    req.eas.forEach(ea => {
      const attribute = {
        effort_area_id: ea.id,
        proposal_id: ea.parent_proposal_id,
        attribute_name: 'pending',
        value: false
      };
      // Set pending to false
      apiRequests.push(this.apiService.upsertAttribute(attribute, ea.fund_id, ea.institution_id));
      // Delete original EA
      apiRequests.push(this.apiService.deleteEffortArea({ id: ea.cloned_from_id }));
    });

    if (!apiRequests.length) {
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      return of(this.applyPendingMultiEffortAreasSuccess(actionPrefix, req.effortAreaType, req.eas, req.parentEffortAreas));
    } else {
      return forkJoin(apiRequests).pipe(
        mergeMap((res) => {
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          this.store.dispatch(Actions.App.showSuccess('Budget modification successful!'));
          return of(this.applyPendingMultiEffortAreasSuccess(actionPrefix, req.effortAreaType, req.eas, req.parentEffortAreas));
        }),
        catchError((error) => of(this.throwError(error, `${actionPrefix}APPLY_PENDING_MULTI_EFFORT_AREAS`))));
    }
  }


  ////////////////////////////////////////////////////////////////////////////////////////////
  // Successful actions to be sent to reducers
  ////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Rethrow caught error through redux.
   * @param error
   * @param location
   * @param show
   */
  throwError(error: any, location: string, show = false): ActionWithPayload<any> | Action {
    // stop spinner just in case it was spinning.
    this.store.dispatch(Actions.Layout.showBusySpinner(false));
    return errorPayload(error, location, show);
  }

  /**
   * Throws an error message.
   * @param msg
   * @param location
   * @param show
   */
  throwErrorMessage(msg: string, location: string, show = false): ActionWithPayload<any> | Action {
    return this.throwError(new Error(msg), location, show);
  }

  /**
   * Sends out upsert success action.
   */
  upsertAttributeSuccess(actionPrefix: string,
    key: string,
    ea: Model.EffortArea,
    response: any,
    parentEffortAreas?: Array<Model.EffortArea>,
    multi_data_id?: number): ActionWithPayload<any> | Action {
    if (response.duplicate_entry) {
      // Service skipped duplicate upsert attempt
      return { type: `${actionPrefix}NOOP` }
    } else {
      response = Attribute.removeUnusedFields(response);
      return {
        type: `${actionPrefix}UPSERT_ATTRIBUTE_SUCCESS`,
        payload: {
          key, ea, value: response, parentEffortAreas, multi_data_id
        }
      };
    }
  }

  /**
   * Sends out delete success action.
   */
  deleteAttributeSuccess(actionPrefix: string,
    key: string,
    ea: Model.EffortArea,
    value: any,
    parentEffortAreas?: Array<Model.EffortArea>,
    multi_data_id?: number): ActionWithPayload<any> {
    return {
      type: `${actionPrefix}DELETE_ATTRIBUTE_SUCCESS`,
      payload: { key, ea, value, parentEffortAreas, multi_data_id }
    };
  }

  deleteAttributesSuccess(actionPrefix: string,
    attributes: Array<{
      key: string,
      value: any,
      ea: Model.EffortArea,
      parentEffortAreas?: Array<Model.EffortArea>,
    }>, multi_data_id?: number): ActionWithPayload<any> {
    const deletes = attributes.map(del => {
      return {
        ...del,
        multi_data_id,
      };
    });

    return {
      type: `${actionPrefix}DELETE_ATTRIBUTES_SUCCESS`,
      payload: { deletes }
    };
  }

  upsertAttributesSuccess(actionPrefix: string,
    updates: Array<{
      deleted?: boolean,
      response?: any,
      key: string,
      ea: Model.EffortArea,
      value: any,
      parentEffortAreas?: Array<Model.EffortArea>,
      storeValue?: any
    }>): ActionWithPayload<any> {
      updates = updates.filter(update => !(update.response && update.response.duplicate_entry))
        .map(update => {
          if (update.response) {
            update.response = Attribute.removeUnusedFields(update.response);
            update.value = update.response;

            if (update.storeValue) {
              update.value = {
                value: update.storeValue
              };
            }
          }
          return update;
        });

      return {
        type: `${actionPrefix}UPSERT_ATTRIBUTES_SUCCESS`,
        payload: { updates }
      };
    }

  /**
   * Successfully created the effort area first then the attribute.
   */
  createAttributeEffortAreaSuccess(actionPrefix: string,
    ea: Model.EffortArea,
    newEa: Model.EffortArea,
    parentEffortAreas?: Array<Model.EffortArea>,
    multi_data_id?: number): ActionWithPayload<any> {
    this.removeFromWaitlist(ea['temp_id']);
    return {
      type: `${actionPrefix}CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS`,
      payload: { ea, newEa, parentEffortAreas, multi_data_id }
    };
  }

  /**
   * Successfully updated effort area.
   */
  updateEffortAreaSuccess(actionPrefix: string,
    ea: any,
    res: any,
    parentEffortAreas: Array<Model.EffortArea>,
    multi_data_id?: number): ActionWithPayload<any> {
    this.removeFromWaitlist(ea['temp_id']);
    return {
      type: `${actionPrefix}UPDATE_EFFORT_AREA_SUCCESS`,
      payload: {
        ea,
        newId: res.id,
        parentEffortAreas,
        multi_data_id
      }
    };
  }

  /**
   * Successfully deleted the effort area.
   */
  deleteEffortAreaSuccess(actionPrefix: string,
    ea: any,
    parentEffortAreas: Array<Model.EffortArea>,
    multi_data_id?: number): ActionWithPayload<any> {
    return {
      type: `${actionPrefix}DELETE_EFFORT_AREA_SUCCESS`,
      payload: { ea, parentEffortAreas, multi_data_id },
    };
  }

  /**
   * Successfully deleted list of effort areas.
   */
  deleteMultiEffortAreasSuccess(actionPrefix: string,
    eas: Array<any>,
    parentEffortAreas?: Array<Model.EffortArea>,
    multi_data_id?: number,
    effortAreaType?: string): ActionWithPayload<any> {
    return {
      type: `${actionPrefix}DELETE_MULTI_EFFORT_AREAS_SUCCESS`,
      payload: { eas, parentEffortAreas, multi_data_id, effortAreaType },
    };
  }

  createMultiEffortAreaSuccess(actionPrefix: string,
    eas: Array<Model.EffortArea>,
    parentEffortAreas?: Array<Model.EffortArea>): ActionWithPayload<any> {
      eas.forEach(ea => this.removeFromWaitlist(ea['temp_id']));
      return {
        type: `${actionPrefix}CREATE_MULTI_EFFORT_AREAS_SUCCESS`,
        payload: { eas, parentEffortAreas }
      }
    }

  applyPendingMultiEffortAreasSuccess(actionPrefix: string,
    effortAreaType: string,
    eas: Array<any>,
    parentEffortAreas?: Array<Model.EffortArea>): ActionWithPayload<any> {
    return {
      type: `${actionPrefix}APPLY_PENDING_MULTI_EFFORT_AREAS_SUCCESS`,
      payload: { effortAreaType, eas, parentEffortAreas },
    };
  }

  /* Waitlist Methods */

  addToWaitlist(tempId: number) {
    if (!tempId) {
      return;
    }

    if (this.effortAreaWaitlist.includes(tempId)) {
      return of(this.throwError(new Error('Service is still processing the last request'), AttributesService.name));
    } else {
      this.effortAreaWaitlist.push(tempId);
    }
  }

  removeFromWaitlist(tempId: number) {
    this.effortAreaWaitlist = this.effortAreaWaitlist.filter(id => id !== tempId);
  }

}
