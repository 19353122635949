import { Model } from '@app-ngrx-domains';
import { WORKFLOW_STEPS, WORKFLOW_TYPES } from '@app-consts';

export class ProjectUtilities {

  /**
   * Returns the steps for a custom workflow
   * @param configWorkflow The workflow that includes all possible static steps but no custom steps
   * @param fund The fund for this workflow
   * @returns {Model.Workflow} All enabled steps including custom ones
   */
  static setWorkflowSteps(configWorkflow: any, fund: Model.Fund, forGuidance?: boolean) {
    const enabledSteps = fund.program_settings.workflow_steps.filter(step => step.enabled);
    const workflowSteps = enabledSteps.map(step => step.workflow_name);

    if (!forGuidance && fund.is_small_program && fund.program_settings.rc_contributor_settings?.length && !fund.program_settings.aggregate_funding_sources) {
      // Braided-Funded Small Program - Check for Budget & Forecast Sub-Workflows
      if (workflowSteps.includes(WORKFLOW_STEPS.BUDGET)) {
        const index = workflowSteps.indexOf(WORKFLOW_STEPS.BUDGET);
        workflowSteps[index] = WORKFLOW_STEPS.BUDGET_SOURCES;
      }

      if (workflowSteps.includes(WORKFLOW_STEPS.FORECAST)) {
        const index = workflowSteps.indexOf(WORKFLOW_STEPS.FORECAST);
        workflowSteps[index] = WORKFLOW_STEPS.FORECAST_SOURCES;
      }
    }

    configWorkflow.steps.forEach(step => {
      const hideStep = step.route !== WORKFLOW_STEPS.PREVIEW && !workflowSteps.includes(step.route);
      step.hide = hideStep;

      if ([WORKFLOW_STEPS.BUDGET_SOURCES, WORKFLOW_STEPS.FORECAST_SOURCES].includes(step.route)) {
        step.parent = {
          route: '',
          name: WORKFLOW_TYPES.SP,
          title: 'Workflow'
        }
      }
    });

    // Add Custom Steps
    ProjectUtilities.addCustomWorkflowSteps(configWorkflow, fund);

    // Sort Workflow Steps
    configWorkflow.steps = configWorkflow.steps.sort((a, b) => this.sortWorkflowSteps(a, b, enabledSteps));
    return configWorkflow;
  }

  /**
   * Adds the custom steps to a workflow
   * @param configWorkflow The workflow that includes all possible static steps but no custom steps
   * @param fund The fund for this workflow
   * @returns {Model.Workflow} All enabled steps, custom ones appended
   */
  static addCustomWorkflowSteps(configWorkflow: any, fund: Model.Fund, beforeStep: string = WORKFLOW_STEPS.PREVIEW) {
    let customSteps = [];
    const enabledSteps = fund.program_settings.workflow_steps.filter(step => step.survey_id && step.enabled);
    enabledSteps.forEach(step => {
      if (!configWorkflow.steps.some(s => s.route === step.workflow_name)) {
        customSteps.push({
          route: step.workflow_name,
          title: step.title,
          showStatus: true,
          valid: false
        });
      }
    });

    if (customSteps.length) {
      const index = configWorkflow.steps.findIndex(step => step.route === beforeStep);
      customSteps = customSteps.sort((a, b) => this.sortWorkflowSteps(a, b, enabledSteps));
      configWorkflow.steps.splice(index, 0, ...customSteps);
    }
  }

  static sortWorkflowSteps(step1, step2, enabledSteps) {
    const aliases = {
      [WORKFLOW_STEPS.BUDGET_SOURCES]: WORKFLOW_STEPS.BUDGET,
      [WORKFLOW_STEPS.FORECAST_SOURCES]: WORKFLOW_STEPS.FORECAST
    };

    const stepAlias = (stepName) => aliases[stepName] || stepName;

    const step1Config = enabledSteps.find(step => step.workflow_name === stepAlias(step1.route)) || { sort_order: 99 };
    const step2Config = enabledSteps.find(step => step.workflow_name === stepAlias(step2.route)) || { sort_order: 99 };
    return step1Config.sort_order > step2Config.sort_order ? 1 : -1;
  }

  static getWorkflow(fund, config, workflowName?) {
    const appWorkflow = config['applicationWorkflow'];
    if (!workflowName && appWorkflow) {
      // read config from the route snapshot
      const durationId = fund.program_settings.base_duration_id;
      return appWorkflow[durationId] || appWorkflow.default;
    }

    const workflows = config.workflows;
    return workflows[workflowName] || workflows[config.mainWorkflow] || workflows[fund.key];
  }
}
